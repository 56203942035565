import { type ReactNode, useCallback, useMemo, useState } from 'react'

import { MoreOutlined } from '@ant-design/icons'
import { datadogLogs } from '@datadog/browser-logs'
import { PharmacyType } from '@dialogue/coredata'
import {
  Alert,
  Button,
  Dropdown,
  Empty,
  type MenuProps,
  notification,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { styled, type CSSProp } from 'styled-components'

import { AddressDisplay } from 'app/components/address-display'
import {
  MemberInfoCard,
  type MemberInfoCardProps,
} from 'app/containers/member-profile-page/member-info-card'
import { useAppSelector } from 'app/hooks'
import {
  useGetPreferredPharmacyQuery,
  useUnsetPreferredPharmacyMutation,
} from 'app/redux/api/emergency-room/members'
import { selectHasPermissions } from 'app/redux/authentification/selectors'
import { ER_UPDATE_MEMBER, ER_UPDATE_PHARMACY } from 'app/scopes'

import AddPharmacyModal from './modals/add-pharmacy-modal'
import EditPharmacyModal from './modals/edit-pharmacy-modal'
import { RemovePreferredPharmacyModal } from './remove-pharmacy-modal'

const EmptyPharmacy = styled(Empty)`
  &&& {
    .ant-empty-description {
      margin-top: 8px;
    }

    .ant-empty-footer {
      margin-top: 0;
    }
  }
`

export const usePreferredPharmacy = (patientId: string | number) => {
  return useGetPreferredPharmacyQuery(
    { patientId: Number(patientId) },
    {
      selectFromResult: ({ currentData, data, ...rest }) => ({
        ...rest,
        pharmacy: currentData,
      }),
    },
  )
}

export const PreferredPharmacy = ({
  editable = false,
  patientId,
  title,
  titleIcon,
  styles,
}: {
  editable?: boolean
  patientId: string | number
  title?: string
  titleIcon?: ReactNode
  styles?: CSSProp
}) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')

  const [isPharmacyModalOpen, setIsPharmacyModalOpen] = useState(false)
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false)
  const { pharmacy, isFetching, isError } = usePreferredPharmacy(patientId)

  const [unsetMemberPharmacy] = useUnsetPreferredPharmacyMutation()

  const handleOpenPharmacyModal = useCallback(
    () => setIsPharmacyModalOpen(true),
    [],
  )

  const handleClosePharmacyModal = useCallback(
    () => setIsPharmacyModalOpen(false),
    [],
  )

  let content: ReactNode = null

  if (isError) {
    content = (
      <Alert
        showIcon
        message={t('medicalProfile.preferredPharmacy.error')}
        type="error"
      />
    )
  } else if (pharmacy === null) {
    content = (
      <EmptyPharmacy
        imageStyle={{ height: '50px', margin: 0 }}
        description={t('medicalProfile.preferredPharmacy.empty')}
      >
        <Button type="link" onClick={handleOpenPharmacyModal}>
          {t('medicalProfile.preferredPharmacy.add')}
        </Button>
      </EmptyPharmacy>
    )
  } else if (pharmacy) {
    content = (
      <AddressDisplay
        city={pharmacy.locality}
        name={pharmacy.name}
        postal_code={pharmacy.postal_code}
        province={pharmacy.admin_area_level_1_iso_code}
        country={pharmacy.admin_area_iso_code?.split('-')?.[0]}
        street_address1={pharmacy.street_number}
        street_address2={pharmacy.street_number2}
        pharmacy_type={pharmacy.pharmacy_type}
        fax={pharmacy.fax_number}
        phone={pharmacy.phone_number}
      />
    )
  }

  const handleRemovePreferredPharmacy = useCallback(() => {
    unsetMemberPharmacy({ patientId: Number(patientId) })
      .unwrap()
      .then(() => {
        setIsRemovalModalOpen(false)
      })
      .catch((error) => {
        datadogLogs.logger.error(
          'Failed to unset preferred pharmacy',
          { memberId: patientId },
          error,
        )
        notification.error({
          message: t('patientProfile.pharmacy.removeModal.errorMessage'),
        })
      })
  }, [patientId, setIsRemovalModalOpen, t, unsetMemberPharmacy])

  const handleOpenRemovalModal = useCallback(
    () => setIsRemovalModalOpen(true),
    [setIsRemovalModalOpen],
  )
  const handleCloseRemovalModal = useCallback(
    () => setIsRemovalModalOpen(false),
    [setIsRemovalModalOpen],
  )

  const hasUpdateMemberScope = useAppSelector((state) =>
    selectHasPermissions(state, [ER_UPDATE_MEMBER]),
  )

  const hasUpdatePharmacyScope = useAppSelector((state) =>
    selectHasPermissions(state, [ER_UPDATE_PHARMACY]),
  )

  const canEditPharmacy =
    pharmacy?.pharmacy_type === PharmacyType.pharmacy && hasUpdatePharmacyScope

  const actionItems = useMemo<NonNullable<MenuProps['items']>>(() => {
    return [
      {
        disabled: !canEditPharmacy,
        key: 'update-pharmacy',
        label: tCommon('button.edit'),
        onClick: handleOpenPharmacyModal,
        'data-dd-action-name': 'preferred-pharmacy:edit',
      },
      {
        disabled: !hasUpdateMemberScope,
        key: 'unset-pharmacy',
        label: tCommon('button.remove'),
        onClick: handleOpenRemovalModal,
        'data-dd-action-name': 'preferred-pharmacy:remove',
      },
    ]
  }, [
    tCommon,
    handleOpenRemovalModal,
    handleOpenPharmacyModal,
    canEditPharmacy,
    hasUpdateMemberScope,
  ])

  const actionsMenu = useMemo<MemberInfoCardProps['extra']>(() => {
    return (
      <>
        <Dropdown
          trigger={['click']}
          menu={{
            items: actionItems,
            // @ts-expect-error Passing arbitrary HTML props is allowed
            'data-dd-privacy': 'allow',
          }}
        >
          <MoreOutlined data-dd-action-name="preferred-pharmacy:open:menu" />
        </Dropdown>
        <RemovePreferredPharmacyModal
          handleClose={handleCloseRemovalModal}
          handleRemovePharmacy={handleRemovePreferredPharmacy}
          open={isRemovalModalOpen}
        />
      </>
    )
  }, [
    actionItems,
    handleCloseRemovalModal,
    handleRemovePreferredPharmacy,
    isRemovalModalOpen,
  ])

  const PharmacyModal = pharmacy ? EditPharmacyModal : AddPharmacyModal

  return (
    <>
      <MemberInfoCard
        loading={isFetching}
        titleIcon={titleIcon}
        title={title}
        content={content}
        extra={editable && pharmacy ? actionsMenu : null}
        updatedAt={pharmacy?.last_updated_at}
        css={styles}
      />
      <PharmacyModal
        patientId={Number(patientId)}
        open={isPharmacyModalOpen}
        onClose={handleClosePharmacyModal}
      />
    </>
  )
}
