import type {
  ItemNullablePharmacy,
  ItemPharmacy,
  MembersApiGetMemberPreferredPharmacyRequest,
  MembersApiUnsetPreferredPharmacyRequest,
  MembersApiUpdatePreferredPharmacyRequest,
} from '@dialogue/coredata'

import { emergencyRoomApi, Tags } from './api'

export const membersApi = emergencyRoomApi.injectEndpoints({
  endpoints: (build) => ({
    getPreferredPharmacy: build.query({
      query: ({ patientId }: MembersApiGetMemberPreferredPharmacyRequest) => ({
        url: `/v1/members/${patientId}/pharmacy`,
      }),
      transformResponse: (response: ItemNullablePharmacy) => response.data,
      providesTags: (_result, _error, { patientId }) => [
        { type: Tags.MemberPharmacy, id: patientId },
      ],
    }),
    updatePreferredPharmacy: build.mutation<
      ItemPharmacy,
      MembersApiUpdatePreferredPharmacyRequest
    >({
      query: ({ patientId, newPharmacy }) => ({
        url: `/v1/members/${patientId}/pharmacy`,
        method: 'PUT',
        body: newPharmacy,
      }),
      invalidatesTags: (_result, _error, { patientId }) => [
        { type: Tags.MemberPharmacy, id: patientId },
      ],
    }),
    unsetPreferredPharmacy: build.mutation({
      query: ({ patientId }: MembersApiUnsetPreferredPharmacyRequest) => ({
        url: `/v1/members/${patientId}/pharmacy`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { patientId }) => [
        { type: Tags.MemberPharmacy, id: patientId },
      ],
    }),
  }),
})

export const {
  useGetPreferredPharmacyQuery,
  useUpdatePreferredPharmacyMutation,
  useUnsetPreferredPharmacyMutation,
} = membersApi
