import { useCallback, useContext, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import type { MemberDocument } from '@dialogue/document-center'
import type { Note } from '@dialogue/notepad-client'
import { Button, Typography, notification } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { ConfirmationModal } from 'app/components/modals'
import { useExportMemberInformationMutation } from 'app/redux/api/document-center/member-documents'
import routes from 'app/services/routes'

import { ProfileTab } from '../member-profile-page/types'

import { MemberExportDrawerContext } from './drawer'
import { ExportSectionTable } from './export-section-table'
import { useExportDocuments, useExportNotes } from './hooks'
import { FOOTER_HEIGHT } from './utils'

export const MemberExport = ({ memberId }: { memberId: number }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { t: tCommon } = useTranslation('common')
  const { selectedExportRecords, setSelectedExportRecords, close } = useContext(
    MemberExportDrawerContext,
  )
  const [exportConfirmationOpen, setExportConfirmationOpen] =
    useState<boolean>(false)
  const [consentChecked, setConsentChecked] = useState<boolean>(false)

  const { notesPagination, setNotesPagination, pageNote, areNotesFetching } =
    useExportNotes(memberId)

  const {
    documentsPagination,
    setDocumentsPagination,
    pageDocument,
    areDocumentsFetching,
  } = useExportDocuments(memberId)

  const [exportMemberRecords, { isLoading: isExportLoading }] =
    useExportMemberInformationMutation()

  const handleNotesSelection = useCallback(
    (selectedNotes: Note[]) => {
      setSelectedExportRecords((prevRecords) => ({
        ...prevRecords,
        [memberId]: {
          ...prevRecords[memberId],
          notes: selectedNotes
            .map((note) => ({
              id: note.id,
              sortDate: note.updated_at,
            }))
            // Sorting by date is needed for the PDF export artifacts to appear in the correct order
            // rather than the order they were selected in.
            .sort((a, b) => b.sortDate.localeCompare(a.sortDate)),
        },
      }))
    },
    [memberId, setSelectedExportRecords],
  )

  const handleDocumentsSelection = useCallback(
    (selectedDocuments: MemberDocument[]) =>
      setSelectedExportRecords((prevRecords) => ({
        ...prevRecords,
        [memberId]: {
          ...prevRecords[memberId],
          documents: selectedDocuments
            .map((document) => ({
              id: document.id,
              sortDate: document.created_at,
            }))
            // Sorting by date is needed for the PDF export artifacts to appear in the correct order
            // rather than the order they were selected in.
            .sort((a, b) => b.sortDate.localeCompare(a.sortDate)),
        },
      })),
    [memberId, setSelectedExportRecords],
  )

  const handleConsentChange = useCallback((e: CheckboxChangeEvent) => {
    setConsentChecked(e.target.checked)
  }, [])

  const handleOpenConfirmation = useCallback(() => {
    setExportConfirmationOpen(true)
  }, [])

  const handleCloseConfirmation = useCallback(() => {
    setExportConfirmationOpen(false)
  }, [])

  const navigateToMemberProfile = useCallback(() => {
    navigate(routes.memberProfile(memberId, ProfileTab.Documents))
  }, [memberId, navigate])

  const handleConfirmation = useCallback(async () => {
    const noteIds = selectedExportRecords[memberId]?.notes?.map(({ id }) => id)
    const documentIds = selectedExportRecords[memberId]?.documents?.map(
      ({ id }) => id,
    )

    try {
      await exportMemberRecords({
        memberId,
        bodyMemberExportMemberInformation: {
          note_ids: noteIds,
          document_ids: documentIds,
        },
      }).unwrap()

      close()
      setExportConfirmationOpen(false)
      setConsentChecked(false)
      notification.info({
        duration: 10,
        message: t('memberExport.confirmationModal.notification.message'),
        description: t(
          'memberExport.confirmationModal.notification.description',
        ),
        btn: (
          <Button type="link" onClick={navigateToMemberProfile}>
            {t('memberExport.confirmationModal.notification.link')}
          </Button>
        ),
      })
      setSelectedExportRecords((prevRecords) => {
        delete prevRecords[memberId]
        return { ...prevRecords }
      })
    } catch (error) {
      let description = t('memberExport.errors.defaultDescription')

      if ((error.data?.detail as string)?.includes('Document extensions')) {
        description = t('memberExport.errors.invalidExtension')
      }

      datadogLogs.logger.error(
        'Error exporting member records',
        {
          memberId,
          noteIds,
          documentIds,
        },
        error,
      )
      notification.error({
        message: t('memberExport.errors.message'),
        description,
      })
    }
  }, [
    close,
    exportMemberRecords,
    memberId,
    navigateToMemberProfile,
    selectedExportRecords,
    setSelectedExportRecords,
    t,
  ])

  const exportDisabled =
    !selectedExportRecords[memberId]?.notes?.length &&
    !selectedExportRecords[memberId]?.documents?.length

  return (
    <>
      <Typography.Title level={2} style={{ fontWeight: 500 }}>
        {t('memberExport.title')}
      </Typography.Title>
      <ExportSectionTable
        memberId={memberId}
        dataSource={pageNote.data}
        loading={areNotesFetching}
        identifier="notes"
        onSelectionChange={handleNotesSelection}
        onChange={setNotesPagination}
        pagination={{
          ...notesPagination,
          total: pageNote.meta.total_items,
          simple: true,
        }}
      />
      <ExportSectionTable
        memberId={memberId}
        dataSource={pageDocument.data}
        loading={areDocumentsFetching}
        identifier="documents"
        onSelectionChange={handleDocumentsSelection}
        onChange={setDocumentsPagination}
        pagination={{
          ...documentsPagination,
          total: pageDocument.meta.total_items,
          simple: true,
        }}
      />
      <ExportFooter>
        <Button type="text" onClick={close}>
          {tCommon('button.cancel')}
        </Button>
        <Button
          type="primary"
          onClick={handleOpenConfirmation}
          disabled={exportDisabled}
          data-cy="member-record-export-button"
        >
          {t('memberExport.exportAsPdf')}
        </Button>
      </ExportFooter>

      <ConfirmationModal
        consentChecked={consentChecked}
        onChange={handleConsentChange}
        message={t('memberExport.confirmationModal.message')}
        checkboxLabel={t('memberExport.confirmationModal.checkboxLabel')}
        open={exportConfirmationOpen}
        confirmLoading={isExportLoading}
        onCancel={handleCloseConfirmation}
        onOk={handleConfirmation}
        title={t('memberExport.confirmationModal.title')}
        destroyOnClose
        okText={tCommon('button.export')}
        okButtonProps={{
          disabled: !consentChecked,
          // @ts-expect-error bad type from antd
          'data-testid': 'member-record-export-confirm-button',
        }}
        cancelButtonProps={{
          // @ts-expect-error bad type from antd
          'data-testid': 'member-record-export-cancel-button',
        }}
      />
    </>
  )
}

const ExportFooter = styled.footer`
  display: flex;
  justify-content: end;
  position: sticky;
  z-index: 1;
  bottom: 0;
  height: ${FOOTER_HEIGHT}px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px #00000026;
  padding: 20px;
  margin: auto -22px 0;
`
