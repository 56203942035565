import type {
  ItemResponseNoteWithSubNote,
  NoteStatus,
  NoteType,
  PageNote,
} from '@dialogue/notepad-client'
import { createApi } from '@reduxjs/toolkit/query/react'

import { API_NAMES, getDynamicBaseUrl } from './utils'

export enum Tags {
  PatientNotes = 'PatientNotes',
  UserDrafts = 'UserDrafts',
  PatientCarePlan = 'PatientCarePlan',
}

export const notepadApi = createApi({
  tagTypes: Object.values(Tags),
  reducerPath: 'notepadApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.NOTEPAD),
  endpoints: (builder) => ({
    getPatientNote: builder.query({
      query: ({
        patientId,
        noteId,
      }: {
        patientId: number
        noteId: number
      }) => ({
        url: `/patients/${patientId}/notes/${noteId}`,
      }),
      providesTags: (_result, _error, { patientId }) => [
        { type: Tags.PatientNotes, id: patientId },
      ],
      transformResponse: (response: ItemResponseNoteWithSubNote) =>
        response.data,
    }),
    getPatientCarePlan: builder.query({
      query: ({
        patientId,
        noteId,
      }: {
        patientId: number
        noteId: number
      }) => ({
        method: 'GET',
        url: `/patients/${patientId}/notes/${noteId}/care_plan`,
      }),
    }),
    getPatientNotes: builder.query<
      PageNote,
      {
        patientId: number
        episodeId?: string
        title?: string
        status?: NoteStatus
        offset?: number
        limit?: number
        types?: Array<NoteType>
      }
    >({
      query: ({ patientId, ...params }) => ({
        url: `/patients/${patientId}/notes/`,
        params,
      }),
      providesTags: (_result, _error, { patientId }) => [
        { type: Tags.PatientNotes, id: patientId },
      ],
    }),
    getMyDraftNotes: builder.query<
      PageNote,
      {
        limit?: number
        offset?: number
      }
    >({
      query: ({ limit, offset }) => ({
        url: '/provider/drafts',
        params: {
          limit,
          offset,
        },
      }),
      providesTags: (_result, _error) => [{ type: Tags.UserDrafts }],
    }),
  }),
})
export const {
  useGetPatientNoteQuery,
  useLazyGetPatientCarePlanQuery,
  useGetPatientNotesQuery,
  useGetMyDraftNotesQuery,
} = notepadApi
