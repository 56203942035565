import {
  DocumentSortOption,
  type MemberApiGetDocumentsRequest,
} from '@dialogue/document-center'
import type { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import {
  fetchBaseQuery,
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import isNil from 'lodash/isNil'
import snakeCase from 'lodash/snakeCase'

import config from 'app/config'
import type { SelectedDocumentFilters } from 'app/containers/documents/filters/selectors'
import type { ReduxState } from 'app/redux'

import { selectAccessToken } from '../authentification/selectors'

export const fetchBaseQueryParams: FetchBaseQueryArgs = {
  prepareHeaders: (headers, api) => {
    // can't type state as it creates a circular dependency
    const accessToken = selectAccessToken(api.getState() as ReduxState)

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
  },
  paramsSerializer: (params: Record<string, any>): string => {
    return (
      Object.entries(params)
        // 1. Convert keys from camel to snake case:
        .map(([k, v]) => [snakeCase(k), v])
        // 2. Ignore any undefined/null values:
        .filter(([_, v]) => !isNil(v))
        // 3. Turn arrays into repeating keys (Fast api doesn't support array params):
        .reduce((acc, [k, v]) => {
          if (Array.isArray(v)) {
            v.forEach((vv) => acc.append(k, vv))
          } else {
            acc.append(k, v)
          }
          return acc
        }, new URLSearchParams())
        .toString()
    )
  },
}

export const transformMemberDocumentFilters = (
  filters: SelectedDocumentFilters,
): Omit<MemberApiGetDocumentsRequest, 'memberId'> => {
  const { createdAt, ...otherFilters } = filters

  const [createdAfter, createdBefore] = createdAt || []

  return {
    limit: 200, // FIXME: properly set the `offset` and `limit` params when pagination is introduced
    sortBy: DocumentSortOption.created_at_desc, // provide default sort, can be overwritten by `otherFilters`
    ...otherFilters,
    createdBefore,
    createdAfter,
  }
}

export const API_NAMES = {
  EMERGENCY_ROOM: 'emergencyRoom',
  EMERALD: 'emerald',
  DOCUMENT_CENTER: 'documentCenter',
  NOTEPAD: 'notepad',
  TELEPHONE: 'telephone',
  TIMEKEEPER: 'timekeeper',
  SCRIBE: 'scribe',
}

export const resolveBaseUrl = (apiName: string) => {
  switch (apiName) {
    case API_NAMES.EMERGENCY_ROOM:
      return config.EMERGENCY_ROOM_URL.replace('/v1', '')
    case API_NAMES.EMERALD:
      return config.EMERALD_API_URL
    case API_NAMES.DOCUMENT_CENTER:
      return config.DOCUMENT_CENTER_URL
    case API_NAMES.NOTEPAD:
      return config.NOTEPAD_URL
    case API_NAMES.TELEPHONE:
      return config.TELEPHONE_SERVICE_URL
    case API_NAMES.TIMEKEEPER:
      return config.TIMEKEEPER_URL
    case API_NAMES.SCRIBE:
      return config.SCRIBE_DOMAIN
    default:
      console.warn(`Missing config for ${apiName}`)
      return apiName
  }
}

export const getDynamicBaseUrl = (apiName: string) => {
  const dynamicBaseUrl: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const updatedBaseUrl = resolveBaseUrl(apiName)
    const urlEnd = typeof args === 'string' ? args : args.url
    const adjustedUrl = `${updatedBaseUrl}${urlEnd}`
    const adjustedArgs =
      typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }

    const baseQuery = fetchBaseQuery(fetchBaseQueryParams)

    return baseQuery(adjustedArgs, api, { extraOptions })
  }

  return dynamicBaseUrl
}
