import { type ReactNode, Suspense } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

const SpinContainer = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const SuspenseWrapper = (props: { children: ReactNode }) => {
  const { t } = useTranslation('common')
  return (
    <Suspense
      {...props}
      fallback={
        <SpinContainer>
          <Spin size="large" tip={t('loader.loading')} />
        </SpinContainer>
      }
    />
  )
}
