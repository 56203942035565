import {
  ActivityTypeEnum,
  MemberDocumentType,
  type Activity,
} from '@dialogue/document-center'
import moment from 'moment'

import type { DocumentActivityMetadata } from 'app/components/activities/types'
import i18n from 'app/i18n'
import { formatPhoneNumber } from 'app/lib/helpers'
import type { ViewerMemberDocument } from 'app/redux/documents/viewer'

import { formatDocumentType } from '../../helpers'

export interface ActivityMetadataField {
  value: string
  copyable?: boolean
}

export const parseDocumentName = (name: string) => {
  const lastPeriod = name.lastIndexOf('.')

  if (lastPeriod === -1) return [name]

  const fileName = name.substring(0, lastPeriod)
  const extension = name.substring(lastPeriod + 1)

  return [fileName, extension]
}

export const DOCUMENT_TYPE_ORDER = [
  MemberDocumentType.laboratory_results,
  MemberDocumentType.medical_imaging_reports,
  MemberDocumentType.other,
  MemberDocumentType.consult_report,
  MemberDocumentType.medical_attestation,
  MemberDocumentType.requisition,
  MemberDocumentType.requisition_communication,
  MemberDocumentType.prescription,
  MemberDocumentType.pharmacy,
  MemberDocumentType.insurance,
  MemberDocumentType.other_third_party_communication,
  MemberDocumentType.external_record_received,
  MemberDocumentType.file_transfer_request,
  MemberDocumentType.member_record_export,
  MemberDocumentType.external_patient,
  MemberDocumentType.external_medical_note,
  MemberDocumentType.external_medical_file,
]

// Converts any changed form values that are undefined (ie, have been cleared) to null for the API
export const convertUndefinedToNull = <T>(obj: T): T => {
  const result: Record<string, unknown> = {}
  Object.keys(obj as Record<string, unknown>).forEach((key) => {
    result[key] = obj[key as keyof T] === undefined ? null : obj[key as keyof T]
  })
  return result as T
}

export const disableFutureDates = (current: moment.Moment) => {
  return current && current > moment().endOf('day')
}

export const dateFormat = 'YYYY-MM-DD'

// Custom sorting provided by OPS (not alphabetical)
export const documentTypeOptions = () =>
  DOCUMENT_TYPE_ORDER.map((type) => ({
    label: formatDocumentType(type),
    value: type,
    'data-testid': 'document-type-option',
  }))

export const mergeDocumentActivity = (
  document: ViewerMemberDocument,
): Activity[] => {
  /*
    For now, DC does not create activity records for all updates to a document.
    Until the remaining activity records are created, we need to build up the activity history
    using the available activity records + data from timestamp columns.
  */
  const {
    created_by_provider,
    content_updated_by_provider,
    content_locked_by_provider,
    ih_id_updated_by_provider,
    deleted_by_provider,
    created_at,
    content_updated_at,
    content_locked_at,
    activities,
    ih_id_updated_at,
    deleted_at,
  } = document

  const activitiesList: Activity[] = []

  // Appending the activity timestamp to the id to ensure uniqueness
  // Note that userNickname will be populated in the component

  if (content_locked_at && content_locked_by_provider) {
    activitiesList.push({
      id: 1,
      timestamp: content_locked_at,
      user_id: content_locked_by_provider,
      activity_type: 'signed',
    })
  }

  if (ih_id_updated_at && ih_id_updated_by_provider) {
    activitiesList.push({
      id: 2,
      timestamp: ih_id_updated_at,
      user_id: ih_id_updated_by_provider,
      activity_type: 'uploadedToIh',
    })
  }

  if (deleted_at && deleted_by_provider) {
    activitiesList.push({
      id: 3,
      timestamp: deleted_at,
      user_id: deleted_by_provider,
      activity_type: 'deleted',
    })
  }

  activitiesList.push(
    ...activities.map((activity) => ({
      id: activity.id,
      timestamp: activity.timestamp,
      user_id: activity?.user_id,
      activity_type: activity.activity_type,

      metadata: activity.metadata,
    })),
  )
  const hasFaxReceived = activitiesList.some(
    (activity) => activity.activity_type === 'fax_received',
  )

  const hasExported = activitiesList.some(
    (activity) => activity.activity_type === 'export',
  )

  // If the doc doesn't have fax_received or exported activity, we add a 'created' activity
  if (!hasFaxReceived && !hasExported) {
    activitiesList.push({
      id: 4,
      timestamp: created_at,
      user_id: created_by_provider,
      activity_type: 'created',
    })
  }

  const alreadyUpdated = activitiesList.some(
    (activity) => activity.activity_type === 'update',
  )

  if (content_updated_at && content_updated_by_provider && !alreadyUpdated) {
    activitiesList.push({
      id: 5,
      timestamp: content_updated_at,
      user_id: content_updated_by_provider,
      activity_type: 'update',
    })
  }

  return activitiesList
}

/**
 * A helper function for extracting the activity metadata fields we want to display
 * in the activity log.
 *
 * FIXME: We should remove/rework this once DC implements a more flexible, type-safe way to
 * handle metadata fields.
 *
 * @see https://www.notion.so/godialogue/Mini-TD-Rethink-Document-Metadata-14563c5c5954803aa741f8635a42b4e0?pvs=4
 *
 */
export const getDisplayableActivityMetadata = (activity: Activity) => {
  const metadata = activity.metadata as DocumentActivityMetadata

  if (!metadata) {
    return []
  }

  const metadataFields: ActivityMetadataField[] = []

  if (metadata.receiver_number) {
    metadataFields.push({
      value: formatPhoneNumber(metadata.receiver_number),
      copyable: true,
    })
  }

  if (metadata.priority) {
    metadataFields.push({
      value: i18n.t(`tasks.values.priority.${metadata.priority}`, {
        defaultValue: metadata.priority.toString(),
      }),
    })
  }

  if (metadata.member_id) {
    switch (activity.activity_type) {
      case ActivityTypeEnum.triage:
        metadataFields.push({
          value: i18n.t('translation:documents.activity.metadata.triage', {
            memberId: metadata.member_id,
            defaultValue: `Assigned to DIA ID: ${metadata.member_id}`,
          }),
        })
        break

      case ActivityTypeEnum.untriage:
        metadataFields.push({
          value: i18n.t('translation:documents.activity.metadata.untriage', {
            memberId: metadata.member_id,
            defaultValue: `Unassigned from DIA ID: ${metadata.member_id}`,
          }),
        })
        break

      default:
        console.warn(
          'Unexpected activity type for member_id metadata:',
          activity.activity_type,
          metadata,
        )
        break
    }
  }

  return metadataFields
}
