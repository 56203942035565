import { useCallback, type PropsWithChildren, type ReactNode } from 'react'

import { baseColors } from '@dialogue/basics'
import { Alert, List, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

export const StyledListItem = styled(List.Item)`
  &&& {
    padding: 12px 0;
    cursor: pointer;
    &:hover {
      background: ${baseColors.smokeLight};
    }
  }
`
const StyledAlert = styled(Alert)`
  border: none;
  border-radius: 0;
`

const StyledText = styled(Typography.Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export interface BaseOption {
  label: string | ReactNode
  value: string
}

export const ListItem = <T extends BaseOption>({
  option,
  onClick,
}: {
  option: T
  onClick: (value: string) => void
}) => {
  const handleClick = useCallback(() => {
    onClick(option.value)
  }, [onClick, option])
  return (
    <StyledListItem
      data-testid={`list-item-${option.value}`}
      onClick={handleClick}
    >
      <StyledText>{option?.label}</StyledText>
    </StyledListItem>
  )
}

const DefaultListContainer = ({ children }: PropsWithChildren) => (
  <div>{children}</div>
)

export interface ListSelectProps<T> {
  onSelect: (id: string) => void
  options?: T[]
  errorMessage: string
  hasError?: boolean
  emptyText?: string
  listContainerRender?: (props: PropsWithChildren) => ReactNode
  loading?: boolean
}

export const ListSelect = <T extends BaseOption>({
  options,
  onSelect,
  loading,
  errorMessage,
  hasError,
  emptyText,
  listContainerRender,
}: ListSelectProps<T>) => {
  const { t: tCommon } = useTranslation('common')

  const renderItem = useCallback(
    (option: T) => <ListItem option={option} onClick={onSelect} />,
    [onSelect],
  )

  const ListContainer = listContainerRender || DefaultListContainer

  return (
    <>
      {hasError && <StyledAlert type="error" message={errorMessage} showIcon />}
      <ListContainer>
        <List
          dataSource={options}
          renderItem={renderItem}
          loading={loading}
          locale={{ emptyText: emptyText || tCommon('list.noData') }}
        />
      </ListContainer>
    </>
  )
}
