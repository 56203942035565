import { useCallback, useState } from 'react'

import {
  DocumentSortOption,
  MemberDocumentType,
  type PageMemberDocument,
} from '@dialogue/document-center'
import { NoteStatus, NoteType, type PageNote } from '@dialogue/notepad-client'
import type { TablePaginationConfig } from 'antd'

import { useAppSelector } from 'app/hooks'
import { formatPractitionerName } from 'app/lib/helpers'
import { useGetPaginatedMemberDocumentsQuery } from 'app/redux/api/document-center/member-documents'
import { useGetPatientNotesQuery } from 'app/redux/api/notepadApi'

import { formatDocumentType } from '../documents/helpers'

import type { SharedExportProperties } from './types'
import { isMemberDocument, isNote } from './utils'

export const useExportNotes = (memberId: number) => {
  const [notesPagination, setNotesPagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 50,
    },
  )

  const {
    data: pageNote = {
      data: [],
      meta: { total_items: 0, offset: 0, page_number: 0, total_pages: 0 },
    } satisfies PageNote,
    isFetching: areNotesFetching,
  } = useGetPatientNotesQuery({
    patientId: memberId,
    status: NoteStatus.Completed,
    limit: notesPagination.pageSize,
    offset:
      notesPagination.current && notesPagination.pageSize
        ? (notesPagination.current - 1) * notesPagination.pageSize
        : undefined,
    // We do not support exporting care plans and next steps.
    types: [NoteType.Consult, NoteType.NursingRsoap, NoteType.Prescription],
  })

  return {
    notesPagination,
    setNotesPagination,
    pageNote,
    areNotesFetching,
  }
}

export const useExportDocuments = (memberId: number) => {
  const [documentsPagination, setDocumentsPagination] =
    useState<TablePaginationConfig>({
      current: 1,
      pageSize: 50,
    })

  // Adding "" as part of acceptedDocumentTypes to allow for
  // the fetching of documents without any type
  const acceptedDocumentTypes = [
    '',
    ...Object.values(MemberDocumentType).filter(
      (type) => type !== MemberDocumentType.member_record_export,
    ),
  ]

  const {
    data: pageDocument = {
      data: [],
      meta: {
        total_items: 0,
        offset: 0,
        page_number: 0,
        total_pages: 0,
      },
    } satisfies PageMemberDocument,
    isFetching: areDocumentsFetching,
  } = useGetPaginatedMemberDocumentsQuery({
    memberId,
    types: acceptedDocumentTypes,
    sortBy: DocumentSortOption.created_at_desc,
    limit: documentsPagination.pageSize,
    offset:
      documentsPagination.current && documentsPagination.pageSize
        ? (documentsPagination.current - 1) * documentsPagination.pageSize
        : undefined,
  })

  return {
    pageDocument,
    areDocumentsFetching,
    documentsPagination,
    setDocumentsPagination,
  }
}

export const useGetSummaryInfo = () => {
  const providers = useAppSelector((state) => state.practitioners?.profiles)

  const getSummaryInfo = useCallback(
    (record: SharedExportProperties) => {
      let title: string, subtitle: string

      if (isMemberDocument(record)) {
        title = record.name
        subtitle = formatDocumentType(record.type)

        return { title, subtitle }
      }

      if (isNote(record)) {
        const providerProfile = providers?.[record.created_by]
        const providerName = formatPractitionerName(
          providerProfile,
          record.created_by.toString(),
        )

        title = record.template.title[record.language]
        subtitle = providerName ?? ''
        return { title, subtitle }
      }

      throw new Error('Invalid record type')
    },
    [providers],
  )

  return { getSummaryInfo }
}
