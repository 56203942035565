import { Checkbox, Modal, Space, Typography, type ModalProps } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'

export interface ConfirmationModalProps extends ModalProps {
  open: boolean
  consentChecked: boolean
  onChange: (e: CheckboxChangeEvent) => void
  message: string
  checkboxLabel: string
}

export const ConfirmationModal = ({
  consentChecked,
  onChange,
  message,
  checkboxLabel,
  ...rest
}: ConfirmationModalProps) => {
  return (
    <Modal {...rest}>
      <Space size={8} direction="vertical">
        <Typography.Text type="secondary">{message}</Typography.Text>

        <Space size={6}>
          <Checkbox
            data-testid="confirmation-consent"
            checked={consentChecked}
            onChange={onChange}
          >
            <Typography.Text>{checkboxLabel}</Typography.Text>
          </Checkbox>
        </Space>
      </Space>
    </Modal>
  )
}
