import {
  DocumentFormat,
  DocumentSortOption,
  type MemberDocument,
} from '@dialogue/document-center'
import type { EmeraldTypes } from '@dialogue/services'
import type { SortOrder } from 'antd/es/table/interface'
import {
  parsePhoneNumberWithError,
  type CountryCode,
  type PhoneNumber,
} from 'libphonenumber-js'

import i18n from 'app/i18n'
import { defaultCountry } from 'app/lib/data-models'
import {
  isDocumentCenterDocument,
  type ViewerDocument,
} from 'app/redux/documents/viewer'

export const formatDocumentType = (
  type: EmeraldTypes.Document['type'] | MemberDocument['type'],
): string =>
  i18n.t(`documents.documentTypes.${type}`, {
    defaultValue: i18n.t('common:notAvailable'),
  })

export const getCreatedAtSortByType = (order: SortOrder): DocumentSortOption =>
  order === 'ascend'
    ? DocumentSortOption.created_at_asc
    : DocumentSortOption.created_at_desc

export const parsePhoneNumber = (raw_number: string): PhoneNumber | null => {
  try {
    return parsePhoneNumberWithError(raw_number, {
      defaultCountry: defaultCountry as CountryCode,
      extract: false, // use stricter matching
    })
  } catch (e) {
    console.error(`Could not parse phone number. Error: ${e}`)
    return null
  }
}

export const extractPhoneNumberAreaCode = (
  raw_number: string,
): string | null => {
  const phoneNumber = parsePhoneNumber(raw_number)
  return phoneNumber !== null ? phoneNumber.nationalNumber.slice(0, 3) : null
}

export const extractPhoneNumberMinusAreaCode = (
  raw_number: string,
): string | null => {
  const phoneNumber = parsePhoneNumber(raw_number)
  return phoneNumber !== null ? phoneNumber.nationalNumber.slice(-7) : null
}

export const safeJoin = <T>(arr: T[], separator: string): string => {
  return arr.filter(Boolean).join(separator)
}

export const isUnsupportedDocument = (document: ViewerDocument): boolean =>
  isDocumentCenterDocument(document) && document.format === DocumentFormat.other
