import { useCallback, useMemo } from 'react'

import { TeamOutlined } from '@ant-design/icons'
import { datadogLogs } from '@datadog/browser-logs'
import { notification, type ModalProps, Typography, Space } from 'antd'
import { useTranslation } from 'react-i18next'

import { usePreferredPharmacy } from 'app/components/preferred-pharmacy'
import { defaultCountry } from 'app/lib/data-models'
import { useUpdatePharmacyMutation } from 'app/redux/api/emergency-room/pharmacies'
import { colors } from 'app/theme'

import PharmacyModal, { type FormValues } from './base-pharmacy-modal'
import PreferredPharmacyForm from './preferred-pharmacy-form'

interface Props extends ModalProps {
  patientId: number
  onClose: () => void
}

const EditPharmacyModal = ({ patientId, onClose, ...rest }: Props) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')

  const [editPharmacy, { isLoading }] = useUpdatePharmacyMutation()
  const { pharmacy } = usePreferredPharmacy(patientId)

  const pharmacyHasMultipleMembersLinked =
    pharmacy?.members_count && pharmacy.members_count > 1

  const initialValues: Partial<FormValues> = useMemo(() => {
    if (!pharmacy) return {}
    return {
      name: pharmacy.name,
      street_number: pharmacy.street_number || '',
      street_number2: pharmacy.street_number2 || '',
      locality: pharmacy.locality || '',
      admin_area_iso_code: pharmacy.admin_area_iso_code || '',
      postal_code: pharmacy.postal_code || '',
      country: defaultCountry,
      fax_number: pharmacy.fax_number || '',
      phone_number: pharmacy.phone_number || '',
    }
  }, [pharmacy])

  const handleSubmit = useCallback(
    (values: FormValues) => {
      if (!pharmacy) return
      const { country, ...updatePharmacy } = values
      editPharmacy({ pharmacyId: pharmacy.id, updatePharmacy })
        .unwrap()
        .then(() => onClose())
        .catch((error) => {
          datadogLogs.logger.error(
            'Failed to edit preferred pharmacy',
            { memberId: patientId },
            error,
          )
          notification.error({
            message: t(
              'medicalProfile.preferredPharmacy.modal.editPharmacyError',
            ),
          })
        })
    },
    [pharmacy, patientId, editPharmacy, onClose, t],
  )

  return (
    <PharmacyModal
      title={t('medicalProfile.preferredPharmacy.modal.editPharmacy')}
      okText={tCommon('button.saveChanges')}
      isLoading={isLoading}
      initialValues={initialValues}
      onCancel={onClose}
      onSubmit={handleSubmit}
      {...rest}
    >
      <PreferredPharmacyForm />
      {pharmacyHasMultipleMembersLinked && (
        <Space>
          <TeamOutlined
            css={`
              color: ${colors.onSurfaceLight};
            `}
          />
          <Typography.Text color={colors.textDark}>
            {t('patientProfile.pharmacy.multipleMembersText', {
              members_count: pharmacy.members_count,
            })}
          </Typography.Text>
        </Space>
      )}
    </PharmacyModal>
  )
}

export default EditPharmacyModal
