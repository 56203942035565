import { useCallback, useContext, type MouseEvent } from 'react'

import { ArrowRightOutlined } from '@ant-design/icons'
import { baseColors } from '@dialogue/basics'
import type { Task, TaskStatus, TaskWithLinkedTasks } from '@dialogue/coredata'
import { styled } from 'styled-components'

import { TaskDrawerContext } from 'app/containers/tasks/task-drawer-context'

import { EnrichedTaskDetails, TaskDetails } from './task-row-details'
import TaskTitle from './task-row-title'

const StyledRightArrow = styled(ArrowRightOutlined)`
  padding-right: 6px;
  color: ${baseColors.slate};
`

const TaskRowContainer = styled.div<{
  status: TaskStatus
  enrichedView: boolean
}>`
  display: flex;
  padding: 8px 0;
  border-bottom: ${(props) =>
    props.enrichedView ? 'none' : `1px solid ${baseColors.smoke}`};
  cursor: pointer;

  .task-row-title {
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
    margin-bottom: 0;
    flex: 1;
    display: inline-grid;
    grid-template-columns: auto auto 1fr;
  }

  .task-row-details {
    float: right;
  }
`

interface Props {
  task: TaskWithLinkedTasks | Task
  enrichedView?: boolean
}

const isHTMLElement = (target: EventTarget): target is HTMLInputElement =>
  'id' in target

export const TaskRow = ({ task, enrichedView = false }: Props) => {
  const { setDrawerTask } = useContext(TaskDrawerContext)

  const handleClick = useCallback(
    ({ target }: MouseEvent<HTMLElement>) => {
      // Prevent modal from opening if the checkbox is clicked
      if (isHTMLElement(target) && target.type === 'checkbox') return

      setDrawerTask(task.id)
    },
    [setDrawerTask, task],
  )

  const showArrow = enrichedView && !!task.created_from_id

  return (
    <TaskRowContainer
      data-testid={'task-row'}
      key={task.id}
      data-cy={`task-row-${task.id}`}
      onClick={handleClick}
      status={task.status}
      enrichedView={enrichedView}
    >
      {showArrow && <StyledRightArrow />}
      <TaskTitle
        title={task.title}
        priority={task.priority}
        status={task.status}
      />
      {enrichedView ? (
        <EnrichedTaskDetails task={task} />
      ) : (
        <TaskDetails task={task} />
      )}
    </TaskRowContainer>
  )
}
