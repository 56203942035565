import { type ChangeEvent, useCallback } from 'react'

import { Input, Typography } from 'antd'
import { styled } from 'styled-components'

import { colors } from 'app/theme'

import { DescriptionTemplateSelect } from './description-template-select'

const StyledTextArea = styled(Input.TextArea)`
  &.ant-input-disabled {
    color: ${colors.onSurface};
  }
`

interface TaskDescripitionFieldProps {
  onChange?: (value?: string) => void
  value?: string
  disabled?: boolean
  label: string
  placeholder?: string
}

export const TaskDescripitionInput = ({
  onChange,
  value,
  disabled,
  label,
  ...textAreaProps
}: TaskDescripitionFieldProps) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (!onChange) return
      onChange(event.target.value)
    },
    [onChange],
  )

  return (
    <div>
      <div css={'display: flex; justify-content: space-between;'}>
        <Typography.Text data-dd-privacy="allow">{label}</Typography.Text>
        <DescriptionTemplateSelect
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <StyledTextArea
        autoSize={{ minRows: 4 }}
        onChange={handleOnChange}
        value={value}
        disabled={disabled}
        {...textAreaProps}
      />
    </div>
  )
}
