import { useCallback } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { notification, type ModalProps } from 'antd'
import { useTranslation } from 'react-i18next'

import { defaultCountry } from 'app/lib/data-models'
import { useUpdatePreferredPharmacyMutation } from 'app/redux/api/emergency-room/members'

import PharmacyModal, { type FormValues } from './base-pharmacy-modal'
import PreferredPharmacyForm from './preferred-pharmacy-form'

interface Props extends ModalProps {
  patientId: number
  onClose: () => void
}

const AddPharmacyModal = ({ patientId, onClose, ...rest }: Props) => {
  const { t } = useTranslation()

  const [addPharmacy, { isLoading: isAdding }] =
    useUpdatePreferredPharmacyMutation()

  const initialValues = {
    country: defaultCountry,
  }

  const handleSubmit = useCallback(
    (values: FormValues) => {
      addPharmacy({ patientId, newPharmacy: values })
        .unwrap()
        .then(() => onClose())
        .catch((error) => {
          datadogLogs.logger.error(
            'Failed to add preferred pharmacy',
            { memberId: patientId },
            error,
          )
          notification.error({
            message: t(
              'medicalProfile.preferredPharmacy.modal.addPharmacyError',
            ),
          })
        })
    },
    [patientId, addPharmacy, onClose, t],
  )

  return (
    <PharmacyModal
      title={t('medicalProfile.preferredPharmacy.modal.addPharmacy')}
      okText={t('medicalProfile.preferredPharmacy.modal.addPharmacy')}
      isLoading={isAdding}
      onCancel={onClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      {...rest}
    >
      <PreferredPharmacyForm />
    </PharmacyModal>
  )
}

export default AddPharmacyModal
