import { useCallback, type MouseEvent, forwardRef } from 'react'

import { Button, type ButtonProps } from 'antd'
import { useLinkClickHandler, useHref } from 'react-router-dom'

export interface NavButtonProps extends Omit<ButtonProps, 'href' | 'htmlType'> {
  /** The target path for the link. This will be passed to React Router's [`useHref` hook](https://reactrouter.com/6.30.0/hooks/use-href). */
  to: string
}

/**
 * Generic component to render a navigation link as an AntD `Button`.
 * This wraps React Router's own [`Link` component](https://reactrouter.com/6.30.0/components/link).
 */
export const NavButton = forwardRef<HTMLElement, NavButtonProps>(
  ({ to, onClick, target, ...rest }, ref) => {
    const href = useHref(to)
    const routerClickHandler = useLinkClickHandler(to, { target })

    const handleClick = useCallback(
      (event: MouseEvent<HTMLAnchorElement>) => {
        onClick?.(event)
        if (!event.defaultPrevented) {
          routerClickHandler(event)
        }
      },
      [onClick, routerClickHandler],
    )

    return (
      <Button
        {...rest}
        href={href}
        htmlType="button"
        onClick={handleClick}
        ref={ref}
        target={target}
      />
    )
  },
)
