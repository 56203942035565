import { DocumentKind, type ViewerDocument } from 'app/redux/documents/viewer'

import { AnticipatedOutgoingFaxPanel } from './anticipated-outgoing-fax-panel'
import type { OutgoingFaxMetadata } from './common'
import { IncomingFaxPanel } from './incoming-fax-panel'
import { MemberPanel } from './member-panel'
import { OutgoingFaxPanel } from './outgoing-fax-panel'

interface Props {
  document: ViewerDocument
  onOutgoingFaxDocumentCancel: () => void
  onConfirmSendFax: () => void
  onClose: () => void
  outgoingFaxMetadata: OutgoingFaxMetadata | null
}

export const DocumentInfoPanel = ({
  document,
  onOutgoingFaxDocumentCancel,
  onConfirmSendFax,
  onClose,
  outgoingFaxMetadata,
}: Props) => {
  switch (document.kind) {
    case DocumentKind.MEMBER_DOCUMENT:
      return <MemberPanel onClose={onClose} document={document} />

    case DocumentKind.INCOMING_FAX_DOCUMENT:
      return <IncomingFaxPanel document={document} />

    case DocumentKind.OUTGOING_FAX_DOCUMENT:
      return <OutgoingFaxPanel document={document} />

    case DocumentKind.ANTICIPATED_OUTGOING_FAX:
      return (
        <AnticipatedOutgoingFaxPanel
          onCancel={onOutgoingFaxDocumentCancel}
          onConfirm={onConfirmSendFax}
          outgoingFaxMetadata={outgoingFaxMetadata}
        />
      )

    default:
      return null
  }
}
