import React, { type ReactNode, useCallback, useContext } from 'react'

import { UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { OpenMemberProfileDrawerContext } from 'app/containers/patient-profile/drawer'
import routes from 'app/services/routes'

import { NavButton } from './nav-button'

interface Props {
  patientId: number | string
  showIcon?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  text?: ReactNode
}

export const MemberProfileLink = ({
  patientId,
  onClick,
  showIcon = true,
  text,
  ...rest
}: Props & Partial<typeof NavButton>) => {
  const { t } = useTranslation()

  const to = routes.memberProfile(patientId)

  return (
    <NavButton
      type="link"
      size="small"
      to={to}
      onClick={onClick}
      data-testid="member-profile-view-button"
      data-dd-privacy="allow"
      data-dd-action-name="goto:member-profile"
      css={`
        padding-left: 0;
      `}
      {...rest}
    >
      {showIcon && <UserOutlined />} {text ?? t('memberProfile.linkButton')}
    </NavButton>
  )
}

export const MemberProfileDrawerLink = ({ patientId, ...props }: Props) => {
  const { open } = useContext(OpenMemberProfileDrawerContext)

  const handleClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault()
      open()
    },
    [open],
  )

  return (
    <MemberProfileLink
      patientId={patientId}
      onClick={handleClick}
      {...props}
      data-testid="member-profile-drawer-button"
    />
  )
}

export default MemberProfileLink
