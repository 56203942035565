import {
  CheckOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Tooltip, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAppSelector } from 'app/hooks'
import {
  selectAutofillData,
  selectAutofillFetching,
} from 'app/redux/documents/selectors'
import { colors } from 'app/theme'

const { Text } = Typography

const ItemContainer = styled.div`
  display: flex;
  align-items: center;

  .anticon {
    margin-right: 8px;
  }

  .anticon-check {
    color: ${colors.success};
  }

  .anticon-question-circle {
    color: ${colors.warning};
  }

  margin: 8px 0;
`

type SummaryItemType = {
  name: string
  selector: string
  tooltip?: string
}

export const SUMMARY_ITEMS: SummaryItemType[] = [
  {
    name: 'todaysDate',
    selector: 'todays_date',
  },
  {
    name: 'memberInfo',
    selector: 'patient_first_name',
  },
  {
    name: 'providerName',
    selector: 'provider_full_name',
  },
  {
    name: 'licenseNumber',
    selector: 'provider_license_number',
    tooltip: 'license',
  },
  {
    name: 'dialogueAddress',
    selector: 'dialogue_province',
    tooltip: 'location',
  },
  {
    name: 'phoneNumber',
    selector: 'dialogue_province',
    tooltip: 'location',
  },
  {
    name: 'faxNumber',
    selector: 'dialogue_province',
    tooltip: 'location',
  },
]

const SummaryItem = ({ name, selector, tooltip }: SummaryItemType) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'documents.autofillPopover',
  })

  const data = useAppSelector(selectAutofillData)
  const loading = useAppSelector(selectAutofillFetching)
  const valid = Boolean(data[selector])

  return (
    <ItemContainer>
      {loading ? (
        <LoadingOutlined />
      ) : valid ? (
        <CheckOutlined />
      ) : tooltip ? (
        <Tooltip title={t(`tooltips.${tooltip}` as any)}>
          <QuestionCircleOutlined />
        </Tooltip>
      ) : (
        <QuestionCircleOutlined />
      )}
      <Text strong>{t(`summaryItems.${name}` as any)}</Text>
    </ItemContainer>
  )
}

export default SummaryItem
