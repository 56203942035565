import { useMemo } from 'react'

import { Col, Form, Input, Row, Select } from 'antd'
import { useTranslation } from 'react-i18next'

import { adminAreas } from 'app/lib/data-models'

const PreferredPharmacyForm = () => {
  const { t } = useTranslation()
  const { t: tf } = useTranslation('translation', {
    keyPrefix: 'medicalProfile.preferredPharmacy.form',
  })

  const regionOptions = useMemo(
    () =>
      adminAreas.map((i) => ({
        value: i.value,
        label: t(i.name),
      })),
    [t],
  )

  return (
    <>
      <Form.Item name="name" label={tf('name')} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Row gutter={18}>
        <Col span={12}>
          <Form.Item
            name="fax_number"
            label={tf('fax')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="phone_number" label={tf('phone')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="street_number"
        label={tf('address')}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="street_number2">
        <Input placeholder={tf('address2')} />
      </Form.Item>
      <Row gutter={18}>
        <Col span={12}>
          <Form.Item
            name="locality"
            label={tf('city')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="admin_area_iso_code"
            label={tf('province')}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              data-testid="member-input-province"
              options={regionOptions}
              data-private
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={18}>
        <Col span={12}>
          <Form.Item
            name="postal_code"
            label={tf('postalCode')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="country"
            label={tf('country')}
            rules={[{ required: true }]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default PreferredPharmacyForm
