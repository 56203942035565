import { useMemo } from 'react'

import { ActivityTypeEnum, type Activity } from '@dialogue/document-center'
import { Timeline, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { ActivityTimelineBlock } from 'app/components/activities'
import { useAppSelector } from 'app/hooks'
import { selectUserIdToNickname } from 'app/redux/practitioners/selectors'

import { ActivitiesContainer, HeaderLine } from './common'
import { getDisplayableActivityMetadata } from './helpers'

interface MergedDocumentActivity extends Activity {
  userNickname: string
}

export const ActivityLog = ({
  activities,
  sortDirection = 'descending',
}: {
  activities: Activity[]
  sortDirection?: 'ascending' | 'descending'
}) => {
  const { t } = useTranslation()
  const userIds = useMemo(() => activities.map((a) => a.user_id), [activities])

  const sortedActivities = useMemo(() => {
    return activities.slice().sort((a, b) => {
      const dateA = new Date(a.timestamp).getTime()
      const dateB = new Date(b.timestamp).getTime()

      return sortDirection === 'ascending' ? dateA - dateB : dateB - dateA
    })
  }, [activities, sortDirection])

  const userIdToNickname = useAppSelector((state) =>
    selectUserIdToNickname(
      state,
      userIds.filter((userId): userId is number => userId !== undefined),
    ),
  )

  const documentActivities: MergedDocumentActivity[] = useMemo(() => {
    return sortedActivities.map((activity) => {
      const userNickname = userIdToNickname[activity.user_id ?? '']
      return {
        ...activity,
        userNickname,
      }
    })
  }, [sortedActivities, userIdToNickname])

  const lockedToolTipMessage = t('documents.activity.lockedToolTip')

  return (
    <>
      <HeaderLine title={t('documents.sections.activity')} />
      <ActivitiesContainer>
        <Typography.Text type="secondary">
          {t('documents.activity.sortOrderText')}
        </Typography.Text>
        <Timeline>
          {documentActivities.map((activity) => {
            const metadataFields = getDisplayableActivityMetadata(activity)

            return (
              <Timeline.Item key={activity.id}>
                <ActivityTimelineBlock
                  personName={activity.userNickname}
                  timestamp={activity.timestamp}
                  metadataFields={metadataFields}
                  activityType={t(
                    `documents.activity.${activity.activity_type}`,
                    activity.activity_type,
                  )}
                  tooltipMessages={
                    activity.activity_type === ActivityTypeEnum.lock
                      ? [lockedToolTipMessage]
                      : []
                  }
                />
              </Timeline.Item>
            )
          })}
        </Timeline>
      </ActivitiesContainer>
    </>
  )
}
