import { useCallback, useState } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { DOCUMENT_ACTION } from 'app/containers/documents/document-actions/types'
import { useAppDispatch } from 'app/hooks'
import { useSplitFaxMutation } from 'app/redux/api/document-center/faxes'
import {
  documentViewerActions,
  type ViewerDocument,
} from 'app/redux/documents/viewer'

import { useApryse } from '../utils'
import { useViewerInstance } from '../viewer-context'

import { SplitDocumentPanel } from './split-document-panel'

interface Props {
  document: ViewerDocument
  documentAction: DOCUMENT_ACTION
  setDocumentAction?: (action: DOCUMENT_ACTION | null) => void
}

export const DocumentActionPanel = ({
  document,
  documentAction,
  setDocumentAction,
}: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const apryse = useApryse()
  const { viewerInstance } = useViewerInstance()

  const [error, setError] = useState<Error | null>(null)

  const [splitFax] = useSplitFaxMutation()

  const cancelDocumentAction = useCallback(() => {
    setDocumentAction?.(null)
  }, [setDocumentAction])

  const splitDocument = useCallback(
    async (pageRangeArray: Array<number[]>) => {
      setError(null)

      if (!viewerInstance?.Core || !document?.id) {
        return
      }

      const { splitDocuments, pageNumbers } = await apryse.splitDocument(
        pageRangeArray,
        viewerInstance.Core,
      )

      // if splitDocuments is undefined, there was an error and we should not call the API
      if (!splitDocuments) {
        return
      }

      splitFax({
        files: splitDocuments,
        documentId: document.id,
        pageNumbers: JSON.stringify(pageNumbers),
      })
        .unwrap()
        .then(() => {
          // Clear document action panel on success
          cancelDocumentAction()

          notification.success({
            message: t('documents.split.success'),
          })

          // Close the viewer
          dispatch(documentViewerActions.close())
        })
        .catch((error) => {
          datadogRum.addError(error)
          setError(error)
        })
    },
    [
      viewerInstance?.Core,
      document.id,
      apryse,
      splitFax,
      cancelDocumentAction,
      dispatch,
      t,
    ],
  )

  switch (documentAction) {
    case DOCUMENT_ACTION.SPLIT:
      return (
        <SplitDocumentPanel
          document={document}
          onConfirm={splitDocument}
          onClosePanel={cancelDocumentAction}
          error={error}
        />
      )

    default:
      console.warn('Unsupported document action:', documentAction)
      break
  }
}
