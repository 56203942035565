import { useCallback, useRef, type KeyboardEventHandler } from 'react'

import type { CommentMention } from '@dialogue/coredata'
import { Button, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import MentionsInput, {
  type MentionsInputRef,
} from 'app/containers/chat/chat-input/mentions'

import { extractMentions } from './helpers'

interface CommentInputProps {
  onSubmit?: (messageContent: string, mentions: CommentMention[]) => void
  disabled?: boolean
}

interface FormValues {
  message_content: string
}

const StyledForm = styled(Form<FormValues>)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
  margin: 8px 0;
`

export const CommentInput = ({
  onSubmit,
  disabled = false,
}: CommentInputProps) => {
  const { t } = useTranslation()

  const [form] = Form.useForm<FormValues>()

  const textInputRef = useRef<MentionsInputRef | null>(null)

  const handleSendMessage = useCallback(
    ({ message_content }: FormValues) => {
      if (message_content?.trim().length > 0) {
        onSubmit?.(
          message_content.trim(),
          extractMentions(message_content, textInputRef.current),
        )
        form.resetFields()
        // needs to run in separate tick from reset to work
        setTimeout(() => {
          if (textInputRef.current) {
            textInputRef.current.focus()
          }
        }, 0)
      }
    },
    [form, onSubmit],
  )

  // Submit a newline if shift key is pressed, otherwise send message
  const handlePressEnter: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.shiftKey) {
        // do nothing, allow newline
      } else {
        e.preventDefault()
        form.submit()
      }
    },
    [form],
  )

  return (
    <StyledForm form={form} onFinish={handleSendMessage} disabled={disabled}>
      <Form.Item
        name="message_content"
        css={`
          margin: 0;
          flex-grow: 1;
        `}
      >
        <MentionsInput
          data-testid="task-comment-input"
          ref={textInputRef}
          onPressEnter={handlePressEnter}
          placeholder={t('taskComments.sendMessagePlaceholder')}
          autoSize={{ maxRows: 5 }}
          css={`
            border-radius: 2px;
          `}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate
        css={`
          margin: 0;
          flex-grow: 0;
        `}
      >
        {() => (
          <Button
            data-testid="task-comment-input-submit"
            disabled={
              disabled || !form.getFieldValue('message_content')?.trim()
            }
            htmlType="submit"
            data-dd-privacy="allow"
            data-dd-action-name="task-comments:send:message"
          >
            {t('taskComments.sendMessageButton')}
          </Button>
        )}
      </Form.Item>
    </StyledForm>
  )
}
