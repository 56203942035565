import { useCallback, useEffect, useMemo } from 'react'

import { FlagOutlined, WarningOutlined } from '@ant-design/icons'
import {
  IncomingFaxDocumentPriority,
  IncomingFaxDocumentStatus,
} from '@dialogue/document-center'
import { Button, Form, Popconfirm } from 'antd'
import { camelizeKeys } from 'humps'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import {
  RadioButonsVariants,
  RadioButtonsGroup,
} from 'app/components/radio-buttons-group'
import { STATUS_COLOR_MAP, StatusTag } from 'app/containers/faxes/common'
import { OwnerField } from 'app/containers/tasks/owner-field'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { formatUserFullName } from 'app/lib/helpers'
import type { ViewerIncomingFaxDocument } from 'app/redux/documents/viewer'
import * as episodeMetaActions from 'app/redux/episode-meta/actions'
import {
  selectIssueTypes,
  selectPropertiesErrors,
} from 'app/redux/episode-meta/selectors'
import { selectResults } from 'app/redux/patient-search/selectors'

import { ActivityLog } from '../activity-log'
import { HeaderLine, LineItem } from '../common'
import { DocumentName } from '../document-name'
import { parseDocumentName } from '../helpers'
import { useModifyFax, useResetFormOnUnprocess } from '../hooks'

import { ProcessedPanel } from './processed-panel'
import { ReceivedPanel } from './received-panel'
import { TriagedPanel } from './triaged-panel'

interface Props {
  document: ViewerIncomingFaxDocument
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const IncomingFaxPanel = ({ document }: Props) => {
  const {
    id: documentId,
    name,
    priority,
    memberDocument,
    status,
    assignedTo,
    teamIds,
  } = camelizeKeys<ViewerIncomingFaxDocument>(document)
  const {
    memberId,
    documentDate,
    type: docType,
    episodeId,
    createdFromNoteId,
  } = memberDocument || {}

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const issueTypes = useAppSelector(selectIssueTypes)
  const issueTypesErrors = useAppSelector(selectPropertiesErrors)
  const patientResults = useAppSelector(selectResults)

  const [currentDocumentName] = parseDocumentName(name)

  useEffect(() => {
    if (!issueTypes && !issueTypesErrors) {
      dispatch(episodeMetaActions.requestProperties())
    }
  }, [issueTypes, issueTypesErrors, dispatch])

  const [form] = Form.useForm()
  const memberIdFieldValue = Form.useWatch(['shared', 'memberId'], form)

  const member = patientResults?.find((p) => p.id === memberIdFieldValue)

  useResetFormOnUnprocess(status, form)

  const {
    handleFaxValueChange,
    handleFaxNameChange,
    handleUnassignMember,
    handleFaxAction,
    isTriageLoading,
    isProcessLoading,
    areFaxActionsDisabled,
  } = useModifyFax(document, form)

  const isReceived = status === IncomingFaxDocumentStatus.received
  const isTriaged = status === IncomingFaxDocumentStatus.triaged
  const isProcessed = status === IncomingFaxDocumentStatus.processed

  const initialValues = useMemo(
    () => ({
      shared: {
        memberId,
      },
      fax: {
        name: currentDocumentName,
        priority,
      },
      document: {
        documentDate: !!documentDate && moment(documentDate),
        type: docType,
        episodeId,
        createdFromNoteId,
      },
      owner: {
        assignee: assignedTo,
        teams: teamIds,
      },
    }),
    [
      assignedTo,
      createdFromNoteId,
      currentDocumentName,
      docType,
      documentDate,
      episodeId,
      memberId,
      priority,
      teamIds,
    ],
  )

  const radioOptions = [
    {
      label: t('faxes.priority.none'),
      value: IncomingFaxDocumentPriority.NONE,
    },
    {
      label: (
        <>
          <FlagOutlined /> {t('faxes.priority.flagged')}
        </>
      ),
      value: IncomingFaxDocumentPriority.FLAGGED,
      variant: RadioButonsVariants.Warning,
    },
    {
      label: (
        <>
          <WarningOutlined /> {t('faxes.priority.urgent')}
        </>
      ),
      value: IncomingFaxDocumentPriority.URGENT,
      variant: RadioButonsVariants.Danger,
    },
  ]

  const unassignMember = useCallback(() => {
    handleUnassignMember(documentId)
  }, [documentId, handleUnassignMember])

  const getActionText = useCallback(
    (status: IncomingFaxDocumentStatus): string => {
      switch (status) {
        case IncomingFaxDocumentStatus.received: {
          if (isTriaged) {
            return t('faxes.status.triaged')
          }

          if (isTriageLoading) {
            return t('faxes.loading.triaging')
          }

          return t('faxes.triage')
        }

        case IncomingFaxDocumentStatus.triaged: {
          if (isProcessed) {
            return t('faxes.status.processed')
          }

          if (isProcessLoading) {
            return t('faxes.loading.processing')
          }

          return t('faxes.process')
        }

        case IncomingFaxDocumentStatus.processed:
          return t('faxes.status.processed')
      }
    },
    [isProcessLoading, isProcessed, isTriageLoading, isTriaged, t],
  )

  return (
    <Form
      key={documentId}
      form={form}
      onValuesChange={handleFaxValueChange}
      onFinish={handleFaxAction}
      initialValues={initialValues}
      css={`
        min-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
      `}
    >
      <StatusTag
        css={`
          margin-bottom: 12px;
          height: 28px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
        `}
        status={status}
        color={STATUS_COLOR_MAP[status]}
      />
      <Form.Item name={['fax', 'name']} noStyle>
        <DocumentName
          formItemName={['fax', 'name']}
          onChangeName={handleFaxNameChange}
          form={form}
        />
      </Form.Item>
      <HeaderLine title={t('documents.sections.priority')} />
      <InputSection>
        <LineItem
          label={t('documents.sections.priority')}
          value={
            <Form.Item name={['fax', 'priority']} noStyle>
              <RadioButtonsGroup options={radioOptions} />
            </Form.Item>
          }
        />
        <LineItem
          label="Assignee"
          value={
            <OwnerField
              // null to hide it completely, instead of falling back the default
              label={null}
              disabled={isProcessed}
              css={`
                margin-bottom: 0;
              `}
            />
          }
        />

        {isReceived && <ReceivedPanel document={document} form={form} />}
        {isTriaged && (
          <TriagedPanel
            document={document}
            memberIdFieldValue={memberIdFieldValue}
            form={form}
            onUnassignMember={unassignMember}
          />
        )}
        {isProcessed && <ProcessedPanel document={document} form={form} />}
      </InputSection>

      <ActivityLog activities={document.activities} />

      {status !== IncomingFaxDocumentStatus.processed && (
        <FaxActionButtonContainer>
          <Popconfirm
            title={
              isReceived
                ? t('faxes.confirmTriage', {
                    member: formatUserFullName(
                      member?.first_name,
                      member?.last_name,
                      member?.preferred_name,
                    ),
                    dob: member?.date_of_birth,
                    id: member?.id,
                  })
                : t('faxes.confirmProcess')
            }
            overlayStyle={{ width: '236px' }}
            // eslint-disable-next-line react/jsx-no-bind
            onConfirm={() => form.submit()}
            disabled={areFaxActionsDisabled(status)}
            okButtonProps={{
              id: 'fax-action-btn-confirm',
              // @ts-expect-error Passing arbitrary HTML props is allowed
              'data-dd-privacy': 'allow',
              'data-dd-action-name': `${isReceived ? 'fax-triage-confirm' : 'fax-process-confirm'}`,
            }}
            cancelButtonProps={{
              // @ts-expect-error Passing arbitrary HTML props is allowed
              'data-dd-privacy': 'allow',
              'data-dd-action-name': `${isReceived ? 'fax-triage-cancel' : 'fax-process-cancel'}`,
            }}
          >
            <Button
              data-testid="fax-action-btn"
              type="primary"
              htmlType="submit"
              loading={isTriageLoading || isProcessLoading}
              css={`
                display: flex;
                justify-self: center;
                align-self: center;
              `}
              disabled={areFaxActionsDisabled(status)}
            >
              {getActionText(status)}
            </Button>
          </Popconfirm>
        </FaxActionButtonContainer>
      )}
    </Form>
  )
}

const FaxActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 62px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px #00000026;
  position: sticky;
  bottom: 0;
  margin: auto -16px 0;
  padding: 20px;
`
