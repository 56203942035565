export class VideoCallError extends Error {
  /**
   * Convert a OpenTok error object to a VideoCallError.
   */
  static fromOt({ message, name }: OT.OTError) {
    return new VideoCallError(message, name)
  }
  name = 'VideoCallError'
  message: string
  /**
   * The error name provided by OpenTok.
   */
  otName?: string

  constructor(message: string, otName?: string) {
    super(message)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, VideoCallError)
    }
    this.message = message
    this.otName = otName
  }
}

export interface VideoCallState {
  error?: VideoCallError
  publisherWarnings: VideoCallWarning[]
  subscriberWarnings: VideoCallWarning[]
  status?: VideoCallStatus
  sessionId?: string
  subscriberMuted: boolean
  publisherMuted: boolean
  initialized: boolean
  calling: boolean
}

export enum VideoCallActionTypes {
  START_CALL = '@@videoCall/START_CALL',
  CALL_ENDED = '@@videoCall/CALL_ENDED',
  HANG_UP = '@@videoCall/HANG_UP',

  SET_STATUS = '@@videoCall/SET_STATUS',
  SET_ERROR = '@@videoCall/SET_ERROR',

  ADD_PUBLISHER_WARNING = '@@videoCall/ADD_PUBLISHER_WARNING',
  REMOVE_PUBLISHER_WARNING = '@@videoCall/REMOVE_PUBLISHER_WARNING',

  ADD_SUBSCRIBER_WARNING = '@@videoCall/ADD_SUBSCRIBER_WARNING',
  REMOVE_SUBSCRIBER_WARNING = '@@videoCall/REMOVE_SUBSCRIBER_WARNING',

  SET_SUBSCRIBER_MUTED = '@@videoCall/SET_SUBSCRIBER_MUTED',
  SET_PUBLISHER_MUTED = '@@videoCall/SET_PUBLISHER_MUTED',

  SET_SESSION_ID = '@@videoCall/SET_SESSION_ID',

  INIT = '@@videoCall/INIT',
  CLEANUP = '@@videoCall/CLEANUP',
  READY_TO_CALL = '@videoCall/READY_TO_CALL',

  WINDOW_POP_OUT_STATUS_CHANGED = '@@videoCall/WINDOW_POP_OUT_STATUS_CHANGED',
}

export enum VideoCallWarning {
  NO_AUDIO_DETECTED = 'no-audio-detected',
}

/**
 * Status sequence
 *
 * 1. INITIALIZING_PUBLISHER
 * 2. READY_TO_CALL
 * 3. AUTHENTICATING
 * 4. INITIALIZING_SESSION
 * 5. SESSION_READY
 * 6. CALLING
 * 7. RINGING
 *
 * 8. Subscriber then gets asked for audio/video permissions
 *    (this does not block going to step 9. CONNECTING)
 *
 *    a. SUBSCRIBER_ASKED_FOR_[MEDIA_TYPE]_PERMISSION
 *
 *    b. SUBSCRIBER_ACCEPTED_[MEDIA_TYPE]_PERMISSION
 *
 * 9. CONNECTING
 * 10. CONNECTED
 * 11. CALL_ENDED
 *
 * Following calls start at step 2. READY_TO_CALL
 */
export enum VideoCallStatus {
  INITIALIZING_PUBLISHER = 'initializing publisher',
  READY_TO_CALL = 'ready to call',
  AUTHENTICATING = 'authenticating',
  INITIALIZING_SESSION = 'initializing session',
  SESSION_READY = 'session ready',
  CALLING = 'calling',
  RINGING = 'ringing',
  SUBSCRIBER_ASKED_FOR_AUDIO_PERMISSION = 'asking for audio permissions',
  SUBSCRIBER_ASKED_FOR_VIDEO_PERMISSION = 'asking for video permissions',
  SUBSCRIBER_ACCEPTED_AUDIO_PERMISSION = 'accepted audio permissions',
  SUBSCRIBER_ACCEPTED_VIDEO_PERMISSION = 'accepted video permissions',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  CALL_ENDED = 'call ended',
}
