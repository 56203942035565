import {
  FlagOutlined,
  PaperClipOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { baseColors } from '@dialogue/basics'
import {
  IncomingFaxDocumentPriority,
  IncomingFaxDocumentStatus,
  type IncomingFaxDocument,
  type OutgoingFaxDocument,
  type OutgoingFaxDocumentStatus,
} from '@dialogue/document-center'
import {
  Alert,
  Button,
  Form,
  Space,
  Tag,
  Typography,
  type TagProps,
} from 'antd'
import type { TitleProps } from 'antd/lib/typography/Title'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { styled } from 'styled-components'

import { getDocumentLinkParams } from 'app/containers/documents/document-actions/copy-link-button'
import { PanelTab } from 'app/containers/episode-member-panel/types'
import { ProfileTab } from 'app/containers/member-profile-page/types'
import { useStopPropagation } from 'app/hooks'
import { formatUserFullName } from 'app/lib/helpers'
import { useGetEpisodeQuery } from 'app/redux/api/emergency-room/episodes'
import { useGetPatientProfileQuery } from 'app/redux/api/emergency-room/patients'
import { DocumentKind, DocumentSource } from 'app/redux/documents/viewer'
import routes from 'app/services/routes'
import { colors } from 'app/theme'

// Can either be a AntD preset color name, or a HEX code
// 'default' === grey
// See more: https://4x.ant.design/components/tag/#components-tag-demo-colorful
export const STATUS_COLOR_MAP: Record<IncomingFaxDocumentStatus, string> = {
  [IncomingFaxDocumentStatus.received]: 'blue',
  [IncomingFaxDocumentStatus.triaged]: 'orange',
  [IncomingFaxDocumentStatus.processed]: 'green',
}

interface ErrorNoticeProps {
  onReload: () => void
}

interface StatusTagProps extends TagProps {
  status: OutgoingFaxDocumentStatus | IncomingFaxDocumentStatus
  color: string
}

interface DocumentNameAndPriorityProps {
  name: string
  fax: IncomingFaxDocument | OutgoingFaxDocument
  priority?: IncomingFaxDocumentPriority
}

export const PAGE_SIZE = 50

export const FaxesContainer = styled.div`
  margin: 18px 24px;
`

export const FaxesTitle = styled(Typography.Title).attrs<TitleProps>(() => ({
  level: 4,
  'data-dd-privacy': 'allow',
}))`
  && {
    margin-bottom: 1em;
  }
`

const StyledTag = styled(Tag)`
  width: 100%;
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;

  &:hover {
    color: ${colors.primary};
    border-color: ${colors.primary};
  }
`

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FullWidthFormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 0;
`

export const getRowKey = <
  TFaxType extends OutgoingFaxDocument | IncomingFaxDocument,
>(
  fax: TFaxType,
) => fax.id

export const ErrorNotice = ({ onReload }: ErrorNoticeProps) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')
  return (
    <Alert
      message={t('faxes.error.fetching')}
      showIcon
      type="error"
      data-dd-privacy="allow"
      action={
        <Button danger ghost onClick={onReload} size="small">
          {tCommon('button.reload')}
        </Button>
      }
    />
  )
}

export const StatusTag = ({ status, color, ...rest }: StatusTagProps) => {
  const { t } = useTranslation()

  return (
    <Tag color={color} {...rest}>
      {t(`faxes.status.${status}`)}
    </Tag>
  )
}

const PriorityIcon = ({
  priority,
}: {
  priority: IncomingFaxDocumentPriority
}) => {
  switch (priority) {
    case IncomingFaxDocumentPriority.URGENT:
      return (
        <Tag
          icon={<WarningOutlined />}
          color="error"
          data-dd-privacy="allow"
          css={`
            // Using Tag and Icon without text still provides an empty span,
            // which gives this extra unnecessary space next to the icon.
            // "unset" is used in padding-right to remove this extra space.
            padding-right: unset;
            border-color: ${colors.error};
          `}
        />
      )
    case IncomingFaxDocumentPriority.FLAGGED:
      return (
        <Tag
          icon={<FlagOutlined />}
          color="warning"
          data-dd-privacy="allow"
          css={`
            padding-right: unset;
            border-color: ${baseColors.sand};
          `}
        />
      )
    default:
      return null
  }
}

export const DocumentNameAndPriority = ({
  name,
  fax,
  priority,
}: DocumentNameAndPriorityProps) => {
  const { stopPropagation } = useStopPropagation()
  const { search } = useLocation()

  const { member_document } = fax
  if (!member_document) return name

  const { episode_id, id, member_id } = member_document

  const pathname = episode_id
    ? routes.channel(member_id, episode_id, PanelTab.DOCUMENTS)
    : routes.memberProfile(member_id, ProfileTab.Documents)

  const pathToDocument = `${pathname}&${getDocumentLinkParams(
    search,
    id,
    DocumentSource.DOCUMENT_CENTER,
    DocumentKind.MEMBER_DOCUMENT,
  )}`

  return (
    <Space
      direction="horizontal"
      css={`
        width: 100%;

        & > .ant-space-item:first-child {
          min-width: 0;
        }
      `}
    >
      <Link to={pathToDocument} onClick={stopPropagation} title={name}>
        <StyledTag icon={<PaperClipOutlined />}>{name}</StyledTag>
      </Link>
      {priority && <PriorityIcon priority={priority} />}
    </Space>
  )
}

export const MemberEpisodeInfo = ({
  memberId,
  episodeId,
}: {
  memberId: number
  episodeId: string | null
}) => {
  const { t } = useTranslation()

  const { data: episode } = useGetEpisodeQuery(
    { episodeId: episodeId || '' },
    { skip: !episodeId },
  )
  const { data: memberProfile } = useGetPatientProfileQuery(
    { patientId: memberId },
    { skip: !!episodeId },
  )

  const { stopPropagation } = useStopPropagation()

  const firstName = episode
    ? episode.patient_first_name
    : memberProfile?.given_name
  const lastName = episode
    ? episode.patient_last_name
    : memberProfile?.family_name
  const preferredName = episode
    ? episode.patient_preferred_name
    : memberProfile?.preferred_name
  const formattedMemberName = formatUserFullName(
    firstName,
    lastName,
    preferredName,
  )
  const episodeTitle =
    episode?.title ||
    episode?.health_issue_type_name ||
    t('episodes.missingTitle')

  return (
    <VerticalContainer
      css={`
        align-items: flex-start;
      `}
    >
      {episodeId && (
        <>
          <Typography.Text>{formattedMemberName}</Typography.Text>
          <Link
            to={routes.channel(memberId, episodeId)}
            onClick={stopPropagation}
          >
            {episodeTitle}
          </Link>
        </>
      )}
      {!episodeId && memberProfile && (
        <Link to={routes.memberProfile(memberId)} onClick={stopPropagation}>
          {formattedMemberName}
        </Link>
      )}
    </VerticalContainer>
  )
}
