import {
  useCallback,
  useMemo,
  type ComponentProps,
  type ReactNode,
} from 'react'

import { Alert, Select, Tag, Typography } from 'antd'
import type { DefaultOptionType } from 'antd/lib/select'
import castArray from 'lodash/castArray'
import isNil from 'lodash/isNil'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { PractitionerOptionLabel } from 'app/components/practitionner-picker/practitioner-option-label'
import { normalizeString } from 'app/lib/helpers'
import type { Practitioner } from 'app/redux/practitioners'

const StyledTag = styled(Tag)`
  display: flex;
  margin: 2px;
  height: 28px;
  line-height: 24px;
`

const StyledSelect = styled(Select<Owner, Option>)`
  line-height: 32px;
  width: 100%;
`

type OwnerSelectProps = ComponentProps<typeof StyledSelect>
type OnSelect = NonNullable<OwnerSelectProps['onSelect']>
type OnDeselect = NonNullable<OwnerSelectProps['onDeselect']>
type FilterOption = NonNullable<OwnerSelectProps['filterOption']>

enum OwnerType {
  TEAM = 'team',
  ASSIGNEE = 'assignee',
}

interface BaseOption extends DefaultOptionType {
  tag?: ReactNode | string
  searchString: string
}

interface AssigneeOption extends BaseOption {
  type: OwnerType.ASSIGNEE
  baseValue: number
}

interface TeamOption extends BaseOption {
  type: OwnerType.TEAM
  baseValue: number
}

type Option = AssigneeOption | TeamOption

export interface Owner {
  teams?: number[] | null
  assignee?: number | number[] | null
}

interface OwnerOptionsProps {
  teams?: { value: number; label: string }[]
  practitioners?: Practitioner[]
  isTeamsError?: boolean
  isPractitionersError?: boolean
  allowMultipleAssignees?: boolean
}

const OptionGroupLabel = ({
  label,
  hasError,
  errorText,
}: {
  label: ReactNode
  errorText: string
  hasError?: boolean
}) => {
  return (
    <>
      {label}
      {hasError && <Alert type={'error'} showIcon message={errorText} />}
    </>
  )
}

const filterValue: FilterOption = (inputValue, option) => {
  const searchString = option?.searchString || ''

  const tokenizedInput = normalizeString(inputValue).split(' ')
  const tokenizedSearchString = normalizeString(searchString).split(' ')

  return tokenizedInput.every((token) =>
    tokenizedSearchString.some((searchToken) => searchToken.startsWith(token)),
  )
}

const useOwnerOptions = ({
  teams = [],
  practitioners = [],
  isTeamsError,
  isPractitionersError,
  allowMultipleAssignees,
}: OwnerOptionsProps) => {
  const { t } = useTranslation()

  const teamOptions = useMemo<TeamOption[]>(
    () =>
      teams.map((team) => ({
        label: (
          <Typography.Text data-testid="team-option" data-dd-privacy="allow">
            {team.label}
          </Typography.Text>
        ),
        // team is added to the value to prevent id collisions
        value: `${OwnerType.TEAM}-${team.value}`,
        // 'baseValue' enables easy parsing of the value without reverting the above string manipulation
        baseValue: team.value,
        type: OwnerType.TEAM,
        searchString: team.label,
      })),
    [teams],
  )

  const practitionerOptions = useMemo<AssigneeOption[]>(
    () =>
      practitioners.map(({ id, first_name, last_name, email, picture }) => ({
        label: (
          <PractitionerOptionLabel
            firstName={first_name}
            lastName={last_name}
            email={email}
            picture={picture}
            showEmail
          />
        ),
        // assignee is added to 'value' to prevent id collisions
        value: `${OwnerType.ASSIGNEE}-${id}`,
        // 'baseValue' enables easy parsing of the value without reverting the above string manipulation
        baseValue: Number(id),
        type: OwnerType.ASSIGNEE,
        searchString: `${first_name} ${last_name} ${id}`,
      })),
    [practitioners],
  )

  return [
    {
      label: (
        <OptionGroupLabel
          label={
            <span data-dd-privacy="allow">
              {t('tasks.properties.labels.team')}
            </span>
          }
          errorText={t('tasks.error.fetchingTeams')}
          hasError={isTeamsError}
        />
      ),
      options: teamOptions,
    },
    {
      label: (
        <OptionGroupLabel
          label={
            <span data-dd-privacy="allow">
              {t('tasks.properties.labels.individual')}
              {!allowMultipleAssignees &&
                ` ${t('tasks.properties.labels.assigneeSelectionLimit')}`}
            </span>
          }
          errorText={t('tasks.error.fetchingProviders')}
          hasError={isPractitionersError}
        />
      ),
      options: practitionerOptions || [],
    },
  ]
}

// Props exposed to usage
export interface TaskOwnerSelectProps
  extends Pick<OwnerSelectProps, 'onSelect' | 'onDeselect' | 'value'> {
  onChange?: (value: Owner) => void
  teams?: { value: number; label: string }[]
  practitioners?: Practitioner[]
  loading?: boolean
  isTeamsError?: boolean
  isPractitionersError?: boolean
  allowMultipleAssignees?: boolean
  disabled?: boolean
}

// Inner select props
interface BaseSelectProps
  extends Omit<TaskOwnerSelectProps, 'value' | 'onChange'> {
  value: string[]
}

const BaseSelect = ({
  teams,
  practitioners,
  value,
  onSelect,
  onDeselect,
  allowMultipleAssignees = false,
  isTeamsError,
  isPractitionersError,
  loading,
  ...rest
}: BaseSelectProps) => {
  const { t } = useTranslation()

  const options = useOwnerOptions({
    teams,
    practitioners,
    isTeamsError,
    isPractitionersError,
    allowMultipleAssignees,
  })

  const renderTag = useCallback(
    ({ label, ...rest }: { label: ReactNode | string }) => (
      <StyledTag data-testid="task-owner-tag" {...rest}>
        {label}
      </StyledTag>
    ),
    [],
  )
  return (
    <StyledSelect
      options={options as any}
      value={value as any}
      placeholder={t('tasks.properties.placeholders.assignee')}
      tagRender={renderTag}
      loading={loading}
      onSelect={onSelect}
      onDeselect={onDeselect}
      filterOption={filterValue}
      {...rest}
      // mode is multiple even for the one-of-each option as
      // the user can select up to two options.
      mode="multiple"
    />
  )
}

const ensureArray = (value: any): number[] =>
  castArray(value).filter((v) => !isNil(v))

export const TaskOwnerSelect = ({
  value,
  onChange = () => {},
  allowMultipleAssignees = false,
  ...rest
}: TaskOwnerSelectProps) => {
  const formattedValue = useMemo(() => {
    if (!value) return []

    const { teams, assignee } = value

    if (isNil(teams) && isNil(assignee)) return []

    let teamValue: string[] = []
    let assigneeValue: string[] = []

    if (!isNil(teams)) {
      teamValue = ensureArray(teams).map(
        (teamItem) => `${OwnerType.TEAM}-${teamItem}`,
      )
    }
    if (!isNil(assignee)) {
      assigneeValue = ensureArray(assignee).map(
        (assigneeItem) => `${OwnerType.ASSIGNEE}-${assigneeItem}`,
      )
    }

    return [...teamValue, ...assigneeValue]
  }, [value])

  const handleSelect = useCallback<OnSelect>(
    (_, selectedOption) => {
      const { type, baseValue } = selectedOption

      if (type === 'team') {
        onChange({
          teams: [...ensureArray(value?.teams), baseValue],
          assignee: value?.assignee,
        })
      }

      if (type === 'assignee') {
        let assigneeValue: number | number[] = baseValue

        if (allowMultipleAssignees) {
          const currentValue = ensureArray(value?.assignee)
          assigneeValue = [...currentValue, baseValue]
        }

        onChange({
          assignee: assigneeValue,
          teams: ensureArray(value?.teams),
        })
      }
    },
    [onChange, value, allowMultipleAssignees],
  )

  const handleDeselect = useCallback<OnDeselect>(
    (_, deselectedOption) => {
      const { type, baseValue } = deselectedOption

      if (type === 'team') {
        onChange({
          teams: value?.teams?.filter((v) => v !== baseValue),
          assignee: value?.assignee,
        })
      }
      if (type === 'assignee') {
        onChange({
          assignee:
            allowMultipleAssignees && Array.isArray(value?.assignee)
              ? value?.assignee?.filter((v) => v !== baseValue)
              : null,
          teams: value?.teams,
        })
      }
    },
    [onChange, value?.assignee, value?.teams, allowMultipleAssignees],
  )

  return (
    <BaseSelect
      value={formattedValue}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      allowMultipleAssignees={allowMultipleAssignees}
      {...rest}
    />
  )
}
