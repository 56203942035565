import type {
  Language,
  Location,
  PacLocation,
  PracticeGroup,
} from '@dialogue/timekeeper'
import { all, call, put, takeLatest } from 'typed-redux-saga/macro'

import { filterGroupScheduleActions } from 'app/redux/timekeeper/schedule/filter-group-schedule'

import { getProvidersApi } from '../utils'

export function* loadFilterGroupSchedule({
  payload: { filters, range },
}: ReturnType<typeof filterGroupScheduleActions.get>) {
  try {
    const api = yield* call(getProvidersApi)

    const response = yield* call(
      api.getSchedule,
      range.start.toISOString(),
      range.end.toISOString(),
      // TK API is quite strict but f-end shouldn't be
      filters.languages as Language[],
      filters.locations as Location[],
      filters.practices as PracticeGroup[],
      filters.pacLocations as PacLocation[],
    )
    const schedules = response.data.data

    yield* put(
      filterGroupScheduleActions.received({ filters, range, schedules }),
    )
  } catch (e) {
    yield* put(
      filterGroupScheduleActions.erroredFetching({ filters, range, error: e }),
    )
  }
}

export default function* filterGroupScheduleSagas() {
  yield* all([
    takeLatest(filterGroupScheduleActions.get, loadFilterGroupSchedule),
  ])
}
