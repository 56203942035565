import type { ReactNode } from 'react'

import { Col, Divider, Row, Space, Typography, type RowProps } from 'antd'
import { styled } from 'styled-components'

interface MetadataField {
  label: string
  value?: string
}

export interface OutgoingFaxMetadata {
  fax_package_properties: MetadataField[]
  document_properties: MetadataField[]
}

export const LineItem = ({
  label,
  value,
}: {
  label: string
  value: ReactNode
}) => (
  <Space
    direction="vertical"
    size={4}
    css={`
      display: flex;
      flex-direction: column;
    `}
  >
    <Typography.Text type="secondary">{label}</Typography.Text>
    <Typography.Text>{value}</Typography.Text>
  </Space>
)

export const DetailLine = ({
  label,
  value,
  alignLabel = 'top',
}: {
  label: string
  value: ReactNode
  alignLabel?: RowProps['align']
}) => (
  <Row align={alignLabel} gutter={8}>
    <Col span={6}>
      <Typography.Text
        type="secondary"
        css={`
          height: 100%;
        `}
      >
        {label}
      </Typography.Text>
    </Col>
    <Col span={18}>{value}</Col>
  </Row>
)

export const HeaderLine = ({
  title,
  extra,
}: {
  title: string
  extra?: ReactNode
}) => (
  <>
    <Divider
      css={`
        margin: 12px 0;
      `}
    />
    <div
      css={`
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      `}
    >
      <Typography.Text
        strong
        data-dd-privacy="allow"
        css={`
          margin-bottom: 5px;
        `}
      >
        {title}
      </Typography.Text>
      {extra}
    </div>
  </>
)

export const ActivitiesContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;
  padding-top: 4px;

  .ant-timeline-item {
    padding-bottom: 12px;
  }
`

export const PanelContent = styled(Space)`
  min-width: 160px;
  width: 100%;
`

export const FormContainer = styled(Space)`
  display: flex;
  /* Ensure the children do not overflow, to avoid horizontal scrolling. */
  & > * {
    min-width: 0;
    overflow: hidden;
  }
  /* END */
  & > *:first-child {
    flex: 1;
  }
`
