import { InfoCircleOutlined } from '@ant-design/icons'
import { Space, Tooltip, Typography } from 'antd'
import { styled } from 'styled-components'

import { colors } from 'app/theme'

export const ActivityToolTip = ({ data = [] }: { data: Array<string> }) => {
  return (
    <Tooltip
      title={
        <Space direction="vertical" size={0}>
          {data.map((item) => (
            <Typography.Text key={item}>{item}</Typography.Text>
          ))}
        </Space>
      }
      color={'white'}
      placement="right"
      arrowPointAtCenter
    >
      <ActivityToolTipButton data-testid="activity-tooltip" />
    </Tooltip>
  )
}

const ActivityToolTipButton = styled(InfoCircleOutlined)`
  color: ${colors.primary};
  font-size: 12px;
  margin-left: 4px;
`
