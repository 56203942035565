import { createApi } from '@reduxjs/toolkit/query/react'

import { API_NAMES, getDynamicBaseUrl } from './utils'

export enum Tags {
  EligibilityRecords = 'EligibilityRecords',
}

// TODO: Update once Scribe generates a TS client.
export interface EligibilityRecord {
  id: number
  attributes: {
    unique_identifier: string
    first_name: string
    last_name: string
    date_of_birth: string
    communication_email: string
    province: string
    department: string
    postal_code: string
  }
  attribute_schema: {
    communication_email: string
    signup_identifier: string
  }
  status: string
  organization_id: number
  eligible_intervals: Array<{
    plan_id: number
    is_active: boolean
    start_date: string
    end_date: string
  }>
  eligibility_tags: EligibilityTag[]
  organization: {
    id: number
    brand_id: string
    name: string
    attribute_schema: {
      communication_email: string
      signup_identifier: string
      date_of_birth_type: string
    }
  }
  participant?: {
    id: number
    created: string
    auth_id: string
    email: string
    first_name: string
    last_name: string
    preferred_name: string
    date_of_birth: string
    phone_number: string
    sex: string
    gender_identity: string
    gender_identity_description: string
    admin_area: string
    language: string
    avatar_url: string
  }
  participant_id_for_legacy_systems?: number
}

export interface PageEligibilityRecord {
  data: EligibilityRecord[]
  meta: {
    total_items: number
    offset: number
  }
}

export interface EligibilityTag {
  id: number
  display_name: string
  short_name: string
  color: string
  admin_description: string
  provider_description_en: string
  provider_description_fr: string
}

interface ListEligibilityRecordsParams {
  organization_id?: number[]
  participant_id?: number[]
  plan_id?: number[]
  attributes?: JSON
  status?: 'active' | 'inactive'
  eligible_on_or_after?: string
  not_eligible_on_or_after?: string
  search?: string
  order?: 'asc' | 'desc'
  offset?: number
  limit?: number
}

export const scribeApi = createApi({
  tagTypes: Object.values(Tags),
  reducerPath: 'scribeApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.SCRIBE),
  endpoints: (builder) => ({
    listEligibilityRecords: builder.query<
      EligibilityRecord[],
      ListEligibilityRecordsParams
    >({
      query: (params) => ({
        url: '/v2/eligibility_records',
        params,
      }),
      transformResponse: (response: PageEligibilityRecord) => response.data,
    }),
  }),
})

export const { useListEligibilityRecordsQuery } = scribeApi
