import { DatePicker, Form, Select, Typography } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { filterSelectOptions, formatPhoneNumber } from 'app/lib/helpers'
import type { ViewerIncomingFaxDocument } from 'app/redux/documents/viewer'

import { HeaderLine, LineItem } from '../common'
import {
  dateFormat,
  disableFutureDates,
  documentTypeOptions,
  parseDocumentName,
} from '../helpers'

export const DocumentProperties = ({
  document,
  form,
  readonly = false,
}: {
  document: ViewerIncomingFaxDocument
  form: FormInstance
  readonly?: boolean
}) => {
  const { t } = useTranslation()
  const [, documentExtension] = parseDocumentName(document.name)

  const docTypeValue: string = Form.useWatch(['document', 'type'], form)
  const displayedDocType = capitalize(docTypeValue?.replace(/_/g, ' '))
  const documentDate = Form.useWatch(['document', 'documentDate'], form)

  return (
    <>
      <HeaderLine title={t('documents.sections.properties')} />
      <div
        css={`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <LineItem
          label={t('documents.type')}
          value={
            <Form.Item noStyle name={['document', 'type']}>
              {readonly ? (
                <Typography>{displayedDocType}</Typography>
              ) : (
                <Select
                  options={documentTypeOptions()}
                  dropdownMatchSelectWidth={false}
                  placeholder={t('documents.typePlaceholder')}
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                  filterOption={filterSelectOptions}
                  data-testid="document-type-select"
                />
              )}
            </Form.Item>
          }
        />
        <LineItem
          label={t('documents.documentDate')}
          value={
            <Form.Item noStyle name={['document', 'documentDate']}>
              {readonly ? (
                <Typography>
                  {documentDate
                    ? moment(documentDate).format('YYYY-MM-DD')
                    : t('faxes.noDocumentDateSet')}
                </Typography>
              ) : (
                <DatePicker
                  format={dateFormat}
                  placeholder={dateFormat}
                  disabledDate={disableFutureDates}
                  allowClear
                  style={{ width: '100%' }}
                  data-testid="document-date-picker"
                />
              )}
            </Form.Item>
          }
        />
        <LineItem
          label={t('documents.format')}
          value={
            <Typography.Text>
              {t(`documents.formatWithExtension`, {
                format: document.format,
                ext: documentExtension,
              })}
            </Typography.Text>
          }
        />
        <LineItem
          label={t('documents.source')}
          value={t('faxes.faxSource', {
            faxNumber: formatPhoneNumber(document.sender_number),
          })}
        />
      </div>
    </>
  )
}
