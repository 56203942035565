// @ts-strict-ignore
import { useCallback, useEffect, useMemo } from 'react'

import type { MemberDocumentType } from '@dialogue/document-center'
import { Form, Select, Tooltip, Typography } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { DatePicker } from 'app/components/ant-design'
import {
  ConsultNoteEntityLink,
  EpisodeEntityLink,
} from 'app/components/entity-links'
import { EpisodePicker } from 'app/components/episode-picker'
import { PermissionsGuard } from 'app/components/permissions-guard'
import TasksView from 'app/components/tasks/tasks-view'
import { useCanEditDocument } from 'app/containers/documents/hooks/access-control'
import { NoteDisplay } from 'app/containers/note-display'
import TasksCreateNew from 'app/containers/tasks/create-new'
import { useAppDispatch, useAppSelector, useVariation } from 'app/hooks'
import { filterSelectOptions } from 'app/lib/helpers'
import { memberDocumentsActions } from 'app/redux/documents/members'
import type { ViewerMemberDocument } from 'app/redux/documents/viewer'
import * as episodeMetaActions from 'app/redux/episode-meta/actions'
import {
  selectIssueTypes,
  selectPropertiesErrors,
} from 'app/redux/episode-meta/selectors'
import { selectPatientEpisodesData } from 'app/redux/patients/selectors'
import { ER_LIST_TASKS } from 'app/scopes'
import { Flags } from 'app/services/feature-flags'

import { ActivityLog } from './activity-log'
import { DetailLine, FormContainer, HeaderLine, PanelContent } from './common'
import { DocumentName } from './document-name'
import {
  convertUndefinedToNull,
  dateFormat,
  disableFutureDates,
  documentTypeOptions,
  mergeDocumentActivity,
  parseDocumentName,
} from './helpers'

interface Props {
  document: ViewerMemberDocument
  onClose: () => void
}

export const MemberPanel = ({ document, onClose }: Props) => {
  const {
    id: documentId,
    name,
    format,
    source,
    type,
    document_date: documentDate,
    member_id: memberId,
    episode_id: episodeId,
    created_from_note_id: createdFromNoteId,
    post_id: postId,
    write_access: writeAccess,
    created_by_provider: createdByProvider,
  } = document
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')
  const dispatch = useAppDispatch()

  const enableTaskSystem = useVariation(Flags.enableTaskSystem, true)
  const taskViewIsFlatList = useVariation(Flags.taskAttachmentsV2, false)

  const hasLinkedEpisode = memberId && episodeId
  /*
    postId means the document originated from a chat message
    createdFromNoteId means the document originated from a note

    Both resources are permanently linked to an episode
    and therefore the document is as well
  */
  const hasPermanentEpisodeLink = !!postId || !!createdFromNoteId
  const hasLinkedNote = memberId && episodeId && createdFromNoteId

  const episodesData = useAppSelector((state) =>
    selectPatientEpisodesData(state, memberId),
  )

  const issueTypes = useAppSelector(selectIssueTypes)
  const issueTypesErrors = useAppSelector(selectPropertiesErrors)
  const issueTypeName = issueTypes?.find(
    (issueType) =>
      issueType.id === episodesData?.[episodeId]?.health_issue_type_id,
  )?.name

  const { isEditable } = useCanEditDocument(writeAccess, createdByProvider)

  const [currentDocumentName, documentExtension] = parseDocumentName(name)

  const episodeTooltipText = useMemo(() => {
    if (postId) return t('documents.details.episodeDisabledByPostId')
    if (createdFromNoteId) return t('documents.details.episodeDisabledByNoteId')
    return ''
  }, [postId, t, createdFromNoteId])

  useEffect(() => {
    if (!issueTypes && !issueTypesErrors) {
      dispatch(episodeMetaActions.requestProperties())
    }
  }, [issueTypes, issueTypesErrors, dispatch])

  const [form] = Form.useForm()

  const handleOnInfoChange = useCallback(
    (changedValues: {
      episode_id?: string
      created_from_note_id?: number
      document_name?: string
    }) => {
      if (changedValues.document_name) {
        // document name changes are handled by a dedicated handler
        return
      }

      // Converts any changed form values that are undefined (ie, cleared) to null so
      // that the API clears the field.
      const documentProperties = convertUndefinedToNull(changedValues)

      if (
        Object.keys(changedValues).includes('episode_id') &&
        changedValues.episode_id === undefined
      ) {
        // Clear created_from_note_id if episode_id is cleared
        documentProperties.created_from_note_id = null
      }

      dispatch(
        memberDocumentsActions.updateDocument({
          memberId,
          documentId,
          documentProperties,
        }),
      )
    },
    [memberId, documentId, dispatch],
  )

  const handleNameChange = useCallback(
    (newDocumentName: string) => {
      if (newDocumentName !== currentDocumentName) {
        dispatch(
          memberDocumentsActions.updateDocument({
            memberId,
            documentId,
            documentProperties: {
              name: documentExtension
                ? `${newDocumentName}.${documentExtension}`
                : newDocumentName,
            },
          }),
        )
      }
    },
    [currentDocumentName, dispatch, documentExtension, documentId, memberId],
  )

  return (
    <Form
      key={documentId}
      form={form}
      onValuesChange={handleOnInfoChange}
      initialValues={{ document_name: currentDocumentName }}
      disabled={!isEditable}
    >
      <PanelContent size={4} direction="vertical">
        <Form.Item name="document_name" noStyle>
          <DocumentName
            formItemName="document_name"
            onChangeName={handleNameChange}
            form={form}
          />
        </Form.Item>
        <HeaderLine title={t('documents.sections.properties')} />
        {format && (
          <DetailLine
            label={t('documents.format')}
            value={
              <Typography.Text>
                {t(`documents.formatWithExtension`, {
                  format,
                  ext: documentExtension,
                })}
              </Typography.Text>
            }
          />
        )}
        <DetailLine
          label={t('documents.type')}
          value={
            <Form.Item noStyle name="type">
              <Select
                options={documentTypeOptions()}
                dropdownMatchSelectWidth={false}
                defaultValue={type as MemberDocumentType}
                placeholder={t('documents.typePlaceholder')}
                style={{ width: '100%' }}
                showSearch
                allowClear
                filterOption={filterSelectOptions}
                data-testid="document-type-select"
              />
            </Form.Item>
          }
        />
        <DetailLine
          label={t('documents.documentDate')}
          alignLabel={'middle'}
          value={
            <Form.Item noStyle name="document_date">
              <DatePicker
                format={dateFormat}
                placeholder={dateFormat}
                defaultValue={!!documentDate && moment(documentDate)}
                disabledDate={disableFutureDates}
                allowClear
                style={{ width: '100%' }}
                data-testid="document-date-picker"
              />
            </Form.Item>
          }
        />
        <DetailLine
          label={t('documents.source')}
          value={
            <Typography.Text>
              {source || tCommon('notAvailable')}
            </Typography.Text>
          }
        />
        <HeaderLine title={t('documents.sections.episode')} />
        <DetailLine
          label={t('documents.details.episode')}
          alignLabel="middle"
          value={
            <FormContainer>
              <Tooltip title={episodeTooltipText}>
                {/* See https://github.com/ant-design/ant-design/issues/30191 */}
                <div>
                  <Form.Item noStyle name="episode_id">
                    <EpisodePicker
                      memberId={memberId}
                      defaultId={episodeId}
                      fallbackTitle={t('episodes.missingTitle')}
                      placeholder={t('documents.details.episodePlaceholder')}
                      disabled={hasPermanentEpisodeLink || !isEditable}
                    />
                  </Form.Item>
                </div>
              </Tooltip>

              {hasLinkedEpisode && (
                <EpisodeEntityLink
                  ddActionName="member-document:goto:episode"
                  episodeId={episodeId}
                  episodeOwnerId={memberId}
                  onClick={onClose}
                  postId={postId}
                  showText={false}
                />
              )}
            </FormContainer>
          }
        />
        {issueTypeName && (
          <DetailLine
            label={t('documents.issueType')}
            value={<Typography.Text>{issueTypeName}</Typography.Text>}
          />
        )}
        <DetailLine
          label={t('documents.details.note')}
          alignLabel="middle"
          value={
            <FormContainer>
              <Form.Item noStyle name="created_from_note_id">
                <NoteDisplay memberId={memberId} noteId={createdFromNoteId} />
              </Form.Item>

              {hasLinkedNote && (
                <ConsultNoteEntityLink
                  ddActionName="member-document:goto:consult-note"
                  episodeId={episodeId}
                  episodeOwnerId={memberId}
                  noteId={createdFromNoteId}
                  onClick={onClose}
                  showText={false}
                />
              )}
            </FormContainer>
          }
        />
        <PermissionsGuard permissions={[ER_LIST_TASKS]}>
          {enableTaskSystem && (
            <>
              <HeaderLine
                title={t('documents.sections.tasks')}
                extra={
                  <TasksCreateNew
                    memberId={memberId}
                    episodeId={episodeId}
                    documentIds={[documentId]}
                  />
                }
              />
              <TasksView
                documentId={documentId}
                // 'null' is a special filter that excludes tasks with a parent
                // If we don't want to filter by parent, we should pass null
                parentId={taskViewIsFlatList ? null : 'null'}
                noTaskChildren={taskViewIsFlatList}
                noTasksLabel={t('tasks.document.noTasksForDocument')}
                noTasksDueLabel={t('tasks.document.noTasksDueForDocument')}
                data-cy="document-view-tasks"
              />
            </>
          )}
        </PermissionsGuard>
        <ActivityLog activities={mergeDocumentActivity(document)} />
      </PanelContent>
    </Form>
  )
}
