import { Space, Typography } from 'antd'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { CopyButton } from '../copy-button/copy-button'

import { ActivityToolTip } from './activity-tooltip'
import type { ActivityMetadataField } from './types'

export const ActivityTimelineBlock = ({
  activityType,
  timestamp,
  tooltipMessages,
  personName,
  resourceTitle,
  metadataFields,
}: {
  activityType: string
  timestamp: string
  tooltipMessages: string[]
  personName?: string
  resourceTitle?: string
  metadataFields?: ActivityMetadataField[]
}) => {
  const { t } = useTranslation()

  return (
    <Space direction="vertical" size={0}>
      <Typography.Text strong>
        {personName
          ? `${activityType} ${t('documents.activity.by')} ${personName}`
          : activityType}
        {tooltipMessages.length > 0 && (
          <ActivityToolTip data={tooltipMessages} />
        )}
      </Typography.Text>
      {resourceTitle && <Typography.Text>{resourceTitle}</Typography.Text>}
      {metadataFields?.map(({ value, copyable }, i) => (
        <Space key={`${value}-${i}`}>
          <Typography.Text type="secondary">{value}</Typography.Text>
          {copyable && <CopyButton value={value} />}
        </Space>
      ))}
      <Typography.Text type="secondary">
        {format(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss')}
      </Typography.Text>
    </Space>
  )
}
