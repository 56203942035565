// @ts-strict-ignore
import type { EnrichedEpisode } from '@dialogue/coredata'
import { createSelector } from '@reduxjs/toolkit'

import { formatUserDisplayName, formatUserFullName } from 'app/lib/helpers'
import type { ReduxState } from 'app/redux'

export const selectPatientsProfiles = (state: ReduxState) =>
  state.patients.profiles

export const selectPatientProfile = (
  state: ReduxState,
  patientId: number | string,
) => {
  return state.patients.profiles[patientId]
}

export const selectPatientProfileLoaded = (
  state: ReduxState,
  patientId: number | string,
) =>
  state.patients.profiles[patientId] &&
  // no real better way to do check with current structure
  // we'll be able to get rid of this when we move to RTKq thankfully
  state.patients.profiles[patientId].given_name !== undefined

export const selectPatientAuthId = (
  state: ReduxState,
  patientId: number | string,
) => selectPatientProfile(state, patientId)?.user_id

export const selectPatientFamily = (
  state: ReduxState,
  patientId: number | string,
) => selectPatientProfile(state, patientId)?.family

export const selectFamilyMemberType = createSelector(
  selectPatientProfile,
  selectPatientFamily,
  (profile, family) => {
    const familyMember = family?.find(
      (member) => profile?.id.toString() === member.id.toString(),
    )
    return familyMember?.type
  },
)

export const selectPatientDisplayName = (
  state: ReduxState,
  patientId: number | string,
) => {
  const profile = selectPatientProfile(state, patientId)

  if (!profile) return null

  const { given_name = '', family_name = '', preferred_name = '' } = profile

  return formatUserDisplayName(given_name, family_name, preferred_name)
}

export const selectPatientLongDisplayName = (
  state: ReduxState,
  patientId: number | string,
) => {
  const profile = selectPatientProfile(state, patientId)

  if (!profile) return null

  const { given_name, family_name, preferred_name } = profile

  return formatUserFullName(given_name, family_name, preferred_name)
}

export const selectPatientEpisodes = createSelector(
  (state: ReduxState, patientId: number) =>
    state.patients.episodes[patientId]?.order,
  (state: ReduxState, patientId: number) =>
    state.patients.episodes[patientId]?.data,
  (order, data) => {
    return order?.map((episodeId) => data[episodeId])
  },
)

export const selectPatientEpisodesData = (
  state: ReduxState,
  patientId: number,
) => {
  return state.patients.episodes[patientId]?.data
}

export const selectMostRecentEpisodeIds = (
  state: ReduxState,
  patientId: number,
) => state.patients.episodes[patientId]?.mostRecentEpisodeIds

export const selectPatientEpisodesByIds = createSelector(
  (state: ReduxState, patientId: number) =>
    state.patients.episodes[patientId]?.data,
  (_state: ReduxState, _patientId: number, episodeIds: string[]) => episodeIds,
  (episodes, episodeIds) => {
    if (!episodeIds || !episodes) {
      return {}
    }
    const matchingEpisodes = Object.keys(episodes)
      .filter((id) => episodeIds.includes(id))
      .reduce((mapping: Record<EnrichedEpisode['id'], EnrichedEpisode>, id) => {
        mapping[id] = episodes[id]
        return mapping
      }, {})
    return matchingEpisodes
  },
)

export const selectPatientEpisodeById = (
  state: ReduxState,
  patiendId: number | null,
  episodeId?: string | null,
) => state.patients.episodes[patiendId]?.data[episodeId]

export const selectPatientEpisodesCount = (
  state: ReduxState,
  patientId: number,
) => state.patients.episodes[patientId]?.totalItems

export const selectPatientActiveEpisodesCount = (
  state: ReduxState,
  patientId: number,
) => state.patients.episodes[patientId]?.activeEpisodesCount

export const selectActiveFamilyMemberId = (
  state: ReduxState,
  patientId: number | string,
): number => {
  return Number(
    state.patients.profiles[patientId]?.activeFamilyMemberId || patientId,
  )
}

export const selectEligibleServices = (
  state: ReduxState,
  patientId: string | number,
) => selectPatientProfile(state, patientId)?.eligible_services

export const selectPatientLocation = (
  state: ReduxState,
  patientId: string | number,
) => state.patients.locations[patientId]

export const selectPatientFirstName = (
  state: ReduxState,
  patientId: number | string,
) => {
  const profile = selectPatientProfile(state, patientId)

  return profile?.preferred_name || profile?.given_name
}

export const selectActiveFamilyMemberProfile = (
  state: ReduxState,
  patientId: number | string,
) => {
  const activeFamilyMemberId = selectActiveFamilyMemberId(state, patientId)
  return selectPatientProfile(state, activeFamilyMemberId)
}

export const selectPatientPreferredLanguage = createSelector(
  selectPatientProfile,
  selectActiveFamilyMemberProfile,
  (patientProfile, activeFamilyMemberProfile) =>
    patientProfile?.preferred_language ||
    activeFamilyMemberProfile?.preferred_language,
)

export const selectFetchingMostRecentEpisodes = (
  state: ReduxState,
  patientId: number,
) => state.patients.episodesStatuses[patientId]?.fetchingMostRecentEpisodes

export const selectErrorFetchingMostRecentEpisodes = (
  state: ReduxState,
  patientId: number,
) => state.patients.episodesStatuses[patientId]?.errorFetchingMostRecentEpisodes
