import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { TaskPriority } from '@dialogue/coredata'
import { Alert, Form, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import type { Owner } from 'app/components/tasks/task-owner-select'
import { TaskPriorityInput } from 'app/components/tasks/task-priority-input'
import { OwnerField } from 'app/containers/tasks/owner-field'
import { TaskDescripitionInput } from 'app/containers/tasks/task-description-input'
import { usePersistedValue } from 'app/hooks'
export interface NextSteps {
  priority?: TaskPriority
  description?: string
  owner?: Owner
}

export interface NextStepsRefType {
  validateNextSteps?: () => Promise<NextSteps>
}

export const NextStepsForm = forwardRef(
  (
    {
      isLoading = false,
      initialValues,
      onChange = () => {},
      disabled,
      taskId,
    }: {
      isLoading?: boolean
      initialValues: NextSteps
      onChange: (newValues: NextSteps) => void
      disabled?: boolean
      taskId: string
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()

    useImperativeHandle(
      ref,
      () => ({
        validateNextSteps: form.validateFields,
      }),
      [form.validateFields],
    )

    useEffect(() => {
      if (isLoading) return
      form.resetFields()
    }, [form, isLoading])

    const handleValuesChange = useCallback(
      (updated_values: Partial<NextSteps>, next_steps: NextSteps) => {
        if (updated_values?.owner) {
          datadogRum.addAction('task-form-next-steps:modify:owner', {
            taskId: taskId,
          })
        }

        onChange(next_steps)
      },
      [onChange, taskId],
    )

    return (
      <Spin spinning={isLoading}>
        <Form
          disabled={disabled}
          id="task-next-steps"
          initialValues={initialValues}
          form={form}
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            name="priority"
            label={t('tasks.nextSteps.priority')}
            data-dd-privacy="allow"
          >
            <TaskPriorityInput data-testid={'next-steps-priority'} />
          </Form.Item>
          <OwnerField
            required
            disabled={disabled}
            data-testid={'next-steps-owner'}
          />
          <Form.Item name="description">
            <TaskDescripitionInput
              label={t('tasks.nextSteps.instructions')}
              placeholder={t('tasks.nextSteps.instructionsPlaceholder')}
              data-testid={'next-steps-description'}
              data-dd-action-name="task-form-next-steps-description"
            />
          </Form.Item>
          <Alert
            message={t('tasks.nextSteps.infoText')}
            type="info"
            showIcon
            data-dd-privacy="allow"
          />
        </Form>
      </Spin>
    )
  },
)

export const usePersistedNextSteps = (
  persistedValueId: string,
  defaultNextSteps: NextSteps,
) => {
  const defaultValue = useMemo<NextSteps>(
    () => ({
      priority: TaskPriority.NONE,
      ...defaultNextSteps,
    }),
    [defaultNextSteps],
  )
  const [nextSteps, setNextSteps, , isNextStepsLoaded] = usePersistedValue(
    defaultValue,
    persistedValueId,
  )

  const handleValuesChange = useCallback(
    (newValue: NextSteps) => {
      setNextSteps(newValue)
    },
    [setNextSteps],
  )

  return {
    nextSteps,
    setNextSteps: handleValuesChange,
    isLoadingNextSteps: !isNextStepsLoaded,
  }
}
