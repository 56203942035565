import type { Schedule } from '@dialogue/timekeeper'
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

import {
  makeScheduleState,
  type ScheduleRange,
  type ScheduleState,
  scheduleStateOps,
} from './common'

interface State {
  [providerId: number]: ScheduleState
}

type ProviderScheduleAction<
  T extends Record<string, any> = Record<string, any>,
> = PayloadAction<{ providerId: number; range: ScheduleRange } & T>

export const { actions: providerScheduleActions, reducer } = createSlice({
  name: 'timekeeper/providerSchedule',
  initialState: {} as State,
  reducers: {
    get(state, action: ProviderScheduleAction) {
      const { providerId, range } = action.payload

      if (!state[providerId]) {
        state[providerId] = makeScheduleState()
      }

      scheduleStateOps.get(state[providerId], range)
    },
    received(state, action: ProviderScheduleAction<{ schedule: Schedule }>) {
      const { providerId, range } = action.payload
      const providerState = state[providerId]

      scheduleStateOps.received(providerState, range)
    },
    erroredFetching(state, action: ProviderScheduleAction<{ error: Error }>) {
      const { providerId, error } = action.payload
      const providerState = state[providerId]

      scheduleStateOps.erroredFetching(providerState, error)
    },
    /**
     * Optionally clear the state for a provider
     * (e.g. when navigating away from schedule)
     * to ensure a fresh load the next time we render
     * the schedule for said provider.
     */
    clear(state, action: PayloadAction<{ providerId: number }>) {
      const { providerId } = action.payload
      delete state[providerId]
    },
  },
})

export default reducer

export const selectProviderSchedule = createSelector(
  (state: ReduxState, providerId: number) =>
    state.timekeeper.providerSchedule[providerId],
  // return memoized "initial state" if request hasn't been made yet
  (providerState) => providerState || makeScheduleState(),
)
