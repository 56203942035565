import type {
  AdditionalInterest,
  AdminEventType,
  Appointment,
  AppointmentType,
  CollectionAppointment,
  CollectionAvailabilitySlot,
  CulturalBackground,
  EditAppointment,
  Gender,
  ItemAdminEvent,
  ItemAllProviderMatchingAttributes,
  ItemAppointment,
  ItemListAdminEventType,
  ItemProvider,
  ItemScheduleFilters,
  Language,
  Location,
  NewAdminEvent,
  NewAppointment,
  PageAppointment,
  Provider,
  ProviderMatchingAttributes,
  ScheduleFilters,
} from '@dialogue/timekeeper'
import { createApi } from '@reduxjs/toolkit/query/react'

import { API_NAMES, getDynamicBaseUrl } from './utils'

export enum Attributes {
  GENDER = 'gender',
  CULTURAL_BACKGROUNDS = 'cultural_backgrounds',
  ADDITIONAL_INTERESTS = 'additional_interests',
  OTHER_INTERESTS = 'other_interests',
}

export interface GetProviderRequest {
  providerId: string
}

export interface PatchProviderRequest extends GetProviderRequest {
  payload: ProviderMatchingAttributes
}

export interface GetProviderAppointmentsRequest {
  provider_id: number
  start_at: string
  end_at: string
}

export interface GetAvailabilitiesRequestParams {
  appointment_type: AppointmentType
  location: Location
  language: Language
  start_at: string
  end_at: string
  provider?: number[]
  cherry_pick?: boolean
  genders?: Gender[]
  cultural_backgrounds?: CulturalBackground[]
  additional_interests?: AdditionalInterest[]
}

export enum Tags {
  Attribute = 'Attribute',
  Provider = 'Provider',
  Appointment = 'Appointment',
  AdminEventType = 'AdminEventType',
}

export const MEMBER_APPOINTMENTS_PAGE_SIZE = 100

export const timekeeperApi = createApi({
  tagTypes: Object.values(Tags),
  reducerPath: 'timekeeperApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.TIMEKEEPER),
  endpoints: (builder) => ({
    getProvider: builder.query<Provider, GetProviderRequest>({
      query: ({ providerId }) => ({
        url: `/providers/${providerId}`,
      }),
      transformResponse: (response: ItemProvider) => response.data,
      providesTags: (_result, _error, { providerId }) => [
        { type: Tags.Provider, id: providerId },
      ],
    }),
    updateProvider: builder.mutation<Provider, PatchProviderRequest>({
      query: ({ providerId, payload }) => ({
        url: `/providers/${providerId}`,
        method: 'PATCH',
        body: payload,
      }),
      transformResponse: (response: ItemProvider) => response.data,
      invalidatesTags: (_result, _error, { providerId }) => [
        { type: Tags.Provider, id: providerId },
      ],
    }),
    getBaseProviderAttributes: builder.query({
      query: () => '/providers/attributes',
      providesTags: [Tags.Attribute],
      transformResponse: (response: ItemAllProviderMatchingAttributes) =>
        response.data,
    }),
    getProviderAppointments: builder.query<
      Appointment[],
      GetProviderAppointmentsRequest
    >({
      query: ({ provider_id, ...params }) => ({
        url: `/providers/${provider_id}/appointments`,
        params,
      }),
      providesTags: (_result, _error, { provider_id }) => [
        { type: Tags.Appointment, id: `PROVIDER-${provider_id}` },
      ],
      transformResponse: (response: CollectionAppointment) => response.data,
    }),
    getMemberAppointments: builder.query<
      PageAppointment,
      { member_id: number; status?: string; offset?: number; limit?: number }
    >({
      query: ({ member_id, ...params }) => ({
        url: `/members/${member_id}/appointments`,
        params,
      }),
      providesTags: (_result, _error, { member_id }) => [
        { type: Tags.Appointment, id: `MEMBER-${member_id}` },
      ],
    }),
    createAppointment: builder.mutation({
      query: (body: NewAppointment) => ({
        url: '/appointments',
        params: { check_availability: false },
        method: 'POST',
        body,
      }),
      transformResponse: (response: ItemAppointment) => response.data,
      invalidatesTags: (result, _error) =>
        result
          ? [
              { type: Tags.Appointment, id: `MEMBER-${result.member_id}` },
              {
                type: Tags.Appointment,
                id: `PROVIDER-${result.provider_id}`,
              },
            ]
          : [],
    }),
    updateAppointment: builder.mutation({
      query: ({
        appointmentId,
        ...body
      }: EditAppointment & { appointmentId: number }) => ({
        url: `/appointments/${appointmentId}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: ItemAppointment) => response.data,
      invalidatesTags: (result, _error) =>
        result
          ? [
              { type: Tags.Appointment, id: `MEMBER-${result.member_id}` },
              {
                type: Tags.Appointment,
                id: `PROVIDER-${result.provider_id}`,
              },
            ]
          : [],
    }),
    getAvailabilities: builder.query({
      query: (params: GetAvailabilitiesRequestParams) => ({
        url: '/availabilities',
        params,
      }),
      transformResponse: (response: CollectionAvailabilitySlot) =>
        response.data,
    }),
    getScheduleFilters: builder.query<ScheduleFilters, void>({
      query: () => '/providers/schedule/filters',
      transformResponse: (response: ItemScheduleFilters) => response.data,
    }),

    getAdminEventTypes: builder.query<AdminEventType[], void>({
      query: () => ({
        url: '/admin_events/types',
      }),
      providesTags: [Tags.AdminEventType],
      transformResponse: (response: ItemListAdminEventType) => response.data,
    }),

    createAdminEvent: builder.mutation({
      query: (body: NewAdminEvent) => ({
        url: '/admin_events',
        method: 'POST',
        body,
      }),
      transformResponse: (response: ItemAdminEvent) => response.data,
      // TODO: Invalidate tags for provider schedule once we move those calls to RTKq.
    }),

    deleteAdminEvent: builder.mutation({
      query: (adminEventId: number) => ({
        url: `/admin_events/${adminEventId}`,
        method: 'DELETE',
      }),
      // TODO: Invalidate tags for provider schedule once we move those calls to RTKq.
    }),
  }),
})

export const {
  useGetProviderQuery,
  useUpdateProviderMutation,
  useGetBaseProviderAttributesQuery,
  useGetProviderAppointmentsQuery,
  useGetMemberAppointmentsQuery,
  useCreateAppointmentMutation,
  useUpdateAppointmentMutation,
  useLazyGetAvailabilitiesQuery,
  useGetScheduleFiltersQuery,
  useLazyGetProviderAppointmentsQuery,
  useGetAdminEventTypesQuery,
  useCreateAdminEventMutation,
  useDeleteAdminEventMutation,
} = timekeeperApi
