import { useCallback, useState } from 'react'

import { Button, Input, List, Typography } from 'antd'
import type { TextAreaProps } from 'antd/lib/input'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { colors } from 'app/theme'

const StyledHistory = styled(Input.TextArea)`
  padding-bottom: 26px;
  padding-top: 26px;

  text-decoration: line-through;
  text-decoration-color: ${({ value }: TextAreaProps): string =>
    value ? 'inherit' : colors.textLight};

  &.ant-input-disabled {
    color: ${colors.onSurface};
  }
`

const StyledList: typeof List = styled(List)`
  .ant-list-item {
    padding: 8px 0 0 0;
  }
`

const StyledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

const OverlayText = styled(Typography.Text)`
  position: absolute;
  padding: 4px 11px;
  color: ${colors.textLight};
  z-index: 1;
`
interface HistoryItemMetadata {
  timestamp: string
  providerName: string
}
export interface HistoryItem {
  id: string
  value?: string
  taskId: string
  metadata: HistoryItemMetadata
}

export const TextAreaHistoryItem = ({
  value,
  metadata,
  placeholder,
}: HistoryItem & { placeholder?: string }) => {
  const { t: tCommon } = useTranslation('common')
  const { t } = useTranslation()
  return (
    <div css={'width: 100%; position: relative;'}>
      <StyledOverlay>
        <OverlayText
          strong
          css={`
            color: ${colors.error};
          `}
        >
          {tCommon('loader.outdated')}
        </OverlayText>
        <OverlayText
          css={`
            bottom: 0px;
            right: 0px;
          `}
        >
          {t('tasks.values.activityMetadata', {
            providerName: metadata.providerName,
            timestamp: metadata.timestamp,
          })}
        </OverlayText>
      </StyledOverlay>
      <StyledHistory
        autoSize
        disabled
        value={value}
        placeholder={placeholder}
      />
    </div>
  )
}

export interface TextAreaHistoryProps {
  history: HistoryItem[]
  isLoading?: boolean
  placeholder?: string
  errorMessage: string | null
}

export const TextAreaHistory = ({
  history,
  isLoading,
  placeholder,
  errorMessage,
}: TextAreaHistoryProps) => {
  const { t: tCommon } = useTranslation('common')
  const [isHistoryVisible, setIsHistoryVisible] = useState(false)
  const isShowMoreButtonVisible = !!errorMessage || !!history.length

  const toggleIsHistoryVisible = useCallback(() => {
    setIsHistoryVisible(!isHistoryVisible)
  }, [isHistoryVisible])
  const renderHistoryItem = useCallback(
    (props: HistoryItem) => {
      return (
        <List.Item key={props.id}>
          <TextAreaHistoryItem placeholder={placeholder} {...props} />
        </List.Item>
      )
    },
    [placeholder],
  )

  return (
    <>
      {isHistoryVisible && (
        <StyledList<HistoryItem>
          dataSource={history}
          renderItem={renderHistoryItem}
          loading={isLoading}
          split={false}
          locale={{
            emptyText: tCommon('list.noData'),
          }}
        >
          {!!errorMessage && (
            <List.Item css={'justify-content: end;'}>
              <Typography.Text type="danger">{errorMessage}</Typography.Text>
            </List.Item>
          )}
        </StyledList>
      )}
      {isShowMoreButtonVisible && (
        <div css={'display: flex; justify-content: flex-end;'}>
          <Button
            onClick={toggleIsHistoryVisible}
            type="link"
            data-testid={'textarea-history-toggle'}
            css={'padding-right: 0;'}
            data-dd-privacy="allow"
            data-dd-action-name="protected-text-area:toggle:view-history"
          >
            {isHistoryVisible
              ? tCommon('button.showLess')
              : tCommon('button.showMore')}
          </Button>
        </div>
      )}
    </>
  )
}
