import { CloseCircleOutlined } from '@ant-design/icons'
import { baseColors } from '@dialogue/basics'
import { IncomingFaxDocumentStatus } from '@dialogue/document-center'
import { Button, Form, Tooltip, Typography } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import { useTranslation } from 'react-i18next'

import {
  EpisodeEntityLink,
  MemberProfileEntityLink,
} from 'app/components/entity-links'
import { EpisodePicker } from 'app/components/episode-picker'
import { MemberPicker } from 'app/components/member-picker'
import type { ViewerIncomingFaxDocument } from 'app/redux/documents/viewer'

import { FormContainer, HeaderLine, LineItem } from '../common'

interface Props {
  document: ViewerIncomingFaxDocument
  form: FormInstance
  readonly?: boolean
  onUnassignMember?: () => void
}

export const MemberProperties = ({
  document,
  form,
  readonly = false,
  onUnassignMember,
}: Props) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')

  const memberIdFieldValue = Form.useWatch(['shared', 'memberId'], form)
  const episodeIdFieldValue = Form.useWatch(['document', 'episodeId'], form)

  const hasLinkedEpisode = memberIdFieldValue && episodeIdFieldValue

  return (
    <>
      <HeaderLine
        title={t('documents.sections.member')}
        extra={
          !!onUnassignMember && (
            <Button
              data-testid="unassign-member-button"
              icon={
                <CloseCircleOutlined style={{ color: baseColors.massacre }} />
              }
              onClick={onUnassignMember}
              type="link"
            >
              <Typography.Text style={{ color: baseColors.massacre }} underline>
                {tCommon('button.remove')}
              </Typography.Text>
            </Button>
          )
        }
      />
      <div
        css={`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <LineItem
          label=""
          value={
            <FormContainer>
              <Form.Item noStyle name={['shared', 'memberId']}>
                <MemberPicker
                  $extendedInfo
                  defaultId={memberIdFieldValue}
                  data-cy="fax-member-id-field"
                  variant="dobEmail"
                  // disabled when there is already a member assigned to the document, need to unassign first
                  disabled={readonly || !!document.member_document?.id}
                />
              </Form.Item>
              {memberIdFieldValue ? (
                <MemberProfileEntityLink
                  ddActionName="incoming-fax:goto:member-profile"
                  memberId={memberIdFieldValue}
                  openInNewTab
                  showText={false}
                />
              ) : null}
            </FormContainer>
          }
        />

        {document.status !== IncomingFaxDocumentStatus.received && (
          <LineItem
            label={t('documents.sections.episode')}
            value={
              <FormContainer>
                <Tooltip
                  title={
                    document.member_document?.post_id
                      ? t('documents.details.episodeDisabledByPostId')
                      : ''
                  }
                >
                  <Form.Item noStyle name={['document', 'episodeId']}>
                    <EpisodePicker
                      memberId={memberIdFieldValue}
                      fallbackTitle={t('episodes.missingTitle')}
                      placeholder={t('documents.details.episodePlaceholder')}
                      disabled={readonly}
                    />
                  </Form.Item>
                </Tooltip>
                {hasLinkedEpisode ? (
                  <EpisodeEntityLink
                    ddActionName="incoming-fax:goto:episode"
                    episodeId={episodeIdFieldValue}
                    episodeOwnerId={memberIdFieldValue}
                    openInNewTab
                    postId={document.member_document?.post_id}
                    showText={false}
                  />
                ) : null}
              </FormContainer>
            }
          />
        )}
      </div>
    </>
  )
}
