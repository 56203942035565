import { useCallback, useMemo } from 'react'

import { Select, Typography, type SelectProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import models from 'app/lib/data-models'
import { normalizeString } from 'app/lib/helpers'
import { autofillActions } from 'app/redux/documents/autofill'
import {
  selectAutofillArguments,
  selectAutofillFetching,
} from 'app/redux/documents/selectors'
import { selectOwnPractitionerLicenses } from 'app/redux/user-management/reducer'

const { Text } = Typography

const StyledSelect = styled(Select).attrs<SelectProps>({
  showSearch: true,
  filterOption: (input, option) =>
    normalizeString(option?.label?.toString()).includes(normalizeString(input)),
})`
  display: block;
  width: 200px;
  margin-bottom: 8px;
`

const PROVINCES = models.adminAreas.CA

const LOCATION_OPTIONS = [
  {
    value: 'CA-QC-U',
    label: 'QC - Ubisoft',
  },
  {
    value: 'CA-QC-BNC',
    label: 'QC - BNC',
  },
  ...PROVINCES.map(({ value }) => ({
    value,
    label: value.split('-')[1],
  })),
]

const AutofillSelectors = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('translation', {
    keyPrefix: 'documents.autofillPopover',
  })

  const loading = useAppSelector(selectAutofillFetching)
  const autofillArgs = useAppSelector(selectAutofillArguments)
  const licenses = useAppSelector(selectOwnPractitionerLicenses)

  const licensesAmount = Object.keys(licenses || {}).length
  const hasMoreThanOneLicense = licensesAmount > 1

  const isLicenseDisabled = !autofillArgs.provinceCode

  const licenseOptions = useMemo(
    () =>
      Object.values(licenses || {}).map(
        ({ id, license_number, billing_number, admin_area_iso_code }) => {
          const license = `${license_number}${
            billing_number ? ` - ${billing_number}` : ''
          } (${admin_area_iso_code.split('-')[1]})`
          return {
            value: String(id),
            label: license,
          }
        },
      ),
    [licenses],
  )

  const getProvinceLicensesIds = useCallback(
    (provinceCode: string) =>
      Object.values(licenses || {})
        .filter(({ admin_area_iso_code }) =>
          provinceCode.includes(admin_area_iso_code),
        )
        .map(({ id }) => String(id)),
    [licenses],
  )

  const handleLocationChange = useCallback(
    (value: string) => {
      const newArgs = { ...autofillArgs, provinceCode: value }
      if (hasMoreThanOneLicense) {
        const provinceLicensesIds = getProvinceLicensesIds(value)
        newArgs.licenseId = provinceLicensesIds.length
          ? provinceLicensesIds[0]
          : undefined
      }
      dispatch(autofillActions.refresh(newArgs))
    },
    [autofillArgs, hasMoreThanOneLicense, getProvinceLicensesIds, dispatch],
  )

  const handleLicenseChange = useCallback(
    (value: string) => {
      dispatch(
        autofillActions.refresh({
          ...autofillArgs,
          licenseId: value,
        }),
      )
    },
    [autofillArgs, dispatch],
  )

  return (
    <>
      <Text strong>{t('dialogueLocation')}</Text>
      <StyledSelect
        placeholder={t('selectPlaceholder')}
        value={autofillArgs.provinceCode}
        onChange={handleLocationChange}
        options={LOCATION_OPTIONS}
        loading={loading}
      />
      <Text strong>{t('licenseNumber')}</Text>
      <StyledSelect
        placeholder={t('selectPlaceholder')}
        value={autofillArgs.licenseId}
        onChange={handleLicenseChange}
        options={licenseOptions}
        disabled={isLicenseDisabled}
        loading={loading}
      />
    </>
  )
}

export default AutofillSelectors
