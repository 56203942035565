import { useCallback } from 'react'

import { DownloadOutlined } from '@ant-design/icons'
import { datadogLogs } from '@datadog/browser-logs'
import { Alert, Button, Space, Typography, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useLazyGetMemberDocumentDownloadUrlQuery } from 'app/redux/api/document-center/member-documents'
import { colors } from 'app/theme'

export const UnsupportedDocument = ({
  documentId,
  memberId,
}: {
  documentId: string
  memberId: number | null
}) => {
  const { t } = useTranslation()

  const [getDownloadUrl] = useLazyGetMemberDocumentDownloadUrlQuery()

  const handleFileDownload = useCallback(async () => {
    if (!memberId) {
      return
    }

    getDownloadUrl({ documentId, memberId })
      .unwrap()
      .then((fileUrl) => {
        window.open(fileUrl)
        notification.success({
          message: t('documents.unsupported.successfullyDownloaded'),
        })
      })
      .catch((error) => {
        datadogLogs.logger.error(
          'Failed to download unsupported document',
          { documentId, memberId },
          error,
        )
        notification.error({
          message: t('documents.unsupported.errorDownloading'),
        })
      })
  }, [documentId, getDownloadUrl, memberId, t])

  return (
    <UnsupportedContainer>
      <ErrorContainer direction="vertical" size={8}>
        <UnsupportedTitle level={3}>
          {t('documents.unsupported.title')}{' '}
          {memberId && t('documents.unsupported.downloadToView')}
        </UnsupportedTitle>
        {memberId && (
          <>
            <Button
              onClick={handleFileDownload}
              type="primary"
              icon={<DownloadOutlined />}
            >
              {t('documents.unsupported.download')}
            </Button>
            <Alert
              type="warning"
              description={t('documents.unsupported.warning')}
              showIcon
              css={`
                margin-top: 8px;
              `}
            />
          </>
        )}
      </ErrorContainer>
    </UnsupportedContainer>
  )
}

const UnsupportedTitle = styled(Typography.Title)`
  max-width: 250px;
  margin: 0 auto;
  font-weight: 600;
`

const UnsupportedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${colors.backgroundDark};
`

const ErrorContainer = styled(Space)`
  padding: 32px 9px;
  min-width: 450px;
  background-color: ${colors.surface};
  text-align: center;
`
