import platformLib from 'platform'

import { version as packageJsonVersion } from '../../../package.json'

const isElectron = !!window.electronApi?.isElectron

const appVersion = () =>
  // isElectron ensures window.electronApi is defined hence ! operator
  isElectron ? 'v' + window.electronApi!.getVersion() : 'v' + packageJsonVersion

export const deviceInfo = {
  getOsInfo: () => {
    if (!platformLib.os) return 'unknown'
    return `${platformLib.os.architecture}-${platformLib.os.family}-${platformLib.os.version}`
  },

  getVersion: () => appVersion(),

  isElectron: () => isElectron,

  getDevices: async () => {
    const devices = await navigator.mediaDevices?.enumerateDevices()
    return devices
      ? {
          audio: devices
            .filter((d) => d.kind === 'audioinput')
            .map((d) => d.toJSON()),
          video: devices
            .filter((d) => d.kind === 'videoinput')
            .map((d) => d.toJSON()),
        }
      : {}
  },

  getAppTarget: () => 'master',
}

export default deviceInfo
