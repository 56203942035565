import { type ReactNode, useCallback, useMemo, useState } from 'react'

import { CheckSquareOutlined, CopyOutlined } from '@ant-design/icons'
import type { TaskTemplate } from '@dialogue/coredata'
import { Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { StyledListItem } from 'app/components/list-select/list-select'
import { SearchableListSelect } from 'app/components/list-select/searchable-list-select'
import { colors } from 'app/theme'

const StyledContentContainer = styled.div`
  width: 350px;
`

const StyledContainer = styled.div.attrs<{ className?: string }>({
  className: 'dd-privacy-allow',
})`
  overflow: auto;
  height: 200px;
`

interface TemplateSelectProps {
  templates?: TaskTemplate[]
  loading?: boolean
  children: ReactNode
  onSelect: (id?: string) => void
  hasError?: boolean
  showBlankTemplate?: boolean
}

export const TemplateSelect = ({
  templates,
  loading,
  children,
  onSelect,
  hasError,
  showBlankTemplate,
}: TemplateSelectProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const options = useMemo(
    () =>
      templates?.map((template) => ({
        label: (
          <>
            <CopyOutlined
              css={`
                color: ${colors.textLight};
                margin-right: 4px;
              `}
            />
            {template.name}
          </>
        ),
        value: template.id,
        filterValue: template.name,
      })),
    [templates],
  )

  const handleSelect = useCallback(
    (value?: string) => {
      onSelect(value)
      setIsOpen(false)
    },
    [onSelect],
  )

  const handleSelectBlankTask = useCallback(() => {
    handleSelect()
  }, [handleSelect])

  const handleOpenChange = useCallback((value: boolean) => {
    setIsOpen(value)
  }, [])

  return (
    <Popover
      trigger="click"
      destroyTooltipOnHide
      open={isOpen}
      onOpenChange={handleOpenChange}
      content={
        <StyledContentContainer>
          {showBlankTemplate && (
            <StyledListItem onClick={handleSelectBlankTask}>
              <CheckSquareOutlined
                css={`
                  color: ${colors.textLight};
                  margin-right: 4px;
                `}
              />
              Blank task
            </StyledListItem>
          )}
          <SearchableListSelect
            options={options}
            onSelect={handleSelect}
            loading={loading}
            errorMessage={t('tasks.error.fetchingTemplates')}
            hasError={hasError}
            emptyText={t('tasks.templates.empty')}
            placeholder={t('tasks.templates.searchPlaceholder')}
            listContainerRender={StyledContainer}
          />
        </StyledContentContainer>
      }
    >
      {children}
    </Popover>
  )
}
