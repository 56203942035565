import type { EnrichedEpisode } from '@dialogue/coredata'
import type { EmergencyRoomTypes } from '@dialogue/services'

export enum EpisodesTypes {
  REQUEST_EPISODES_FROM_STATE = '@@episodes/REQUEST_EPISODES_FROM_STATE',
  REQUEST_EPISODES_FROM_STATE_SUCCESS = '@@episodes/REQUEST_EPISODES_FROM_STATE_SUCCESS',
  REQUEST_EPISODES_FROM_STATE_FAILURE = '@@episodes/REQUEST_EPISODES_FROM_STATE_FAILURE',

  INIT_POLLING_EPISODE = '@@episodes/INIT_POLLING_EPISODE',
  START_POLLING_EPISODE = '@@episodes/START_POLLING_EPISODE',
  STOP_POLLING_EPISODE = '@@episodes/STOP_POLLING_EPISODE',

  GET_EPISODE = '@@episodes/GET_EPISODE',
  GET_EPISODE_SUCCESS = '@@episodes/GET_EPISODE_SUCCESS',
  GET_EPISODE_ERROR = '@@episodes/GET_EPISODE_ERROR',

  CREATE_EPISODE = '@@episodes/CREATE_EPISODE',
  CREATE_EPISODE_SUCCESS = '@@episodes/CREATE_EPISODE_SUCCESS',
  CREATE_EPISODE_ERROR = '@@episodes/CREATE_EPISODE_ERROR',

  RESOLVE_ALL_ACTIVE_EPISODES = '@@episodes/RESOLVE_ALL_ACTIVE_EPISODES',
}

interface EpisodeStateData {
  episodes: EnrichedEpisode[]
  fetching: boolean
  error: Error | null
  count?: number
  totalItems?: number
}
export type EpisodesState = Record<
  EmergencyRoomTypes.EpisodeState,
  EpisodeStateData
>

export type EpisodeChannel = 'callCentre' | 'ccq'
