import React from 'react'

import { UserOutlined } from '@ant-design/icons'
import { Avatar, Typography } from 'antd'
import { styled } from 'styled-components'

const OptionContainer = styled.div`
  width: 350px;
  display: flex;
  align-items: center;
  line-height: 1.2em;
`

export const UserInfo: React.FC<{
  name: string
  username: string
  avatarSrc?: string
}> = ({ name, username, avatarSrc }) => {
  return (
    <OptionContainer>
      <Avatar
        size="small"
        src={avatarSrc}
        icon={<UserOutlined />}
        css={`
          flex-shrink: 0;
          margin-right: 4px;
        `}
      />
      <div>
        <Typography.Text>{name}</Typography.Text>
        <br />
        <Typography.Text type="secondary">@{username}</Typography.Text>
      </div>
    </OptionContainer>
  )
}

export default React.memo(UserInfo)
