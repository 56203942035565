import {
  type ChangeEventHandler,
  type ReactNode,
  useCallback,
  useState,
} from 'react'

import { Button, Input, Layout, Typography, Space } from 'antd'
import type { TextAreaProps } from 'antd/lib/input'
import { Content, Footer } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { colors } from 'app/theme'

const StyledTextArea = styled(Input.TextArea)`
  &.ant-input-disabled {
    color: ${colors.onSurface};
  }
`

export interface EditingOptionProps {
  onChange: (value: string) => void
  value: string
}

export type EditingOptionsType = (props: EditingOptionProps) => ReactNode

export interface EditableTextAreaProps {
  value?: string
  onSave: (newValue: string) => void
  onCancel?: () => void
  editingOptions?: EditingOptionsType
  autosave?: boolean
  placeholder?: string
}

export const EditableTextArea = ({
  value: initialValue,
  onSave,
  onCancel,
  editingOptions,
  autosave,
  ...textAreaProps
}: EditableTextAreaProps) => {
  const { t: tCommon } = useTranslation('common')
  const [value, setValue] = useState<string>(initialValue || '')

  const handleSave = useCallback(() => {
    onSave(value)
  }, [onSave, value])

  const handleOnChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      const newValue = e.target.value
      setValue(newValue)
      if (autosave) onSave(newValue)
    },
    [autosave, onSave],
  )

  return (
    <Layout css={'margin-bottom: 8px;'}>
      <Content>
        <StyledTextArea
          value={value}
          onChange={handleOnChange}
          autoSize
          {...textAreaProps}
        />
      </Content>
      <Footer
        css={`
          display: flex;
          padding: 6px;
        `}
      >
        {editingOptions && (
          <Space>{editingOptions({ value, onChange: setValue })}</Space>
        )}
        {!autosave && (
          <Space
            direction="horizontal"
            data-dd-privacy="allow"
            css={'margin-right: 0; margin-left: auto;'}
          >
            <Button
              onClick={onCancel}
              data-testid={'protected-text-area-cancel-button'}
              data-dd-action-name="versioned-text-area:edit-cancel"
            >
              {tCommon('button.cancel')}
            </Button>
            <Button
              type="primary"
              onClick={handleSave}
              data-testid={'protected-text-area-save-button'}
              data-dd-action-name="versioned-text-area:edit-confirm"
            >
              {tCommon('button.update')}
            </Button>
          </Space>
        )}
      </Footer>
    </Layout>
  )
}

export type EditableInputType = (props: EditableTextAreaProps) => ReactNode

interface ProtectedTextAreaProps {
  fieldName: string
  value?: string
  onChange?: (newValue: string) => void
  renderEditableInput?: (props: EditableTextAreaProps) => ReactNode
  renderInput?: (props: TextAreaProps) => ReactNode
  disabled?: boolean
  placeholder?: string
}

export const ProtectedTextArea = ({
  fieldName,
  value,
  onChange,
  renderEditableInput,
  renderInput,
  disabled,
  placeholder,
}: ProtectedTextAreaProps) => {
  const { t: tCommon } = useTranslation('common')

  const [isEditingEnabled, setIsEditingEnabled] = useState(false)

  const handleEnableEditing = useCallback(() => {
    setIsEditingEnabled(true)
  }, [setIsEditingEnabled])

  const handleDisableEditing = useCallback(() => {
    setIsEditingEnabled(false)
  }, [setIsEditingEnabled])

  const handleSave = useCallback(
    (newValue: string) => {
      setIsEditingEnabled(false)
      onChange?.(newValue)
    },
    [onChange],
  )

  const renderDefaultInput = useCallback(
    (props: TextAreaProps) => <StyledTextArea {...props} autoSize />,
    [],
  )
  const renderDefaultEditableInput = useCallback(
    (props: EditableTextAreaProps) => <EditableTextArea {...props} />,
    [],
  )

  const InputComponent = renderInput || renderDefaultInput
  const EditableInputComponent =
    renderEditableInput || renderDefaultEditableInput

  return (
    <div css={'width: 100%'}>
      <div
        css={
          'display: flex; padding-bottom: 4px; justify-content: space-between;'
        }
      >
        <Typography.Text data-dd-privacy="allow">{fieldName}</Typography.Text>
        <Button
          onClick={handleEnableEditing}
          type="link"
          data-testid={'protected-input-edit-button'}
          data-dd-privacy="allow"
          data-dd-action-name="protected-text-area:edit"
          disabled={disabled}
          css={'padding: 0; height: fit-content;'}
        >
          {tCommon('button.update')} {fieldName.toLowerCase()}
        </Button>
      </div>
      {isEditingEnabled && (
        <EditableInputComponent
          value={value}
          onSave={handleSave}
          onCancel={handleDisableEditing}
          placeholder={placeholder}
        />
      )}
      <InputComponent disabled value={value} placeholder={placeholder} />
    </div>
  )
}
