// @ts-strict-ignore
import { notification } from 'antd'
import { all, call, put, takeLatest } from 'typed-redux-saga/macro'

import i18n from 'app/i18n'
import { documentViewerActions } from 'app/redux/documents/viewer'

import { getDocumentById } from './members'

function* resolveAndViewDcMemberDocument(memberId: number, documentId: string) {
  const memberDocument = yield* call(getDocumentById, memberId, documentId)

  yield* put(
    documentViewerActions.viewMemberDocument({ document: memberDocument }),
  )
}

export function* resolveAndViewMemberDocument({
  payload: { documentId, memberId },
}: ReturnType<typeof documentViewerActions.resolveAndViewMemberDocument>) {
  try {
    yield* call(resolveAndViewDcMemberDocument, memberId, documentId)
  } catch (e) {
    notification.error({
      message: i18n.t('documents.errorResolvingDocument'),
    })
    console.warn('could not resolve file', e)
  }
}

export default function* documentViewerSagas() {
  yield* all([
    takeLatest(
      documentViewerActions.resolveAndViewMemberDocument,
      resolveAndViewMemberDocument,
    ),
  ])
}
