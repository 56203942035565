import type { EnrichedEpisode } from '@dialogue/coredata'
import type { EmergencyRoomTypes } from '@dialogue/services'
import type { AdminEvent, NewAdminEvent } from '@dialogue/timekeeper'
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'
import type { Moment } from 'moment'

import type { ReduxState } from 'app/redux'
import type { Episode } from 'app/redux/episode-view/types'

import { makeSetForRangeAndProviders } from './schedule/common'
import {
  filterGroupScheduleActions,
  selectFilterGroupProviderIds,
} from './schedule/filter-group-schedule'
import { providerScheduleActions } from './schedule/provider-schedule'

export interface FormData {
  admin_type: string
  description?: string
  start_date: Moment
  end_date: Moment
  episode_id: string
  provider_id: number
}

export type FormSubmitData = FormData & {
  episode:
    | EmergencyRoomTypes.EnrichedEpisode
    | EnrichedEpisode
    | Partial<Episode>
}

interface State {
  creating: boolean
  errorCreating: Error | null
  deleting: boolean
  errorDeleting: Error | null
}

const adminEventAdapter = createEntityAdapter<AdminEvent>({
  selectId: (adminEvent) => adminEvent.id,
  sortComparer: false,
})

const INITIAL_STATE = adminEventAdapter.getInitialState<State>({
  creating: false,
  errorCreating: null,
  deleting: false,
  errorDeleting: null,
})

export const convertAdminEventFormData = (
  formData: FormSubmitData,
): NewAdminEvent => {
  return {
    admin_type: formData.admin_type,
    description: formData.description,
    start_at: formData.start_date.toISOString(),
    end_at: formData.end_date.toISOString(),
    provider_id: formData.provider_id,
    member_id: formData.episode?.subject_id || formData.episode?.patient_id,
    episode_owner_id: formData.episode?.patient_id,
    episode_id: formData.episode_id,
  }
}

const setForRangeAndProviders = makeSetForRangeAndProviders(adminEventAdapter)

export const { actions: timekeeperAdminEventActions, reducer } = createSlice({
  name: '@@timekeeper/adminEvents',
  initialState: INITIAL_STATE,
  reducers: {
    create: {
      reducer(state, _action: PayloadAction<{ newAdminEvent: NewAdminEvent }>) {
        state.creating = true
        state.errorCreating = null
      },
      prepare({ adminEventFormData }: { adminEventFormData: FormSubmitData }) {
        return {
          payload: {
            newAdminEvent: convertAdminEventFormData(adminEventFormData),
          },
        }
      },
    },
    created(state, { payload }: PayloadAction<{ adminEvent: AdminEvent }>) {
      state.creating = false
      adminEventAdapter.setOne(state, payload.adminEvent)
    },
    erroredCreating(state, { payload }: PayloadAction<Error>) {
      state.creating = false
      state.errorCreating = payload
    },
    delete(state, _action: PayloadAction<{ adminEventId: AdminEvent['id'] }>) {
      state.deleting = true
      state.errorDeleting = null
    },
    deleted(
      state,
      { payload }: PayloadAction<{ adminEventId: AdminEvent['id'] }>,
    ) {
      state.deleting = false
      adminEventAdapter.removeOne(state, payload.adminEventId)
    },
    erroredDeleting(state, { payload }: PayloadAction<Error>) {
      state.deleting = false
      state.errorDeleting = payload
    },
  },
  extraReducers(builder) {
    builder.addCase(providerScheduleActions.received, (state, action) => {
      setForRangeAndProviders(
        state,
        action.payload.schedule.admin_events,
        [action.payload.schedule.provider_id],
        action.payload.range,
      )
    })
    builder.addCase(filterGroupScheduleActions.received, (state, action) => {
      const adminEvents = action.payload.schedules.flatMap(
        (schedule) => schedule.admin_events,
      )
      const providerIds = action.payload.schedules.map(
        (schedule) => schedule.provider_id,
      )
      setForRangeAndProviders(
        state,
        adminEvents,
        providerIds,
        action.payload.range,
      )
    })
  },
})

const selectors = adminEventAdapter.getSelectors(
  (state: ReduxState) => state.timekeeper.adminEvents,
)

export const selectAdminEventsForProvider = createSelector(
  selectors.selectAll,
  (_state: ReduxState, providerId: number) => providerId,
  (adminEventsDataMap, providerId): AdminEvent[] =>
    adminEventsDataMap.filter((a) => a.provider_id === providerId),
)

export const selectAdminEventsForFilterGroup = createSelector(
  selectors.selectAll,
  selectFilterGroupProviderIds,
  (adminEventsDataMap, providerIds) =>
    adminEventsDataMap.filter((a) => providerIds.includes(a.provider_id)),
)

export const selectCreateAdminEventError = (state: ReduxState) =>
  state.timekeeper.adminEvents.errorCreating

export default reducer
