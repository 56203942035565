import { useCallback, useContext } from 'react'

import { CheckOutlined, WarningOutlined } from '@ant-design/icons'
import { baseColors } from '@dialogue/basics'
import { TaskStatus } from '@dialogue/coredata'
import { Button, notification, Popconfirm, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import type { NextSteps } from 'app/components/tasks/next-steps-form'
import { TaskDrawerContext } from 'app/containers/tasks/task-drawer-context'
import {
  getFormName,
  changedValuesOwnerToTaskValues,
} from 'app/containers/tasks/task-form'
import {
  useCreateTaskMutation,
  useUpdateTaskStatusMutation,
} from 'app/redux/api/emergency-room/tasks'

const StyledSpace = styled(Space)`
  justify-content: end;
  box-shadow: -2px -2px 4px ${baseColors.smoke};
  /*
    a bug with antd form items results in them covering the box-shadow.
    position: relative fixes that issue.
  */
  position: relative;
`

export const TaskDrawerFooter = ({
  nextSteps,
  isLoading,
  validateNextSteps,
  ...props
}: {
  nextSteps?: NextSteps
  isLoading?: boolean
  validateNextSteps?: () => Promise<NextSteps>
}) => {
  const { t } = useTranslation()

  const { task, initialData, closeDrawer } = useContext(TaskDrawerContext)

  const { id, status, assignee_id, teams = [] } = task || {}
  const isNewSubtask = !!initialData?.parent_id

  const totalAssigneeCount = (assignee_id ? 1 : 0) + teams.length

  const newStatus =
    status === TaskStatus.DONE ? TaskStatus.TO_DO : TaskStatus.DONE

  const [updateTaskStatus] = useUpdateTaskStatusMutation()

  const [, { isLoading: isCreatingTaskOrSubTask }] = useCreateTaskMutation()

  const handleStatusChange = useCallback(async () => {
    if (!task || isLoading || !nextSteps) return

    const { description, priority, assignee_id, team_ids } =
      changedValuesOwnerToTaskValues(nextSteps)
    const includeNextSteps = description && newStatus === TaskStatus.DONE
    if (includeNextSteps) {
      try {
        await validateNextSteps?.()
      } catch (e) {
        console.info(e)
        notification.error({
          message: t('tasks.error.updatingTaskStatus'),
        })
        return
      }
    }

    updateTaskStatus({
      taskId: task.id,
      status: newStatus,
      next_steps: includeNextSteps
        ? [{ description, priority, assignee_id, team_ids }]
        : undefined,
    })
      .unwrap()
      .then(() => {
        if (newStatus === TaskStatus.TO_DO) return
        closeDrawer()
      })
  }, [
    task,
    isLoading,
    nextSteps,
    newStatus,
    updateTaskStatus,
    validateNextSteps,
    t,
    closeDrawer,
  ])

  // If there are multiple assignee, show a confirmation modal before marking as done
  const shouldConfirmWhenMarkAsDone =
    totalAssigneeCount > 1 && status === TaskStatus.TO_DO

  return (
    <StyledSpace
      css={`
        justify-content: end;
      `}
      {...props}
    >
      {id ? (
        <Popconfirm
          title={
            <span
              data-testid="update-status-popconfirm"
              data-dd-privacy="allow"
            >
              {t('tasks.actions.markDoneConfirmation')}
            </span>
          }
          okText={t('button.confirm', { ns: 'common' })}
          okButtonProps={{
            // @ts-expect-error Passing arbitrary HTML props is allowed
            'data-dd-action-name': 'task-form:mark-as-done-confirm',
            'data-testid': 'update-status-popconfirm-ok',
          }}
          cancelText={t('button.cancel', { ns: 'common' })}
          cancelButtonProps={{
            // @ts-expect-error Passing arbitrary HTML props is allowed
            'data-dd-action-name': 'task-form:mark-as-done-cancel',
            'data-testid': 'update-status-popconfirm-cancel',
          }}
          onConfirm={handleStatusChange}
          overlayStyle={{ width: 300 }}
          disabled={!shouldConfirmWhenMarkAsDone}
        >
          <Button
            type="primary"
            ghost={status === TaskStatus.DONE}
            // If confirm is needed, disable the button:
            onClick={
              shouldConfirmWhenMarkAsDone ? undefined : handleStatusChange
            }
            data-testid="update-status-btn"
            data-cy="update-status-btn"
            data-dd-privacy="allow"
            data-dd-action-name="task-form:toggle:status"
            icon={
              newStatus === TaskStatus.DONE ? (
                <CheckOutlined />
              ) : (
                <WarningOutlined />
              )
            }
          >
            {newStatus === TaskStatus.DONE
              ? t('tasks.actions.markDone')
              : t('tasks.actions.markTodo')}
          </Button>
        </Popconfirm>
      ) : (
        <Button
          form={getFormName()}
          type="primary"
          htmlType="submit"
          data-cy="create-task-submit-button"
          data-dd-privacy="allow"
          data-dd-action-name="task-form:create"
          loading={isCreatingTaskOrSubTask || isLoading}
        >
          {isNewSubtask
            ? t('tasks.actions.createSubtask')
            : t('tasks.actions.create')}
        </Button>
      )}
    </StyledSpace>
  )
}
