import type { EnrichedEpisode, Patient } from '@dialogue/coredata'
import type { ScribeTypes } from '@dialogue/services'

export interface PatientEpisodes {
  data: { [episodeId: string]: EnrichedEpisode }
  order: string[]
  totalItems: number
  activeEpisodesCount: number
  mostRecentEpisodeIds?: string[]
}

export interface PatientEpisodesStatuses {
  fetching: boolean
  error?: Error | null
  fetchingEpisodesByIds: boolean
  errorFetchingEpisodesByIds?: Error
  fetchingMostRecentEpisodes: boolean
  errorFetchingMostRecentEpisodes?: Error
}

export type MergedPatientProfile = {
  fetchingIdCard?: boolean
  idCardError: Error | null
  id_card_url?: string
  eligible_services: ScribeTypes.V2.UserEligibleServices
  family: ScribeTypes.FamilyMember[]
  fetching: boolean
  error?: Error | null
  activeFamilyMemberId?: number
  updating?: boolean
} & Partial<Patient>

export type MemberAddress = Pick<
  Patient,
  | 'admin_area_iso_code'
  | 'country_iso_code'
  | 'locality'
  | 'postal_code'
  | 'street_number'
  | 'street_number2'
>

export interface PatientsState {
  episodes: Record<string, PatientEpisodes>
  episodesStatuses: Record<string, PatientEpisodesStatuses>
  profiles: Record<string | number, MergedPatientProfile>
  locations: Record<string, any> // FIXME: type - create types in services
  fetchingProfile: boolean
  errorProfile?: Error
  fetchingLocation: boolean
  errorLocation?: Error
  updatingProfile: boolean
  errorUpdatingProfile?: Error
}

export enum PatientsTypes {
  REQUEST_EPISODES = '@@patients/REQUEST_EPISODES',
  REQUEST_EPISODES_SUCCESS = '@@patients/REQUEST_EPISODES_SUCCESS',
  REQUEST_EPISODES_FAILURE = '@@patients/REQUEST_EPISODES_FAILURE',
  REQUEST_MOST_RECENT_EPISODES = '@@patients/REQUEST_MOST_RECENT_EPISODES',
  REQUEST_MOST_RECENT_EPISODES_SUCCESS = '@@patients/REQUEST_MOST_RECENT_EPISODES_SUCCESS',
  REQUEST_MOST_RECENT_EPISODES_FAILURE = '@@patients/REQUEST_MOST_RECENT_EPISODES_FAILURE',
  REQUEST_EPISODE = '@@patients/REQUEST_EPISODE',
  REQUEST_EPISODE_SUCCESS = '@@patients/REQUEST_EPISODE_SUCCESS',
  REQUEST_EPISODE_FAILURE = '@@patients/REQUEST_EPISODE_ERROR',
  REQUEST_EPISODES_BY_IDS = '@@patients/REQUEST_EPISODES_BY_IDS',
  REQUEST_EPISODES_BY_IDS_SUCCESS = '@@patients/REQUEST_EPISODES_BY_IDS_SUCCESS',
  REQUEST_EPISODES_BY_IDS_FAILURE = '@@patients/REQUEST_EPISODES_BY_IDS_FAILURE',
  CLEAR_EPISODES = '@@patients/CLEAR_EPISODES',
  REQUEST_PROFILE = '@@patients/REQUEST_PROFILE',
  REQUEST_PROFILE_SUCCESS = '@@patients/REQUEST_PROFILE_SUCCESS',
  REQUEST_PROFILE_FAILURE = '@@patients/REQUEST_PROFILE_FAILURE',
  REQUEST_ID_CARD = '@@patients/REQUEST_ID_CARD',
  REQUEST_ID_CARD_SUCCESS = '@@patients/REQUEST_ID_CARD_SUCCESS',
  REQUEST_ID_CARD_FAILURE = '@@patients/REQUEST_ID_CARD_FAILURE',
  REQUEST_LOCATION = '@@patients/REQUEST_LOCATION',
  REQUEST_LOCATION_SUCCESS = '@@patients/REQUEST_LOCATION_SUCCESS',
  REQUEST_LOCATION_FAILURE = '@@patients/REQUEST_LOCATION_FAILURE',
  REQUEST_CHARGES = '@@patients/REQUEST_CHARGES',
  REQUEST_CHARGES_SUCCESS = '@@patients/REQUEST_CHARGES_SUCCESS',
  REQUEST_CHARGES_FAILURE = '@@patients/REQUEST_CHARGES_FAILURE',
  UPDATE_PROFILE_REQUEST = '@@patients/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = '@@patients/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE = '@@patients/UPDATE_PROFILE_FAILURE',
  SET_ACTIVE_FAMILY_MEMBER = '@@patients/SET_ACTIVE_FAMILY_MEMBER',
}
