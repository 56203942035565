import { useCallback, useEffect, useRef } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { datadogRum } from '@datadog/browser-rum'
import { baseColors } from '@dialogue/basics'
import type { MemberDocument } from '@dialogue/document-center'
import { Button, Form, type FormProps, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { TaskSection } from 'app/components/tasks/task-section'
import { useOpenMemberDocument } from 'app/containers/documents/document-details/useOpenMemberDocument'
import {
  type ForwardRefProps,
  MemberDocumentsMultiSelect,
  type MemberDocumentsMultiSelectProps,
} from 'app/containers/documents/member-document-multi-select'

export const TASK_ATTACHMENTS_FORM_NAME = 'task-attachments-form'

const MultiSelectListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
`

const StyledAttachmentsSubSection = styled.div`
  flex: 1;
`

const StyledAttachmentsTitle = styled(Typography.Text)`
  margin-bottom: 8px;
  display: block;
`

interface TaskAttachmentsStandaloneProps {
  memberId: number
  taskDocumentIds: string[]
  relatedDocumentIds: string[]
}

export const TaskAttachmentsStandalone = ({
  memberId,
  taskDocumentIds,
  relatedDocumentIds,
}: TaskAttachmentsStandaloneProps) => {
  const { t } = useTranslation()
  const openMemberDocument = useOpenMemberDocument()
  const taskDocumentsMultiSelectRef = useRef<ForwardRefProps>(null)
  const relatedDocumentsMultiSelectRef = useRef<ForwardRefProps>(null)

  const handleOpenTaskDocument = useCallback(
    (id: string, document: MemberDocument) => {
      openMemberDocument(document)
      datadogRum.addAction('task-form:goto:document')
    },
    [openMemberDocument],
  )

  const handleOpenRelatedDocument = useCallback(
    (id: string, document: MemberDocument) => {
      openMemberDocument(document)
      datadogRum.addAction('task-form:goto:related-document')
    },
    [openMemberDocument],
  )

  const hasData =
    (taskDocumentIds && taskDocumentIds.length > 0) ||
    (relatedDocumentIds && relatedDocumentIds.length > 0)

  if (!hasData) {
    return (
      <TaskSection title={t('tasks.properties.labels.attachedDocuments')}>
        <MultiSelectListContainer
          style={{
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
            color: baseColors.aluminum,
          }}
        >
          {t('documents.emptyText')}
        </MultiSelectListContainer>
      </TaskSection>
    )
  }

  return (
    <MultiSelectListContainer>
      <TaskSection title={t('tasks.properties.labels.attachedDocuments')}>
        <StyledAttachmentsSubSection>
          <StyledAttachmentsTitle type={'secondary'}>
            {t('tasks.properties.labels.attachedTaskDocuments')}
          </StyledAttachmentsTitle>
          <MemberDocumentsMultiSelect
            ref={taskDocumentsMultiSelectRef}
            value={taskDocumentIds}
            memberId={memberId}
            onDocumentClick={handleOpenTaskDocument}
            allowEdit={false}
          />
        </StyledAttachmentsSubSection>
        <StyledAttachmentsSubSection>
          <StyledAttachmentsTitle type={'secondary'}>
            {t('tasks.properties.labels.otherAttachedDocuments')}
          </StyledAttachmentsTitle>
          <MemberDocumentsMultiSelect
            ref={relatedDocumentsMultiSelectRef}
            value={relatedDocumentIds}
            memberId={memberId}
            onDocumentClick={handleOpenRelatedDocument}
            allowEdit={false}
          />
        </StyledAttachmentsSubSection>
      </TaskSection>
    </MultiSelectListContainer>
  )
}

export const TaskAttachmentsForm = <FormType extends Record<string, any>>({
  disabled,
  initialValues = {},
  ...rest
}: FormProps<FormType>) => {
  const [form] = Form.useForm()
  const openMemberDocument = useOpenMemberDocument()
  const memberIdFieldValue = Form.useWatch('member_id', form)
  const { t } = useTranslation()

  useEffect(() => {
    form.resetFields()
  }, [initialValues?.id, form])

  const memberDocumentsMultiSelectRef = useRef<ForwardRefProps>(null)

  const handleOpenModal = useCallback(() => {
    memberDocumentsMultiSelectRef.current?.openModal()
  }, [memberDocumentsMultiSelectRef])

  const handleOpenDocument = useCallback<
    NonNullable<MemberDocumentsMultiSelectProps['onDocumentClick']>
  >(
    (id, document) => {
      openMemberDocument(document)
    },
    [openMemberDocument],
  )

  return (
    <Form
      {...rest}
      form={form}
      name={TASK_ATTACHMENTS_FORM_NAME}
      initialValues={initialValues}
      disabled={disabled}
    >
      <Form.Item name="member_id" hidden />
      <Form.Item name="id" hidden />
      <Form.Item name="parent_id" hidden />
      <MultiSelectListContainer>
        <TaskSection
          title={t('tasks.properties.labels.attachedDocuments')}
          actions={
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={handleOpenModal}
              style={{ paddingRight: 0, paddingLeft: 0 }}
              data-cy="open-templates-picker-button"
              data-dd-privacy="allow"
              data-dd-action-name="task-form:add:document"
            >
              {t('tasks.actions.addAttachment')}
            </Button>
          }
        >
          <Form.Item name="document_ids" noStyle>
            <MemberDocumentsMultiSelect
              ref={memberDocumentsMultiSelectRef}
              memberId={memberIdFieldValue}
              onDocumentClick={handleOpenDocument}
            />
          </Form.Item>
        </TaskSection>
      </MultiSelectListContainer>
    </Form>
  )
}
