import { useCallback } from 'react'

import type { TaskComment } from '@dialogue/coredata'
import { List, type ListProps } from 'antd'
import { styled } from 'styled-components'

import { useAppSelector } from 'app/hooks'
import { selectUserId } from 'app/redux/authentification/selectors'

import { Comment } from './comment'

interface CommentListProps {
  comments: TaskComment[]
}

interface Props
  extends CommentListProps,
    Pick<ListProps<TaskComment>, 'loading' | 'locale'> {}

const AutoScrollList = styled(List<TaskComment>)`
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-grow: 1;

  /* 
  ** These properties allow the container to auto scroll and display new comments when the
  ** user is at the bottom, but not when the user is scrolling up to read previous comments.
  ** When the psuedo element is scrolled into view, the container will pin the scroll to the
  ** bottom.
  ** 
  ** Read more on how this works here: https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
  */
  .ant-list-items {
    * {
      overflow-anchor: none;
    }

    &::after {
      content: '';
      height: 1px;
      display: block;
      overflow-anchor: auto;
    }
  }
`

export const CommentList = ({ comments, ...rest }: Props) => {
  const ownProviderId = useAppSelector(selectUserId)?.toString()

  const renderComment: ListProps<TaskComment>['renderItem'] = useCallback(
    (comment: TaskComment, index: number) => {
      const isNewProvider =
        comment.created_by !== comments[index - 1]?.created_by

      return (
        <Comment
          key={comment.id}
          content={comment.text}
          providerName={`${comment.created_by_given_name} ${comment.created_by_family_name}`}
          date={comment.created_at}
          showAuthorInfo={isNewProvider}
          hideBackground={comment.created_by.toString() === ownProviderId}
        />
      )
    },
    [comments, ownProviderId],
  )

  return (
    <AutoScrollList
      {...rest}
      dataSource={comments}
      renderItem={renderComment}
      data-testid="task-comment-list"
    />
  )
}
