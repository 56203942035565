import { useCallback, useEffect } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { autofillActions } from 'app/redux/documents/autofill'
import {
  selectAutofillData,
  selectAutofillFetching,
} from 'app/redux/documents/selectors'

import { useViewerInstance } from '../viewer-context'

import AutofillSelectors from './selectors'
import SummaryItem, { SUMMARY_ITEMS } from './summary-item'

const { Text, Title } = Typography

const InfoContainer = styled.div`
  height: 100%;
  padding: 8px 16px 0;
  background-color: #f7f9fa;
`

const Footer = styled(Row)`
  padding: 8px 8px;
  background-color: #f7f9fa;

  // Override antd styles (styled components don't with popover)
  margin: 16px -16px -12px -16px !important;

  .ant-btn {
    margin-right: 8px;
  }
`

interface AutofillContentProps {
  memberId: number
  onClose: () => void
}

const AutofillContent = ({ memberId, onClose }: AutofillContentProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('translation', {
    keyPrefix: 'documents.autofillPopover',
  })
  const { t: tCommon } = useTranslation('common')

  const { autofillDocument } = useViewerInstance()

  const loading = useAppSelector(selectAutofillFetching)
  const autofillData = useAppSelector(selectAutofillData)

  useEffect(() => {
    dispatch(
      autofillActions.init({
        patientId: memberId,
      }),
    )

    return () => {
      dispatch(autofillActions.reset())
    }
  }, [memberId, dispatch])

  const handleApply = useCallback(() => {
    autofillDocument(autofillData)
    onClose()
  }, [autofillData, autofillDocument, onClose])

  return (
    <>
      <Row gutter={32} justify="space-between" align="top">
        <Col>
          <Title level={3}>{t('title')}</Title>
        </Col>
        <Col>
          <CloseOutlined
            style={{ fontSize: 18, cursor: 'pointer' }}
            data-testid="close-autofill-popover"
            onClick={onClose}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <AutofillSelectors />
        </Col>
        <Col span={12}>
          <InfoContainer>
            <Text strong>{t('summary')}</Text>
            {SUMMARY_ITEMS.map((item) => (
              <SummaryItem key={item.name} {...item} />
            ))}
          </InfoContainer>
        </Col>
      </Row>
      <Footer gutter={24} justify="end">
        <Button onClick={onClose}>{tCommon('button.cancel')}</Button>
        <Button type="primary" disabled={loading} onClick={handleApply}>
          {tCommon('button.apply')}
        </Button>
      </Footer>
    </>
  )
}

export default AutofillContent
