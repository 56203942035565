import { baseColors } from '@dialogue/basics'
import { Radio } from 'antd'
import type { RadioChangeEvent } from 'antd/lib/radio/interface'
import { css, styled } from 'styled-components'

import { colors } from 'app/theme'

const danger = css`
  background-color: ${baseColors.dustLight};
  border-color: ${baseColors.salmonLight};
  &::before {
    background-color: ${baseColors.salmonLight};
  }
`
const warning = css`
  background-color: ${baseColors.eggshellLight};
  border-color: ${baseColors.sand};
  &::before {
    background-color: ${baseColors.sand};
  }
`
const variants = {
  danger,
  warning,
}

export enum RadioButonsVariants {
  Warning = 'warning',
  Danger = 'danger',
}

type VariantProps = 'warning' | 'danger'

interface RadioButtonsGroupProps {
  value?: any
  onChange?: (e: RadioChangeEvent) => void
  options: {
    label: string | JSX.Element
    value: string | number
    variant?: VariantProps
  }[]
}

const RadioButton = styled(Radio.Button)<{ variant?: VariantProps }>`
  &.ant-radio-button-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    color: ${colors.onSurfaceLight};
    &.ant-radio-button-wrapper-checked {
      box-shadow: none;
      color: ${colors.textDark};
      ${({ variant }) => variant && variants[variant]}};
    }
  }
`
export const RadioButtonsGroup = ({
  options,
  value,
  onChange,
}: RadioButtonsGroupProps) => (
  <Radio.Group value={value} onChange={onChange}>
    {options.map((option) => (
      <RadioButton
        key={option.value}
        value={option.value}
        variant={option.variant}
      >
        {option.label}
      </RadioButton>
    ))}
  </Radio.Group>
)
