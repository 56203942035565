import { FlagOutlined } from '@ant-design/icons'
import { styled } from 'styled-components'

import { Tag, type TagProps } from 'app/components/tags/base'
import { colors } from 'app/theme'

const StyledTag = styled(Tag)`
  &&& {
    color: ${colors.warningDark};
    border: 1px solid ${colors.warningLight};
    background-color: ${colors.surfaceWarningLight};
  }
`

export const FlaggedTag = ({ ...rest }: TagProps) => {
  return (
    <StyledTag icon={<FlagOutlined />} color={colors.warningDark} {...rest} />
  )
}
