import { Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { PRIORITY_MAPPING } from 'app/containers/tasks/task-priority-tag'

export interface StyledRadioProps {
  $backgroundColor: string
  $fontColor: string
}

const StyledRadio = styled(Radio)<StyledRadioProps>`
  &.ant-radio-button-wrapper-checked {
    background-color: ${(props) => props.$backgroundColor};
    color: ${(props) => props.$fontColor};
    &:hover {
      color: ${(props) => props.$fontColor};
      border-color: #000;
    }
  }
`

interface TaskPriorityInputProps {
  'data-testid'?: string
}

export const TaskPriorityInput = (props: TaskPriorityInputProps) => {
  const { t } = useTranslation()
  return (
    <Radio.Group optionType="button" buttonStyle="outline" {...props}>
      {Object.values(PRIORITY_MAPPING)
        .reverse()
        .map(({ value, label, backgroundColor, fontColor }) => (
          <StyledRadio
            $backgroundColor={backgroundColor}
            $fontColor={fontColor}
            key={value}
            value={value}
            data-testid={`task-priority-${value}-button`}
          >
            {t(label)}
          </StyledRadio>
        ))}
    </Radio.Group>
  )
}
