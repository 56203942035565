import { useCallback, useMemo } from 'react'

import {
  CalendarOutlined,
  FileOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { baseColors } from '@dialogue/basics'
import { type Task, TaskStatus } from '@dialogue/coredata'
import { Popover, Typography } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { DateOffset } from 'app/components/date/date-offset'
import { CommentList } from 'app/components/task-comments/comment-list'
import TaskOwner from 'app/components/tasks/task-owner'
import { formatDateTime } from 'app/components/tasks/utils'
import { useOpenMemberDocument } from 'app/containers/documents/document-details/useOpenMemberDocument'
import {
  MemberDocumentsMultiSelect,
  type MemberDocumentsMultiSelectProps,
} from 'app/containers/documents/member-document-multi-select'
import { useAppSelector } from 'app/hooks'
import { useGetTaskCommentsQuery } from 'app/redux/api/emergency-room/tasks'
import { selectUserIdToProviderName } from 'app/redux/practitioners/selectors'
import { useTaskDescriptionHistory, useGetTasks } from 'app/redux/tasks/hooks'
import { colors } from 'app/theme'

interface TaskDetailsProps {
  task: Task
}

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 8px;
  font-size: 16px;
`
const PopoverContentContainer = styled(Typography)`
  width: 400px;
  max-width: 500px;
  max-height: 50vh;
  overflow: auto;

  div:first-child {
    padding-bottom: 20px;
  }

  div {
    h5 {
      font-weight: 500;
    }
    span {
      float: right;
      margin-top: -10px;
      color: ${colors.textLight};
    }
  }
`

const CommentsContainer = styled.div`
  max-width: 400px;
  max-height: 700px;
  overflow: auto;
`

export const TaskDetails = ({ task }: TaskDetailsProps) => {
  const { assignee_id, teams, scheduled_for } = task

  return (
    <DetailsContainer className="task-row-details">
      <TaskOwner assigneeId={assignee_id} teams={teams} />
      <DateOffset date={scheduled_for} />
    </DetailsContainer>
  )
}

export const Comments = ({ taskId }: { taskId: string }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'mentions.inbox',
  })
  // TODO: Possibly prefetch in subtasks list component
  const { data: comments = [], isLoading } = useGetTaskCommentsQuery({ taskId })

  const content = (
    <CommentsContainer>
      <CommentList
        comments={comments}
        loading={isLoading}
        locale={{ emptyText: t('noTaskComments') }}
      />
    </CommentsContainer>
  )

  const opacity = comments.length ? 1 : 0.5

  return (
    <Popover
      content={isLoading ? null : content}
      placement="topLeft"
      align={{ offset: [-20, 0] }}
      css={{ opacity }}
    >
      {isLoading ? (
        <LoadingOutlined data-testid="loading-comments-icon" />
      ) : (
        <>
          {comments.length}
          <MessageOutlined
            data-testid="comments-icon"
            style={{ marginLeft: '2px' }}
          />
        </>
      )}
    </Popover>
  )
}

export const Attachments = ({
  documentIds,
  memberId,
}: {
  documentIds: string[]
  memberId: number
}) => {
  const openMemberDocument = useOpenMemberDocument()

  const handleOpenDocument = useCallback<
    NonNullable<MemberDocumentsMultiSelectProps['onDocumentClick']>
  >(
    (_, document) => {
      openMemberDocument(document)
    },
    [openMemberDocument],
  )

  const content = (
    <MemberDocumentsMultiSelect
      value={documentIds}
      memberId={memberId}
      allowEdit={false}
      onDocumentClick={handleOpenDocument}
      style={{ maxWidth: '400px' }}
    />
  )
  const opacity = documentIds.length ? 1 : 0.5
  return (
    <Popover
      content={content}
      placement="topLeft"
      arrowPointAtCenter
      css={{ opacity }}
    >
      {documentIds.length}
      <FileOutlined
        data-testid="attachment-icon"
        style={{ marginLeft: '2px' }}
      />
    </Popover>
  )
}

const InformationDetails = ({
  title,
  emptyTextTitle,
  content,
  providerFullName,
  timestamp,
}: {
  title: string
  emptyTextTitle: string
  content?: string
  providerFullName?: string
  timestamp?: string
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tasks.values',
  })

  const formattedTimestamp = timestamp ? formatDateTime(timestamp) : null
  const providerDetails =
    providerFullName && formattedTimestamp
      ? t('activityMetadata', {
          providerName: providerFullName,
          timestamp: formattedTimestamp,
        })
      : null

  return (
    <div>
      <Title level={5}>{content ? title : emptyTextTitle}</Title>
      {content && (
        <Paragraph>
          <pre>{content}</pre>
        </Paragraph>
      )}
      {content && providerDetails && <Text>{providerDetails}</Text>}
    </div>
  )
}

const Information = ({ task }: TaskDetailsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tasks.properties.labels',
  })

  const { id, parent_id, description, completed_at, completed_by } = task

  // Get details related to task's instructions.

  // Note: As the query used by useGetTasks has been invoked upstream in the TasksView component,
  // this call will read from the cache.
  const { tasks: nextSubtaskData } = useGetTasks({
    parentId: parent_id,
    showCompleted: true,
  })
  // Note: The instructions for this task correspond to the description of the next task
  // created from it.
  const nextSubtask = useMemo(
    () => nextSubtaskData.find((subtask) => subtask.created_from_id === id),
    [nextSubtaskData, id],
  )
  const instructions = nextSubtask?.description
  const instructionsProviderFullName = useAppSelector((state) => {
    if (!completed_by) return
    return selectUserIdToProviderName(state, completed_by)
  })

  // Get details related to task's description

  // Note: Requesting description history of the parent will fetch a
  // unified history of all subtasks, so it's more efficient than fetching
  // history for each individual subtask.
  const { descriptionHistory } = useTaskDescriptionHistory(
    parent_id || id,
    true,
  )
  const latestDescriptionActivity = useMemo(() => {
    return descriptionHistory.filter((activity) => activity.taskId === id).pop()
  }, [descriptionHistory, id])
  const { providerName, timestamp } = latestDescriptionActivity?.metadata || {}

  const content = (
    <PopoverContentContainer>
      <InformationDetails
        title={t('description')}
        emptyTextTitle={t('noDescription')}
        content={description}
        providerFullName={providerName}
        timestamp={timestamp}
      />
      <InformationDetails
        title={t('instructions')}
        emptyTextTitle={t('noInstructions')}
        content={instructions}
        providerFullName={instructionsProviderFullName}
        timestamp={completed_at}
      />
    </PopoverContentContainer>
  )

  return (
    <Popover content={content} placement="topLeft" align={{ offset: [-20, 0] }}>
      <InfoCircleOutlined data-testid="information-icon" />
    </Popover>
  )
}

export const ScheduledFor = ({
  date,
  status,
}: {
  date: string
  status: string
}) => {
  const isTimePast = moment(date).isBefore(moment())
  const isDone = status === TaskStatus.DONE

  return (
    <Popover
      content={<Typography.Text>{formatDateTime(date)}</Typography.Text>}
      placement="topLeft"
      align={{ offset: [-20, 0] }}
    >
      <CalendarOutlined
        data-testid="scheduled-for-icon"
        css={{ color: isTimePast && !isDone ? baseColors.massacre : 'inherit' }}
      />
    </Popover>
  )
}

export const EnrichedTaskDetails = ({ task }: TaskDetailsProps) => {
  const { scheduled_for, document_ids = [], member_id, status } = task

  return (
    <DetailsContainer className="task-row-details">
      <Comments taskId={task.id} />
      <Attachments documentIds={document_ids} memberId={member_id} />
      <Information task={task} />
      <ScheduledFor date={scheduled_for} status={status} />
    </DetailsContainer>
  )
}
