import { CheckOutlined } from '@ant-design/icons'
import { TaskStatus, type Task } from '@dialogue/coredata'
import { Typography } from 'antd'
import { styled } from 'styled-components'

import { TaskPriorityTag } from 'app/components/tasks/task-priority-tag'
import { colors } from 'app/theme'

type TaskTitleProps = Pick<Task, 'title' | 'priority' | 'status'>

const TitleContainer = styled.div<{ status: TaskStatus }>`
  display: flex;
  align-items: center;
  gap: 7px;
  text-decoration: ${(props) =>
    props.status === TaskStatus.DONE ? 'line-through' : 'none'};
`

export const TaskTitle = ({
  title,
  priority,
  status,
  ...rest
}: TaskTitleProps) => {
  return (
    <TitleContainer className="task-row-title" status={status} {...rest}>
      <TaskPriorityTag priority={priority} showTooltip />
      <Typography.Text
        ellipsis={{ tooltip: { title, overlayStyle: { textWrap: 'wrap' } } }}
      >
        {title}
      </Typography.Text>
      {status === TaskStatus.DONE && (
        <CheckOutlined
          css={`
            color: ${colors.success};
          `}
        />
      )}
    </TitleContainer>
  )
}

export default TaskTitle
