import type {
  EnrichedEpisode,
  ItemEpisodesById,
  Patient,
} from '@dialogue/coredata'
import type { ScribeTypes } from '@dialogue/services'
import type { UserEligibleServices } from '@dialogue/services/dist/scribe/v2/model'
import { action } from 'typesafe-actions'

import { PatientsTypes } from './types'

export const requestEpisodes = (
  patientId: number,
  offset?: number,
  limit?: number,
) => action(PatientsTypes.REQUEST_EPISODES, { patientId, offset, limit })

export const requestEpisodesSuccess = (
  patientId: number,
  episodes: EnrichedEpisode[],
  totalItems: number,
  activeEpisodesCount: number,
) =>
  action(PatientsTypes.REQUEST_EPISODES_SUCCESS, {
    patientId,
    episodes,
    totalItems,
    activeEpisodesCount,
  })

export const requestEpisodesFailure = (patientId: number, error: Error) =>
  action(PatientsTypes.REQUEST_EPISODES_FAILURE, { patientId, error })

export const requestMostRecentEpisodes = (patientId: number) =>
  action(PatientsTypes.REQUEST_MOST_RECENT_EPISODES, { patientId })

export const requestMostRecentEpisodesSuccess = (
  patientId: number,
  episodeIds: string[],
  episodeMapping: Record<EnrichedEpisode['id'], EnrichedEpisode>,
) =>
  action(PatientsTypes.REQUEST_MOST_RECENT_EPISODES_SUCCESS, {
    patientId,
    episodeIds,
    episodeMapping,
  })

export const requestMostRecentEpisodesFailure = (
  patientId: number,
  error: Error,
) =>
  action(PatientsTypes.REQUEST_MOST_RECENT_EPISODES_FAILURE, {
    patientId,
    error,
  })

export const requestEpisode = (patientId: number, episodeId: string) =>
  action(PatientsTypes.REQUEST_EPISODE, { patientId, episodeId })

export const requestEpisodeSuccess = (
  patientId: number,
  episodeId: string,
  episode: EnrichedEpisode,
) =>
  action(PatientsTypes.REQUEST_EPISODE_SUCCESS, {
    patientId,
    episodeId,
    episode,
  })

export const requestEpisodeFailure = (
  patientId: number,
  episodeId: string,
  error: Error,
) =>
  action(PatientsTypes.REQUEST_EPISODE_FAILURE, { patientId, episodeId, error })

export const requestEpisodesByIds = (patientId: number, episodeIds: string[]) =>
  action(PatientsTypes.REQUEST_EPISODES_BY_IDS, { patientId, episodeIds })

export const requestEpisodesByIdsSuccess = (
  patientId: number,
  episodes: ItemEpisodesById['data'],
) =>
  action(PatientsTypes.REQUEST_EPISODES_BY_IDS_SUCCESS, {
    patientId,
    episodes,
  })

export const requestEpisodesByIdsFailure = (patientId: number, error: Error) =>
  action(PatientsTypes.REQUEST_EPISODES_BY_IDS_FAILURE, { patientId, error })

export const clearEpisodes = (patientId: number) =>
  action(PatientsTypes.CLEAR_EPISODES, patientId)

export const requestProfile = (
  patientId: number,
  includeHealthProfile: boolean = false,
) =>
  action(PatientsTypes.REQUEST_PROFILE, {
    patientId,
    includeHealthProfile,
  })

export const requestProfileSuccess = (payload: {
  patientId: number
  patientProfile: Patient
  eligible_services: UserEligibleServices
  family: ScribeTypes.FamilyMember[]
}) => action(PatientsTypes.REQUEST_PROFILE_SUCCESS, payload)

export const requestProfileFailure = (patientId: number, error: Error) =>
  action(PatientsTypes.REQUEST_PROFILE_FAILURE, { patientId, error })

export const requestIdCard = (payload: { patientId: number }) =>
  action(PatientsTypes.REQUEST_ID_CARD, payload)

export const requestIdCardSuccess = (payload: {
  patientId: number
  patientIdCardUrl: string
}) => action(PatientsTypes.REQUEST_ID_CARD_SUCCESS, payload)

export const requestIdCardFailure = (payload: {
  patientId: number
  error: Error
}) => action(PatientsTypes.REQUEST_ID_CARD_FAILURE, payload)

export const requestLocation = (patientId: number) =>
  action(PatientsTypes.REQUEST_LOCATION, { patientId })

export const requestLocationSuccess = (
  patientLocationData: any,
  patientId: number,
) =>
  action(PatientsTypes.REQUEST_LOCATION_SUCCESS, {
    patientId,
    patientLocationData,
  })

export const requestLocationFailure = (error: Error, patientId: number) =>
  action(PatientsTypes.REQUEST_LOCATION_FAILURE, { patientId, error })

export const updateProfileRequest = (
  patientId: number,
  patientProfile: Partial<Patient>,
  pinNumber: number | null,
) =>
  action(PatientsTypes.UPDATE_PROFILE_REQUEST, {
    patientId,
    patientProfile,
    pinNumber,
  })

export const updateProfileSuccess = (
  patientId: number,
  patientProfile: Patient,
) => action(PatientsTypes.UPDATE_PROFILE_SUCCESS, { patientId, patientProfile })

export const updateProfileFailure = (patientId: number, error: Error) =>
  action(PatientsTypes.UPDATE_PROFILE_FAILURE, { patientId, error })

export const setActiveFamilyMember = (
  patientId: number,
  activeFamilyMemberId?: number,
) =>
  action(PatientsTypes.SET_ACTIVE_FAMILY_MEMBER, {
    patientId,
    activeFamilyMemberId,
  })
