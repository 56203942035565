import { WarningOutlined } from '@ant-design/icons'
import { styled } from 'styled-components'

import { Tag, type TagProps } from 'app/components/tags/base'
import { colors } from 'app/theme'

const StyledTag = styled(Tag)`
  &&& {
    color: ${colors.errorDark};
    border: 1px solid ${colors.errorLight};
    background-color: ${colors.surfaceErrorLight};
  }
`

export const CriticalTag = ({ ...rest }: TagProps) => {
  return (
    <StyledTag
      icon={<WarningOutlined />}
      color={colors.warningDark}
      {...rest}
    />
  )
}
