import routes from 'app/services/routes'

import { EntityLink, type EntityLinkProps } from './base'

interface MemberProfileEntityLinkProps extends Omit<EntityLinkProps, 'to'> {
  ddActionName: `${string}:goto:member-profile`
  memberId: number | string
}

export const MemberProfileEntityLink = ({
  memberId,
  ...linkProps
}: MemberProfileEntityLinkProps) => {
  return <EntityLink to={routes.memberProfile(memberId)} {...linkProps} />
}

interface EpisodeEntityLinkProps extends Omit<EntityLinkProps, 'to'> {
  ddActionName: `${string}:goto:episode`
  episodeId: string
  episodeOwnerId: number | string
  postId?: string
}

export const EpisodeEntityLink = ({
  episodeId,
  episodeOwnerId,
  postId,
  ...linkProps
}: EpisodeEntityLinkProps) => {
  const episodeRoute = postId
    ? routes.channelPost(episodeOwnerId, episodeId, postId)
    : routes.channel(episodeOwnerId, episodeId)
  return <EntityLink to={episodeRoute} {...linkProps} />
}

interface ConsultNoteEntityLinkProps extends Omit<EntityLinkProps, 'to'> {
  ddActionName: `${string}:goto:consult-note`
  episodeId: string
  episodeOwnerId: number | string
  noteId: number
}

export const ConsultNoteEntityLink = ({
  episodeId,
  episodeOwnerId,
  noteId,
  ...linkProps
}: ConsultNoteEntityLinkProps) => {
  const noteRoute = routes.patientNote(episodeOwnerId, episodeId, noteId)
  return <EntityLink to={noteRoute} {...linkProps} />
}
