import type { FC } from 'react'

import { Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { EllipsizedText } from 'app/components/extended-select'
import { formatTimestamp } from 'app/lib/helpers'
import { useGetPatientNoteQuery } from 'app/redux/api/notepadApi'

type Props = {
  memberId?: number
  noteId?: number
}

const ErrorMessage = ({ noteId }: { noteId?: number }) => {
  const { t } = useTranslation()
  return (
    <Typography.Text type="danger">
      {t('documents.errorLoadingNoteDisplay', { noteId })}
    </Typography.Text>
  )
}

export const NoteDisplay: FC<Props> = ({ memberId, noteId }) => {
  const { t } = useTranslation()
  const {
    data: note,
    isFetching,
    isError,
  } = useGetPatientNoteQuery(
    {
      patientId: memberId || 0,
      noteId: noteId || 0,
    },
    { skip: !noteId || !memberId },
  )

  return (
    <Spin spinning={isFetching} size="small">
      {!noteId && t('documents.details.noNote')}
      {note && (
        <EllipsizedText
          leftText={formatTimestamp({
            timestamp: note.completed_at || note.updated_at,
          })}
          rightText={note.template.title[note.language]}
        />
      )}
      {isError && <ErrorMessage noteId={noteId} />}
    </Spin>
  )
}
