import type { ReactNode } from 'react'

import { Space, Typography } from 'antd'
import { styled } from 'styled-components'

import { colors } from 'app/theme'

const { Text } = Typography

const StyledSpace = styled(Space)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed ${colors.border};
  margin-bottom: 16px;
  padding-bottom: 5px;
`

const SectionLabel = styled(Text)`
  display: block;
  padding: 4px 0;
`

interface TaskSectionProps {
  title: ReactNode
  actions?: ReactNode
  children?: ReactNode
}

export const TaskSection = ({
  actions,
  title,
  children,
  ...rest
}: TaskSectionProps) => (
  <>
    <StyledSpace {...rest}>
      <SectionLabel strong data-dd-privacy="allow">
        {title}
      </SectionLabel>
      <Space>{actions}</Space>
    </StyledSpace>
    {children}
  </>
)
