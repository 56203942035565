import { useCallback, useState, type ChangeEvent } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { baseColors } from '@dialogue/basics'
import type { MemberDocument } from '@dialogue/document-center'
import {
  Alert,
  Empty,
  Input,
  List,
  Modal,
  Pagination,
  Space,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useGetPaginatedMemberDocumentsQuery } from 'app/redux/api/document-center/member-documents'

const StyledModal = styled(Modal)`
  top: 50px;
  .ant-modal-header {
    border: none;
  }
  .ant-modal-body {
    padding: 0 0 20px 0;
  }

  .ant-modal-title {
    font-size: 22px;
  }
`

const StyledSearchWrapper = styled.div`
  margin: 1.6rem 2.4rem 0;
`

const StyledList = styled(List)`
  max-height: 550px;
  min-height: 300px;
  width: 100%;
  padding: 0 2.4rem 1rem;
  overflow: auto;
`

const StyledItem = styled(List.Item)`
  cursor: pointer;
  padding-left: 4px;
  &:hover {
    background: ${baseColors.smokeLight};
  }
`

interface DocumentItemProps {
  document: MemberDocument
  onClick: (id: string) => void
}

const DocumentItem = ({ document, onClick }: DocumentItemProps) => {
  const { name, id } = document

  const handleClick = useCallback(() => onClick(id), [id, onClick])

  return (
    <StyledItem
      onClick={handleClick}
      data-cy="document-item"
      data-dd-action-name="document-picker:select:option"
    >
      <Typography.Text strong>{name}</Typography.Text>
    </StyledItem>
  )
}

interface DocumentListProps {
  loading: boolean
  documents: MemberDocument[]
  onSelect: (id: string) => void
}

const DocumentList = ({ documents, onSelect, loading }: DocumentListProps) => {
  return (
    <StyledList loading={loading}>
      {documents.map((document) => (
        <DocumentItem
          document={document}
          key={document.id}
          onClick={onSelect}
        />
      ))}
      {!documents.length && <Empty description={false} />}
    </StyledList>
  )
}

interface DocumentSelectModalProps {
  open: boolean
  onSelect: (id: string) => void
  onCancel: () => void
  memberId: number
}

const PAGE_SIZE = 50

export const DocumentSelectModal = ({
  open,
  memberId,
  onSelect,
  onCancel,
}: DocumentSelectModalProps) => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [page, setPage] = useState(1)

  const {
    data: documents,
    total: totalItems,
    isLoading,
    isError,
  } = useGetPaginatedMemberDocumentsQuery(
    {
      memberId,
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (page - 1),
      name: searchTerm,
    },
    {
      skip: !memberId,
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data: data?.data || [],
        total: data?.meta?.total_items,
      }),
    },
  )

  console.log(totalItems)
  const handleSearch = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(target?.value)
    },
    [],
  )

  return (
    <StyledModal
      open={open}
      onCancel={onCancel}
      footer={false}
      title={t('documents.actions.selectDocument')}
    >
      <Space direction="vertical" css={'width: 100%'}>
        <StyledSearchWrapper>
          <Input
            allowClear
            autoFocus
            placeholder="Search"
            onChange={handleSearch}
            addonBefore={<SearchOutlined />}
            value={searchTerm}
            css={`
              padding-bottom: 10px;
            `}
          />
        </StyledSearchWrapper>
        {isError && (
          <Alert
            message={t('documents.errorFetchingDocuments')}
            type="error"
            data-dd-privacy="allow"
          />
        )}
        <DocumentList
          documents={documents}
          onSelect={onSelect}
          loading={isLoading}
        />
        <Pagination
          css={'text-align: center;'}
          current={page}
          total={totalItems}
          onChange={setPage}
          simple
          showSizeChanger={false}
          pageSize={PAGE_SIZE}
        />
      </Space>
    </StyledModal>
  )
}
