import type {
  ItemPharmacy,
  PharmaciesApiGetPharmacyRequest,
  PharmaciesApiUpdatePharmacyRequest,
} from '@dialogue/coredata'

import { emergencyRoomApi, Tags } from './api'

export const pharmaciesApi = emergencyRoomApi.injectEndpoints({
  endpoints: (build) => ({
    getPharmacy: build.query({
      query: ({ pharmacyId }: PharmaciesApiGetPharmacyRequest) => ({
        url: `/v1/pharmacies/${pharmacyId}`,
      }),
      transformResponse: (response: ItemPharmacy) => response.data,
      providesTags: (_result, _error, { pharmacyId }) => [
        { type: Tags.Pharmacies, id: pharmacyId },
      ],
    }),
    updatePharmacy: build.mutation<
      ItemPharmacy,
      PharmaciesApiUpdatePharmacyRequest
    >({
      query: ({ pharmacyId, updatePharmacy }) => ({
        url: `/v1/pharmacies/${pharmacyId}`,
        method: 'PATCH',
        body: updatePharmacy,
      }),
      invalidatesTags: (_result, _error, { pharmacyId }) => [
        { type: Tags.Pharmacies, id: pharmacyId },
        Tags.MemberPharmacy,
      ],
    }),
  }),
})

export const { useGetPharmacyQuery, useUpdatePharmacyMutation } = pharmaciesApi
