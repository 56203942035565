import { useCallback, useMemo, useState } from 'react'

import { CaretDownOutlined } from '@ant-design/icons'
import { IncomingFaxDocumentStatus } from '@dialogue/document-center'
import { Button, Dropdown, Tooltip } from 'antd'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useTranslation } from 'react-i18next'

import { InfoModal } from 'app/components/modals'
import { useViewerInstance } from 'app/containers/documents/document-details/viewer-context'
import { useVariation } from 'app/hooks'
import {
  DocumentKind,
  isFaxedDocument,
  type ViewerDocument,
} from 'app/redux/documents/viewer'
import { Flags } from 'app/services/feature-flags'

import { useUnprocessFax } from '../document-details/info-panel/hooks'

import { DOCUMENT_ACTION } from './types'

interface Props {
  document: ViewerDocument
  onActionClick?: (action: DOCUMENT_ACTION | null) => void
}

export const ModifyDocumentActions = ({ document, onActionClick }: Props) => {
  const { t } = useTranslation()
  const [unprocessFaxModalOpen, setUnprocessFaxModalOpen] =
    useState<boolean>(false)
  const [unprocessFax, { isLoading: isUnprocessing }] = useUnprocessFax()

  const toggleUnprocessFaxModal = useCallback(() => {
    setUnprocessFaxModalOpen((open) => !open)
  }, [])

  const isReceived =
    document?.kind === DocumentKind.INCOMING_FAX_DOCUMENT &&
    document.status === IncomingFaxDocumentStatus.received

  const enableRedactFaxes = useVariation(Flags.enableRedactFaxes, true)
  const enableUnprocessFax = useVariation(Flags.enableUnprocessFax, true)

  const {
    disableViewerRedact,
    enableViewerRedact,
    isViewerRedacting,
    loadedDocumentId,
  } = useViewerInstance()

  const handleSplitDocument = useCallback(() => {
    if (isViewerRedacting) {
      disableViewerRedact()
    }

    onActionClick?.(DOCUMENT_ACTION.SPLIT)
  }, [disableViewerRedact, isViewerRedacting, onActionClick])

  const handleRedactDocument = useCallback(() => {
    // Clear any existing action
    onActionClick?.(null)

    enableViewerRedact()
  }, [enableViewerRedact, onActionClick])

  const handleUnprocessFax = useCallback(async () => {
    await unprocessFax(document?.id)
    toggleUnprocessFaxModal()
  }, [document?.id, toggleUnprocessFaxModal, unprocessFax])

  const actionItems: ItemType[] = useMemo(() => {
    const items: ItemType[] = []

    if (document?.kind === DocumentKind.INCOMING_FAX_DOCUMENT) {
      items.push({
        key: 'split-document',
        label: (
          <Tooltip
            title={!isReceived ? t('documents.actions.actionDisabled') : null}
          >
            {t('documents.actions.splitDocument')}
          </Tooltip>
        ),
        onClick: handleSplitDocument,
        disabled: !isReceived,
        // @ts-expect-error ad-hoc properties are passed down to the DOM
        'data-testid': 'split-document',
      })

      if (enableRedactFaxes) {
        items.push({
          key: 'redact-document',
          label: (
            <Tooltip
              title={!isReceived ? t('documents.actions.actionDisabled') : null}
            >
              {t('documents.actions.redactDocument')}
            </Tooltip>
          ),
          onClick: handleRedactDocument,
          disabled:
            document?.kind === DocumentKind.INCOMING_FAX_DOCUMENT &&
            !isReceived,
          // @ts-expect-error ad-hoc properties are passed down to the DOM
          'data-testid': 'redact-document',
        })
      }
    }

    if (
      isFaxedDocument(document) &&
      document.status === IncomingFaxDocumentStatus.processed &&
      enableUnprocessFax
    ) {
      items.push({
        key: 'unprocess-document',
        label: t('documents.actions.unprocessDocument', 'Undo fax processing'),
        onClick: () => toggleUnprocessFaxModal(),
        // @ts-expect-error ad-hoc properties are passed down to the DOM
        'data-testid': 'unprocess-document',
      })
    }

    return items
  }, [
    document,
    enableRedactFaxes,
    enableUnprocessFax,
    handleRedactDocument,
    handleSplitDocument,
    isReceived,
    t,
    toggleUnprocessFaxModal,
  ])

  return (
    actionItems.length > 0 && (
      <>
        <Dropdown
          disabled={!loadedDocumentId}
          menu={{
            items: actionItems,
          }}
        >
          <Button
            size="small"
            type="primary"
            ghost
            data-testid="actions-dropdown"
          >
            {t('documents.actions.actionsDropdown')}
            <CaretDownOutlined />
          </Button>
        </Dropdown>

        <InfoModal
          open={unprocessFaxModalOpen}
          onCancel={toggleUnprocessFaxModal}
          cancelButtonProps={{
            'data-testid': 'unprocess-fax-cancel',
          }}
          confirmLoading={isUnprocessing}
          onOk={handleUnprocessFax}
          okButtonProps={{
            'data-testid': 'unprocess-fax-confirm',
          }}
          title={t('documents.unprocess.modal.title', 'Title')}
          message={t('documents.unprocess.modal.message', 'Message')}
        />
      </>
    )
  )
}
