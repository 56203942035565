import React, { type ReactNode, useCallback } from 'react'

import { MedicineBoxOutlined } from '@ant-design/icons'
import { Form, Modal, Typography, type ModalProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { colors } from 'app/theme'

interface Props extends ModalProps {
  initialValues?: Partial<FormValues>
  isLoading?: boolean
  onSubmit: (values: FormValues) => void
}

export interface FormValues {
  name: string
  fax_number: string
  phone_number?: string
  street_number: string
  street_number2?: string
  locality: string
  admin_area_iso_code: string
  postal_code: string
  country: string
}

const TitleIcon = styled(MedicineBoxOutlined)`
  color: ${colors.primary};
  padding-right: 8px;
`

const PharmacyModal = ({
  title,
  isLoading,
  onCancel,
  onSubmit,
  initialValues,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<FormValues>()

  const handleModalRender = useCallback(
    (dom: ReactNode) => (
      <Form
        layout="vertical"
        form={form}
        name="pharmacy-form"
        initialValues={initialValues}
        requiredMark="optional"
        onFinish={onSubmit}
        validateMessages={{ required: t('form.validation.required') }}
      >
        {dom}
      </Form>
    ),
    [initialValues, form, onSubmit, t],
  )

  const handleCancel = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      form.resetFields()
      onCancel?.(e)
    },
    [onCancel, form],
  )

  return (
    <Modal
      title={
        <>
          <TitleIcon />
          <Typography.Text>{title}</Typography.Text>
        </>
      }
      okButtonProps={{
        autoFocus: true,
        htmlType: 'submit',
        loading: isLoading,
      }}
      onCancel={handleCancel}
      destroyOnClose
      modalRender={handleModalRender}
      {...rest}
    />
  )
}

export default PharmacyModal
