import { useCallback } from 'react'

import { ExportOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { NavButton, type NavButtonProps } from 'app/components/nav-button'
import deviceInfo from 'app/services/device-info'

export interface EntityLinkProps
  extends Omit<NavButtonProps, 'size' | 'target' | 'type'> {
  [key: `data-${string}`]: string
  /**
   * The name of the action represented by this link.
   * Refer to [our documentation](https://docs.dev.dialoguecorp.com/care-platform/playbooks/analytics/#recording-user-clicks-with-a-custom-name) for conventions and usage.
   */
  ddActionName: `${string}:goto:${string}`
  /**
   * Optional callback to execute when the user clicks on this link.
   * This can be used to prevent default event handler execution, for example.
   */
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  /**
   * Determines if the link should open a new tab.
   * This applies only to the Web app and will be ignored in Electron.
   * Note that this will prevent event propagation, so you do not need to do it yourself in `onChange` if you use this parameter.
   * Defaults to `false`.
   */
  openInNewTab?: boolean
  /**
   * Determines if the link text should be rendered or not.
   * This can be used to have a more compact display when there is less available space.
   * Defaults to `true`.
   */
  showText?: boolean
}

/**
 * Generic component to render a link to a given entity (e.g. member profile, episode, note, etc).
 * Do not import this component directly; instead, create a per-route component that consumes this one, centralizes any business logic, and enforces conventions (e.g. action names).
 * See `EpisodeEntityLink` for a full example.
 */
export const EntityLink = ({
  ddActionName,
  onClick,
  openInNewTab = false,
  showText = true,
  ...navButtonProps
}: EntityLinkProps) => {
  const { t: tCommon } = useTranslation('common')

  const linkTitle = tCommon('button.view')
  const shouldOpenInNewTab = openInNewTab && !deviceInfo.isElectron()

  const handleClick = useCallback<NonNullable<EntityLinkProps['onClick']>>(
    (event) => {
      if (shouldOpenInNewTab) {
        // Prevent the current tab from navigating
        event.stopPropagation()
      }

      onClick?.(event)
    },
    [onClick, shouldOpenInNewTab],
  )

  return (
    <Tooltip
      destroyTooltipOnHide
      title={
        shouldOpenInNewTab ? null : (
          <span data-dd-privacy="allow">{tCommon('navigationWarning')}</span>
        )
      }
    >
      <NavButton
        {...navButtonProps}
        aria-label={linkTitle}
        // Ensure we never disable navigation links, even when the surrounding context (e.g. form) is disabled
        disabled={false}
        icon={<ExportOutlined />}
        onClick={handleClick}
        size="small"
        target={shouldOpenInNewTab ? '_blank' : undefined}
        type="link"
        data-dd-action-name={ddActionName}
        data-dd-privacy="allow"
      >
        {showText && (
          <span style={{ textDecoration: 'underline' }}>{linkTitle}</span>
        )}
      </NavButton>
    </Tooltip>
  )
}
