// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import type { EpisodeMetaAction } from 'app/redux/episode-meta/reducer'
import { EpisodeMetaTypes } from 'app/redux/episode-meta/types'

import type * as actions from './actions'
import { type PatientsState, PatientsTypes } from './types'

export const INITIAL_STATE: PatientsState = {
  profiles: {},
  locations: {},
  episodes: {},
  episodesStatuses: {},
  fetchingProfile: false,
  errorProfile: null,
  fetchingLocation: false,
  errorLocation: null,
  updatingProfile: false,
  errorUpdatingProfile: null,
}

export type PatientsAction = ActionType<typeof actions>

export const reducer: Reducer<
  typeof INITIAL_STATE,
  | PatientsAction
  | Extract<
      EpisodeMetaAction,
      | { type: EpisodeMetaTypes.UPDATE_EPISODE_TITLE }
      | { type: EpisodeMetaTypes.UPDATE_EPISODE_PROPERTIES }
    >
> = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EpisodeMetaTypes.UPDATE_EPISODE_TITLE:
        if (
          draft.episodes[action.payload.patientId]?.data[
            action.payload.episodeId
          ]
        ) {
          draft.episodes[action.payload.patientId].data[
            action.payload.episodeId
          ].title = action.payload.title
        }
        break
      case EpisodeMetaTypes.UPDATE_EPISODE_PROPERTIES:
        if (
          draft.episodes[action.payload.patientId]?.data[
            action.payload.episodeId
          ]
        ) {
          draft.episodes[action.payload.patientId].data[
            action.payload.episodeId
          ] = {
            ...draft.episodes[action.payload.patientId]?.data[
              action.payload.episodeId
            ],
            ...action.payload.properties,
          }
        }
        break
      case PatientsTypes.REQUEST_EPISODES:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetching: true,
          error: null,
        }
        break
      case PatientsTypes.REQUEST_EPISODES_SUCCESS:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetching: false,
          error: null,
        }

        draft.episodes[action.payload.patientId] = {
          ...draft.episodes[action.payload.patientId],
          activeEpisodesCount: action.payload.activeEpisodesCount,
          totalItems: action.payload.totalItems,
          data: { ...draft.episodes[action.payload.patientId]?.data },
          order: [],
        }

        for (const episode of action.payload.episodes) {
          draft.episodes[action.payload.patientId].data[episode.id] = episode
          draft.episodes[action.payload.patientId].order.push(episode.id)
        }
        break
      case PatientsTypes.REQUEST_EPISODES_FAILURE:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetching: false,
          error: action.payload.error,
        }
        break
      case PatientsTypes.REQUEST_MOST_RECENT_EPISODES:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetchingMostRecentEpisodes: true,
          errorFetchingMostRecentEpisodes: null,
        }
        break
      case PatientsTypes.REQUEST_MOST_RECENT_EPISODES_SUCCESS:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetchingMostRecentEpisodes: false,
          errorFetchingMostRecentEpisodes: null,
        }

        draft.episodes[action.payload.patientId] = {
          ...draft.episodes[action.payload.patientId],
          data: {
            ...draft.episodes[action.payload.patientId]?.data,
            ...action.payload.episodeMapping,
          },
          mostRecentEpisodeIds: action.payload.episodeIds,
        }
        break
      case PatientsTypes.REQUEST_MOST_RECENT_EPISODES_FAILURE:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetchingMostRecentEpisodes: false,
          errorFetchingMostRecentEpisodes: action.payload.error,
        }
        break
      case PatientsTypes.REQUEST_EPISODE:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetchingEpisodesByIds: true,
          errorFetchingEpisodesByIds: null,
        }
        break
      case PatientsTypes.REQUEST_EPISODE_SUCCESS:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetchingEpisodesByIds: false,
          errorFetchingEpisodesByIds: null,
        }

        draft.episodes[action.payload.patientId] = {
          ...draft.episodes[action.payload.patientId],
          data: {
            ...draft.episodes[action.payload.patientId]?.data,
            [action.payload.episodeId]: action.payload.episode,
          },
        }
        break
      case PatientsTypes.REQUEST_EPISODE_FAILURE:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetchingEpisodesByIds: false,
          errorFetchingEpisodesByIds: action.payload.error,
        }
        break
      case PatientsTypes.REQUEST_EPISODES_BY_IDS:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetchingEpisodesByIds: true,
          errorFetchingEpisodesByIds: null,
        }
        break
      case PatientsTypes.REQUEST_EPISODES_BY_IDS_SUCCESS:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetchingEpisodesByIds: false,
          errorFetchingEpisodesByIds: null,
        }

        draft.episodes[action.payload.patientId] = {
          ...draft.episodes[action.payload.patientId],
          data: {
            ...draft.episodes[action.payload.patientId]?.data,
            ...action.payload.episodes,
          },
        }
        break
      case PatientsTypes.REQUEST_EPISODES_BY_IDS_FAILURE:
        draft.episodesStatuses[action.payload.patientId] = {
          ...draft.episodesStatuses[action.payload.patientId],
          fetchingEpisodesByIds: false,
          errorFetchingEpisodesByIds: action.payload.error,
        }
        break
      case PatientsTypes.CLEAR_EPISODES:
        draft.episodesStatuses[action.payload] = null
        draft.episodes[action.payload] = null
        break
      case PatientsTypes.REQUEST_PROFILE:
        if (action.payload) {
          draft.fetchingProfile = true
          draft.errorProfile = null
          draft.profiles[action.payload.patientId] = {
            ...draft.profiles[action.payload.patientId],
            id: action.payload.patientId,
            fetching: true,
            error: null,
          }
        }
        break
      case PatientsTypes.REQUEST_PROFILE_SUCCESS:
        draft.fetchingProfile = false
        draft.errorProfile = null
        draft.profiles[action.payload.patientId] = {
          ...draft.profiles[action.payload.patientId],
          // We dump all profile attributes directly into the slice.
          // This isn't ideal, but is only temporary until we've migrated the saga to hooks.
          // TODO: migrate this slice into a hook with fetching from RTKq
          ...action.payload.patientProfile,
          id: action.payload.patientId,
          fetching: false,
          eligible_services: action.payload.eligible_services,
          family: action.payload.family,
        }
        break
      case PatientsTypes.REQUEST_PROFILE_FAILURE:
        draft.fetchingProfile = false
        draft.errorProfile = action.payload.error
        draft.profiles[action.payload.patientId] = {
          ...draft.profiles[action.payload.patientId],
          fetching: false,
          error: action.payload.error,
        }
        break
      case PatientsTypes.REQUEST_ID_CARD:
        draft.profiles[action.payload.patientId] = {
          ...draft.profiles[action.payload.patientId],
          fetchingIdCard: true,
          idCardError: null,
        }
        break
      case PatientsTypes.REQUEST_ID_CARD_SUCCESS:
        draft.profiles[action.payload.patientId] = {
          ...draft.profiles[action.payload.patientId],
          id_card_url: action.payload.patientIdCardUrl,
          fetchingIdCard: false,
          idCardError: null,
        }
        break
      case PatientsTypes.REQUEST_ID_CARD_FAILURE:
        draft.profiles[action.payload.patientId] = {
          ...draft.profiles[action.payload.patientId],
          fetchingIdCard: false,
          idCardError: action.payload.error,
        }
        break
      case PatientsTypes.SET_ACTIVE_FAMILY_MEMBER:
        draft.profiles[action.payload.patientId] = {
          ...draft.profiles[action.payload.patientId],
          activeFamilyMemberId: action.payload.activeFamilyMemberId,
        }
        break
      case PatientsTypes.REQUEST_LOCATION:
        draft.fetchingLocation = true
        draft.errorLocation = null
        draft.locations[action.payload.patientId] = {
          ...draft.locations[action.payload.patientId],
          error: null,
          fetching: true,
        }
        break
      case PatientsTypes.REQUEST_LOCATION_SUCCESS:
        draft.fetchingLocation = false
        draft.errorLocation = null
        draft.locations[action.payload.patientId] = {
          ...draft.locations[action.payload.patientId],
          ...action.payload.patientLocationData,
          display_text:
            (action.payload.patientLocationData &&
              action.payload.patientLocationData.display_text) ||
            'Unknown location',
          error: null,
          fetching: false,
        }
        break
      case PatientsTypes.REQUEST_LOCATION_FAILURE:
        draft.fetchingLocation = false
        draft.errorLocation = action.payload.error
        draft.locations[action.payload.patientId] = {
          ...draft.locations[action.payload.patientId],
          display_text: 'Unknown location',
          error: action.payload.error,
          fetching: false,
        }
        break
      case PatientsTypes.UPDATE_PROFILE_REQUEST:
        draft.updatingProfile = true
        draft.errorUpdatingProfile = null
        draft.profiles[action.payload.patientId] = {
          ...draft.profiles[action.payload.patientId],
          updating: true,
          error: null,
        }
        break
      case PatientsTypes.UPDATE_PROFILE_SUCCESS:
        draft.updatingProfile = false
        draft.errorUpdatingProfile = null
        draft.profiles[action.payload.patientId] = {
          ...draft.profiles[action.payload.patientId],
          // We dump all profile attributes directly into the slice.
          // This isn't ideal, but is only temporary until we've migrated the saga to hooks.
          // TODO: migrate this operation to RTKq
          ...action.payload.patientProfile,
          updating: false,
          error: null,
        }
        break
      case PatientsTypes.UPDATE_PROFILE_FAILURE:
        draft.updatingProfile = false
        draft.errorUpdatingProfile = action.payload.error
        draft.profiles[action.payload.patientId] = {
          ...draft.profiles[action.payload.patientId],
          updating: false,
          error: action.payload.error,
        }
        break
    }
  })

export default reducer
