import type { ReactNode } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Modal,
  Space,
  Typography,
  type ButtonProps,
  type ModalProps,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { colors } from 'app/theme'

interface TestableButtonProps extends ButtonProps {
  'data-testid'?: string
}

interface InfoModalProps extends ModalProps {
  message: string | ReactNode
  /**
   * Bad Antd type does not currently allow data-testid
   */
  okButtonProps?: TestableButtonProps
  /**
   * Bad Antd type does not currently allow data-testid
   */
  cancelButtonProps?: TestableButtonProps
}

export function InfoModal({ message, title, ...rest }: InfoModalProps) {
  const { t: tCommon } = useTranslation('common')

  return (
    <StyledModal
      title={null}
      okText={tCommon('button.confirm')}
      cancelButtonProps={{
        type: 'text',
      }}
      {...rest}
    >
      <ModalContainer size={16} align="start">
        <ExclamationCircleOutlined
          css={`
            font-size: 22px;
            color: ${colors.primary};
          `}
        />
        <Space direction="vertical" size={8}>
          <ModalTitle level={5}>{title}</ModalTitle>
          <ModalMessage>{message}</ModalMessage>
        </Space>
      </ModalContainer>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 32px 32px 24px 24px;
    max-width: 433px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 0;

    .ant-btn {
      border-radius: 2px;

      &:last-child {
        margin-left: 16px;
      }
    }
  }
`

const ModalContainer = styled(Space)`
  margin-bottom: 24px;
`

const ModalTitle = styled(Typography.Title)`
  &&.ant-typography {
    font-weight: 500;
  }
`

const ModalMessage = styled(Typography.Text)`
  &&.ant-typography {
    color: ${colors.onSurfaceLight};
    font-weight: 400;
  }
`
