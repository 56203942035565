// @ts-strict-ignore
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  type MutableRefObject,
} from 'react'

import WebViewer, {
  type WebViewerInstance,
  type WebViewerOptions,
  type UI as WebViewerUI,
} from '@pdftron/webviewer'
import { useTranslation } from 'react-i18next'
import { useBlocker } from 'react-router-dom'

import config from 'app/config'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectUserProfile } from 'app/redux/authentification/selectors'
import { useLazyDocumentDownloadUrl } from 'app/redux/documents/hooks'
import { DocumentSource } from 'app/redux/documents/viewer'
import { track } from 'app/services/snowplow-analytics'

import { webviewerPath } from './viewer-config'
import { MODAL_TYPE, useWebViewerContext } from './viewer-context'

const webViewerConfig: WebViewerOptions = {
  path: webviewerPath,
  licenseKey: config.PDF_APRYSE_LICENSE,
  fullAPI: true,
  isAdminUser: true,
  isReadOnly: true,
  enableAnnotations: true, // if we disable these, then they also become invisible when the doc is not in edit mode.
}

interface Props {
  url?: string
  blob?: Blob
  memberId?: number
  documentId?: string
  documentName?: string
  documentSource?: DocumentSource
  onDocumentLoaded?: () => void
}

const PDF_APRYSE_SOURCE = 'pdf-apryse'

const getContainerWindow = (element: HTMLDivElement | null) =>
  element?.ownerDocument.defaultView

/**
 * Hack to allow the PDFJS Express viewer to communicate
 * with a viewer rendered in an external window.
 *
 * See https://pdfjs.community/t/rendering-viewer-in-a-new-controlled-window/2291/11?u=victor.repkow
 * for more context and information.
 */
const useWindowIpcSync = (
  viewerElementRef: MutableRefObject<HTMLDivElement | null>,
) => {
  const iframeRef = useRef<Window | null>()

  useEffect(() => {
    const containerWindow = getContainerWindow(viewerElementRef.current)

    const isInPopup = containerWindow && containerWindow !== window

    if (!isInPopup) {
      return
    }

    const popupWindowHandler = (event: MessageEvent) => {
      if (event.origin === window.origin) {
        // @ts-expect-error instance is injected by pdfJSExpress
        const isIframe = !!event.source?.instance
        if (isIframe) {
          // @ts-expect-error trust me, it's an iframe
          iframeRef.current = event.source
          let data = event.data

          // allows deduping
          if (typeof data === 'object') {
            data = { ...data, _source: PDF_APRYSE_SOURCE }
          }

          window.postMessage(data, event.origin)
        }
      }
    }

    const mainWindowHandler = (event: MessageEvent) => {
      if (event.origin === window.origin) {
        // only send events that didn't originate from
        // the forwarded popout window events
        if (event.data?._source !== PDF_APRYSE_SOURCE) {
          iframeRef.current?.postMessage(event.data, event.origin)
        }
      }
    }

    containerWindow.addEventListener('message', popupWindowHandler)
    window.addEventListener('message', mainWindowHandler)

    return () => {
      containerWindow.removeEventListener('message', popupWindowHandler)
      window.removeEventListener('message', mainWindowHandler)
    }
  }, [viewerElementRef])
}

const implementCustomStyles = (instance: WebViewerInstance) => {
  const { Annotations } = instance.Core

  // @ts-expect-error getCustomStyles exists but the library doesn't define it properly
  // https://docs.apryse.com/documentation/web/guides/form-field-styling/
  Annotations.WidgetAnnotation.getCustomStyles = (
    widget: typeof Annotations.WidgetAnnotation,
  ) => {
    // @ts-expect-error fieldFlags should be defined, still going for graceful
    // degradation in case it's not, and will update when types are fixed.
    if (widget.fieldFlags?.get('ReadOnly')) {
      // If fields are read-only, don't apply any custom styles, ensures a more
      // readable document when not editing and to give more predictable results
      // when printing from the viewer.
      return {}
    }

    if (widget instanceof Annotations.TextWidgetAnnotation) {
      return {
        backgroundColor: '#CCEAFD',
        color: '#272D31',
        'border-radius': 0,
        'padding-left': '2px',
        'padding-right': '2px',
      }
    }

    if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
      return {
        backgroundColor: '#CCEAFD',
      }
    }

    if (widget instanceof Annotations.RadioButtonWidgetAnnotation) {
      return {
        backgroundColor: '#CCEAFD',
        'border-radius': 0,
      }
    }
  }
}

export const DocumentViewer = ({
  url,
  blob,
  memberId,
  documentId,
  documentName,
  documentSource,
  onDocumentLoaded,
  ...rest
}: Props) => {
  const viewerElementRef = useRef<HTMLDivElement>(null)
  const {
    isViewerEditing,
    isViewerRedacting,
    loadedDocumentId,
    viewerInstance,
    setLoadedDocumentId,
    setModal,
    setViewerInstance,
  } = useWebViewerContext()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const userProfile = useAppSelector(selectUserProfile)

  // Block navigation if the document is in edit mode
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      (isViewerEditing || isViewerRedacting),
  )

  const annotationUser = useMemo((): string => {
    if (!userProfile) {
      return t('documents.annotations.guest')
    }

    const { givenName, familyName, mainSpecialization, id } = userProfile

    const name = givenName
      ? `${givenName} ${familyName}`
      : t('documents.annotations.noName')
    const details =
      mainSpecialization &&
      mainSpecialization !== t('documents.annotations.roleNotSpecified')
        ? `${mainSpecialization} (${id})`
        : `(${id})`

    return `${name} - ${details}`
  }, [userProfile, t])

  const didInit = useRef(false)

  const [fetchDocumentDownloadUrl, { isError, error }] =
    useLazyDocumentDownloadUrl(documentSource)

  const loadDocumentIntoViewer = useCallback(
    async (blob?: Blob) => {
      if (!viewerInstance) {
        return
      }

      const loadOptions: WebViewerUI.loadDocumentOptions = {
        filename: documentName,
      }

      if (blob) {
        viewerInstance.UI.loadDocument(blob, loadOptions)
        return
      }

      try {
        const resolvedUrl = url?.startsWith(`${config.DOCUMENT_CENTER_URL}`)
          ? await fetchDocumentDownloadUrl({ documentId, memberId }).unwrap()
          : url

        viewerInstance.UI.loadDocument(resolvedUrl, loadOptions)
      } catch (error) {
        console.error('Error occurred while resolving document URL:', error)
        viewerInstance.UI.displayErrorMessage(
          t('documents.errorLoadingDocument'),
        )
      }
    },
    [
      t,
      viewerInstance,
      documentName,
      url,
      fetchDocumentDownloadUrl,
      documentId,
      memberId,
    ],
  )

  useEffect(() => {
    // 🟡 This is required, the effect will rerun when the accessToken is refreshed
    // and loadDocumentIntoViewer is redefined. New blobs should always be reloaded
    if (loadedDocumentId === documentId && !blob) {
      return
    }

    loadDocumentIntoViewer(
      documentSource === DocumentSource.OUTGOING_FAX_DOCUMENT
        ? blob
        : undefined,
    )
  }, [
    blob,
    documentId,
    documentSource,
    loadDocumentIntoViewer,
    loadedDocumentId,
    url,
  ])

  useEffect(() => {
    // nb: init ref to prevent double effects run in strict mode
    // and ensure we never init twice whatever happens since that's not allowed
    if (!didInit.current) {
      didInit.current = true
      WebViewer(
        { ...webViewerConfig, annotationUser },
        viewerElementRef.current,
      ).then((instance: WebViewerInstance) => {
        const { Annotations, annotationManager } = instance.Core
        const { UI } = instance
        const { DatePickerWidgetAnnotation } = Annotations

        setViewerInstance(instance)
        implementCustomStyles(instance)

        // Datepicker config, first day of the week is Sunday to match rest of
        // datepickers in the app.
        DatePickerWidgetAnnotation.datePickerOptions.firstDay = 0

        // ------ ONE TIME SETUP REQUIRED STARTED ------ //
        // 1. All form fields explicitly disabled
        // https://docs.apryse.com/documentation/salesforce/guides/forms/modify-fields/
        annotationManager.addEventListener(
          'annotationChanged',
          (annotations: (typeof Annotations.WidgetAnnotation)[]) => {
            annotations.forEach((annotation) => {
              if (annotation instanceof Annotations.WidgetAnnotation) {
                annotation.fieldFlags.set('ReadOnly', true)
              }
            })
          },
        )

        // 2. Hide unwanted toolbars
        UI.disableElements([
          'toolbarGroup-Shapes',
          'toolbarGroup-Edit',
          'toolbarGroup-Insert',
          'toolbarGroup-Measure',
          'toolbarGroup-Forms',
          'toolbarGroup-Annotate',
          'textSignaturePanelButton',
          'textSignaturePanel',
          'imageSignaturePanelButton',
          'imageSignaturePanel',
        ])

        // useEmbeddedPrint is Only applicable to Chrome. But this will produce
        // more consistent and predictible results when printing.
        // eslint-disable-next-line react-hooks/rules-of-hooks -- this is not a hook.
        UI.useEmbeddedPrint(true)

        // @ts-expect-error willUseEmbeddedPrinting is defined with void return type...
        const printEnabled: boolean = UI.willUseEmbeddedPrinting()

        // Custom print method, bypassing Apryse's print dialog which offers
        // options we don't care about and are useless (low quality print). From
        // Apryse documentation, after quality of 5 there is diminishing
        // returns. We also set the default print options to include annotations.
        UI.updateElement('printButton', {
          // disable print button for non-embedded printing, it produces
          // inconsistent results and there are other ways of printing for users
          // not using Chrome as recommended.
          disabled: !printEnabled,
          title: !printEnabled
            ? t('documents.actions.printingNotSupported')
            : '',
          onClick: () => {
            UI.setPrintQuality(5)
            UI.setDefaultPrintOptions({
              includeAnnotations: true,
            })
            UI.printInBackground()
          },
        })

        instance.UI.setHeaderItems(function (header: any) {
          // 3. Move specific Annotate tools into FillAndSign toolbar
          header.getHeader('toolbarGroup-FillAndSign').push({
            type: 'toolGroupButton',
            toolGroup: 'rectangleTools',
            dataElement: 'shapeToolGroupButton',
            title: 'annotation.rectangle',
          })
          header.getHeader('toolbarGroup-FillAndSign').push({
            type: 'toolGroupButton',
            toolGroup: 'freeHandHighlightTools',
            dataElement: 'freeHandHighlightToolGroupButton',
            title: 'annotation.freeHandHighlight',
          })

          // 4. Remove unwanted tools from FillAndSign toolbar
          header
            .getHeader('toolbarGroup-FillAndSign')
            .delete('rubberStampToolGroupButton')
          header
            .getHeader('toolbarGroup-FillAndSign')
            .delete('checkStampToolButton')
          header
            .getHeader('toolbarGroup-FillAndSign')
            .delete('dotStampToolButton')
          header
            .getHeader('toolbarGroup-FillAndSign')
            .delete('dateFreeTextToolButton')
        })
        // ------ ONE TIME SETUP REQUIRED COMPLETE ------ //
      })
    }
  }, [setViewerInstance, annotationUser, dispatch, documentName, setModal, t])

  useEffect(() => {
    if (!viewerInstance) {
      return
    }

    const listener = () => {
      track('doc_center_file_viewer_actions', {
        action_name: 'Download',
        document_id: documentId,
      })
    }

    viewerInstance.UI.addEventListener(
      viewerInstance.UI.Events.FILE_DOWNLOADED,
      listener,
    )

    return () => {
      viewerInstance.UI.removeEventListener(
        viewerInstance.UI.Events.FILE_DOWNLOADED,
        listener,
      )
    }
  }, [viewerInstance, documentId])

  useEffect(() => {
    if (viewerInstance && isError) {
      console.warn('There was an error getting the file download.', error)

      // https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
      if ('status' in error) {
        const parsedError =
          'error' in error ? error.error : JSON.stringify(error.data)
        const errorMessage = `There was an error getting the file download. Details: ${parsedError}`
        viewerInstance.UI.displayErrorMessage(errorMessage)
      }
    }
  }, [error, isError, viewerInstance])

  useEffect(() => {
    if (!viewerInstance) {
      return
    }
    viewerInstance.Core.documentViewer.addEventListener(
      'documentLoaded.cpviewer',
      () => {
        if (onDocumentLoaded) onDocumentLoaded()

        viewerInstance.UI.setFitMode(viewerInstance.UI.FitMode.FitWidth)
      },
    )

    // annotationsLoaded is the safest event to use as final loading event since
    // we are usually working heavily with annotations for form filling.
    // see https://docs.apryse.com/documentation/web/guides/events/loading-events/
    viewerInstance.Core.documentViewer.addEventListener(
      'annotationsLoaded.cpviewer',
      () => {
        setLoadedDocumentId(documentId)
      },
    )

    return () => {
      viewerInstance.Core.documentViewer.removeEventListener(
        'annotationsLoaded.cpviewer',
      )
      viewerInstance.Core.documentViewer.removeEventListener(
        'documentLoaded.cpviewer',
      )
    }
  }, [viewerInstance, onDocumentLoaded, setLoadedDocumentId, documentId])

  useEffect(() => {
    if (!viewerInstance) {
      return
    }

    // if navigation is blocked, show a confirmation modal
    if (blocker.state === 'blocked') {
      setModal({
        type: MODAL_TYPE.CONFIRM_SAVE,
        onCancel: blocker.proceed,
        onConfirm: blocker.proceed,
      })
    }
  }, [viewerInstance, setModal, blocker])

  useWindowIpcSync(viewerElementRef)

  return (
    <div
      {...rest}
      ref={viewerElementRef}
      data-testid="document-viewer-element"
    />
  )
}
